import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "Referral Options": "Referral Options",
        "Questionnaire": "Questionnaire",
        "Questionnaire Description": "Screen {{givenName}} for psychosocial needs based on a questionnaire",
        "Start Questionnaire": "Start Questionnaire",
        "Needs Known": "I know the needs",
        "Needs Known Description": "I know the needs of {{givenName}} and want to know to which organisations I can refer",
        "Show Offer": "Show me the available services",
        "Direct Referral": "Direct Referral",
        "Direct Referral Description": "I know the needs of {{givenName}} and know to which organisation I want to refer",
        "Start Referral": "Start refferal",
        "Contact Header": "Do you have a question or remark? Contact us!",
        "Contact Context S1": "Zipster is currently active in 13 primary care areas in Flanders and Brussels.",
        "Contact Context S2": "We are welcoming all feedback about Zipster.",
        "Contact Context S3": "Do you miss an organisation or a specific support need? Is the referral process less smooth than expected? Do you miss specific functionalities?",
        "Contact Context S4": "Don't hesitate to contact us!",
        "Contact FAQ": "Have you already read the most frequently asked questions",
        "Contact Button": "See them here",
        "Direct Referral S1": "1. Choose the service to which you want to refer {{givenName}}",
        "Direct Referral S1 Placeholder": "Choose the service",
        "Direct Referral S2": "2. Select the support need for which you refer {{givenName}}",
        "Direct Referral S2 Placeholder": "Choose the support need",
        "Direct Referral S3": "3. Add extra context about the referral",
        "Direct Referral S3 Placeholder": 'Tell us more about the patient...',
        "Direct Referral Consent": "The patient gives consent for the referral",
        "Direct Referral Refer": "Refer",
        "Refer": "Refer",
        "Direct Referral Success": "The referral was initiated successfully!",
        "Direct Referral Overview": "Back to overview",
        "Error S1": "Oops, we didn't expect this",
        "Error S2": "An unexpected problem occurred.",
        "Error S3": "The problem is reported and is looked into.",
        "Error S4": "In the meanwhile we guide you back to the Zipster dashboard!",
        "Overview": "Overview",
        "Requests": "Requests",
        "Patients": "Patients",
        "Services": "Services",
        "Referrals": "Referrals",
        "Settings": "Settings",
        "My Profile": "My Profile",
        "My Organisation": "My Organisation",
        "Log Out": "Log Out",
        "Login Failed": "Login failed",
        "Login Failed S1": "Login failed. Please check your username and password.",
        "Login Failed S2": "Did you forget your username or password? Contact us ",
        "Here": "here",
        "Invitation": "Zipster is currently only available through an invitation",
        "Contact Us": "Want to know more? Contact us!",
        "Login": "Login",
        "Keep Me Logged In": "Keep me logged in",
        "Username": "Username",
        "Password": "Password",
        "Direct Referrals": "Direct Referrals",
        "Other Services": "Other Services",
        "Organisation": "Organisation",
        "Contact Person": "Contact Person",
        "Telephone": "Telephone",
        "Needs": "Needs",
        "Links": "Links",
        "Emails Referrals": "Emails Referrals",
        "No Offer Found": "Unfortunately we couldn't find any services matching the selected support needs.",
        "Direct Referrals Through Zipster": "Direct referrals through Zipster",
        "No Direct Referrals Through Zipster": "No direct referrals through Zipster",
        "Contact Details": "Contact details",
        "Service Description": "Service Description",
        "Characteristics": "Characteristics",
        "Support Needs": "Support Needs",
        "Governing Body": "Governing Body",
        "Target Audience": "Target Audience",
        "Close Window": "Close Window",
        "Needs Based Referral S1": "1. Indicate the needs for which you want to support {{givenName}}",
        "Needs Based Referral S2": "2. Select the service to which you want to refer {{givenName}}",
        "Needs Based Referral S3": "3. Add extra context about the referral",
        "Email Consent": "The patient gives consent to provide information through email",
        "Source Sociale Kaart": "This information is based on the information in the Sociale Kaart",
        "Source Social Brussels": "This information is based on the information in Social.Brussels",
        "Source Zipster": "This information is based on the information in Zipster",
        "Add Employee": "Add Employee",
        "Email": "Email",
        "User Exists": "There already exists a user with is email address in Zipster",
        "Add Employee Header": "Add an employee",
        "Add Employee Description": "When you add an employee to your organisation an email will be sent to the employee to register for Zipster.",
        "Given Name": "Given name",
        "Family Name": "Family name",
        "Given Name Administrator": "Given Name Administrator",
        "Family Name Administrator": "Family Name Administrator",
        "Email Administrator": "Email Administrator",
        "Date of Birth": "Date of Birth",
        "Role": "Role",
        "Telephone Number": "Telephone Number",
        "Add": "Add",
        "Employee Added": "Employee Added",
        "Employee Added Description": 'The employee will get an email to register for Zipster.',
        "Save": "Save",
        "Edit": "Edit",
        "Administrator": "Administrator",
        "Assigned Healthcare Service": "You are part of {{serviceName}} as a service from {{organisationName}}",
        "Your services": "Your services from ",
        "Employees of": "Employees of ",
        "Employees": "Employees",
        "Services Organisation": "Services",
        "Access Services": "You have access to all requests for the above services.",
        "Name Organisation": "Name Organisation",
        "Street and house number": "Street and house number",
        "City": "City",
        "Postal Code": "Postal Code",
        "About the organisation": "About the organisation",
        "Description of the organisation": "Description of the organisation",
        "Description of the service": "Description of the service",
        "Sociale Kaart Unique Source": "The Sociale Kaart as a unique source",
        "Sociale Kaart Unique Source Description": "The above information about your services is directly retrieved from the Flemish Sociale Kaart.",
        "Sociale Kaart Adjustments": "Adjustments can be done through the ",
        "Brussels Social Unique Source": "Brussels Social as a unique source",
        "Brussels Social Unique Source Description": "The above information about your services is directly retrieved from Brussels Social.",
        "Brussels Social Adjustments": "Adjustments can be done through the ",
        "Name": "Name",
        "Name service": "Name service",
        "Has No Access To": "Has no access to",
        "Has Access To": "Has access to",
        "Manage Rights": "Manage Rights",
        "Manage User": "Manage User",
        "Give Administrator Rights": "Give Administrator Rights",
        "Deactivate Account": "Deactivate Account",
        "Activate Account": "Activate Account",
        "Account Activated": "The account is activated",
        "Account Not Yet Activated": "The account is not yet activated",
        "Account Deactivated": "The account is deactivated",
        "Requested": "Requested",
        "Planned": "Conversation planned",
        "Waiting List": "Waiting list",
        "Supported": "Supported",
        "Support Finished": "Support ended",
        "Referred": "Referred to other organisation",
        "No Support": "Could not offer support",
        "Already Supported": "Already supported by other organisation or person",
        "Not Reachable": "Could not reach {{givenName}}",
        "No Show": "{{givenName}} did not show up for appointment",
        "No Support Wanted": "{{givenName}} does not want the offered support",
        "No Support Needs": "{{givenName}} currently has no support needs",
        "Encounter Support": "{{practitionerName}} has made an appointment with {{givenName}} and {{serviceName}} will offer support.",
        "Encounter Support Finished": "{{practitionerName}} has ended the support of {{givenName}} with {{serviceName}}.",
        "Encounter No Support": "{{practitionerName}} ({{serviceName}}) can not support {{givenName}}.",
        "Encounter Referred": '{{practitionerName}} ({{serviceName}}) has referred {{givenName}} to another organisation.',
        "Encounter Conversation Planned": "{{practitionerName}} ({{serviceName}}) has planned a conversation with {{givenName}}.",
        "Enouncter Waiting List": "{{practitionerName}} ({{serviceName}}) has put {{givenName}} on the waiting list.",
        "Encounter Already Supported": "{{givenName}} is already supported by another organisation or person.",
        "Encounter No Show": "{{givenName}} did not show up on appointment with {{serviceName}}.",
        "Encounter Conversation": "{{practitionerName}} has had a conversation with {{givenName}}.",
        "Support Request For": "Support request for ",
        "Informed About": "Informed about",
        "Patient Informed": "{{givenName}} is informed concerning the following supported needs ",
        "No Contact": "There is no contact registered yet",
        "New Referral": "New Referral",
        "History": "History",
        "Last Encounter": "Last encounter:",
        "Edit Personal Data": "Edit Personal Data",
        "SSN": "Social Security Number",
        "Cancel": "Cancel",
        "Years": "Years",
        "Contact": "Contact ",
        "View": "View",
        "Your Organisation": "Your Organisation",
        "RIZIV Number": "RIZIV Number",
        "PD Planned": " {{serviceName}} planned a conversation",
        "PD WL": " {{serviceName}} has put {{givenName}} on the waiting list",
        "PD Support": " {{serviceName}} started support",
        "PD Already Supported": " Already supported by another organisation or person",
        "PD No Support": " {{serviceName}} can not offer support",
        "PD Referred": " {{serviceName}} has referred to another organisation",
        "PD Informed": " Informed about {{serviceName}}",
        "PD Not Reachable": " {{serviceName}} can not contact {{givenName}}",
        "PD No Show": " {{givenName}} did not show up for the first appointment",
        "PD No Support Wanted": " {{givenName}} does not wish to make use of the support of {{serviceName}}",
        "PD Support Ended": " The support at {{serviceName}} has ended",
        "PD Referral": " Referred to {{serviceName}}",
        "PD No Referrals": " There are no referrals yet for {{givenName}}",
        "PD Contact": "Contact:",
        "PD Last Update": "Last update:",
        "PD No Patients": "We could not find any patients with name {{patientName}}",
        "PD No Patients Description": "You can always register a new patient when you haven't yet entered the patient in Zipster.",
        "PD No Patients Registered": "No patients have been registered yet for your organisation.",
        "PD No Patients Registered Description": "You can register a new patient at a next encounter and refer the patient through Zipster.",
        "PD New Contact": "New Contact",
        "Results": " results",
        "Search By Name": "Search by name...",
        "Search Need": "Search need...",
        "Questionnaire Patient Title": "Questionnaire patient",
        "Questionnaire Patient Description": "About your conversation with the patient",
        "Suggestions Title": "Suggestions",
        "Suggestions Description": "Support suggestions",
        "Consent Title": "Consent",
        "Consent Description": "Registration patient consent",
        "Profile Title": "Profile",
        "Profile Description": "The profile of the patient",
        "Needs Title": "Social Needs",
        "Needs Description Patient": "The social needs of the patient",
        "Context Title": "Context",
        "Context Description": "Relevant context",
        "Referral Started": "Referral Started!",
        "Referral Started S1": "The selected organisations are now contacted.",
        "Referral Started S2": "You can follow-up the status of the referral in Zipster.",
        "To Overview": "Naar overzicht",
        "Information Sent": "Information Sent!",
        "Information Sent S1": "The patient will be informed about the selected services through email.",
        "Information Sent S2": "Please not that this is not a referral but that it will be up to the patient to contact the selected services. Referrals are only possible to organisations that can consult referrals through Zipster.",
        "Questionnaire Ended": "Questionnaire Ended",
        "Questionnaire Ended S1": "The questionnaire did not lead to a referral.",
        "Next": "Next",
        "Back": "Back",
        "Consent Organisations": "The patient gives consent to be referred to the following organisations:",
        "Consent Organisation": "I have the consent of the patient to allow these organisations to contact the patient",
        "Close": "Close",
        "Information Services": "Information about other services",
        "Information Links": "You can view the following information and print out if desired",
        "Information Email": "The patient wants to be informed about the following services through email:",
        "Information Email Consent": "I have the consent of the patient to send information about the service through email",
        "Information Services S1": "There is no email address registered yet for the patient",
        "Information Services S2": "We can send the info of the selected services to the patient but we need an email adress for that.",
        "Information Services S3": "You can also choose to print the information and hand it out to the patient.",
        "Enter Email": "Let me enter the email adress",
        "Print Information": "No problem, I will print the info",
        "Register Consent": "Register Consent",
        "Email Patient": "Email Patient",
        "Patient Registration Failed": "The patient can not be registered",
        "Registration New Patient": "Registration New Patient",
        "Register": "Register",
        "Patient Consent Zipster": "The patient gives consent to be screened through Zipster",
        "Social Context": "Social Context",
        "Answer The Question Again": "Answer the question again",
        "SD Awaiting": "Awaiting",
        "SD Planned": "Conversation planned",
        "SD Waiting List": "Waiting list",
        "SD Support": "Supported",
        "SD Already Supported": "Already supported",
        "SD Referred": "Referred",
        "SD Not Supported": "Not supported",
        "SD No Show": "Did not show up",
        "SD No Support Wanted": "Does not want the offered support",
        "SD Unreachable": "Unreachable",
        "SD Support Ended": "Support ended",
        "Question For": "Question for",
        "Person": "Person",
        "Requester": "Requester",
        "Status": "Status",
        "Date": "Date",
        "SD No Requests": "We could not find any requests yet",
        "SD No Requests Description": "New and already addressed service requests will always be shown here",
        "Filter": "Filter by: ",
        "Register Follow-up": "Register Follow-up",
        "SD Support Already Ended": "The support is already ended",
        "Follow-up Registered": "Follow-up registered!",
        "SR Waiting List": "We put {{givenName}} on the waiting list",
        "SR Support": "We had a conversation and will support {{givenName}}",
        "SR Referred": "We had a conversation and referred {{givenName}} to another organisation",
        "SR No Support": "We had a conversation but we can't support {{givenName}}",
        "SR Already Supported": "There already is support for {{givenName}}, no further support is needed",
        "SR No Show": "{{givenName}} did not show up on our appointment",
        "SR No Support Wanted": "{{givenName}} does not want to make use of the offered support",
        "SR Support Ended": "We will no longer support {{givenName}}",
        "SR Planned": 'We have planned a conversation with {{givenName}}',
        "SR Unreachable": "We can not reach {{givenName}}",
        "Social Context Description": "Relevant context added by the referrer",
        "SR Requester": "Requester of the referral",
        "Person With Support Need": "Person with a support need",
        "Age": "Age",
        "Residence": "Residence",
        "Needs Description": "The exact support needs",
        "More Information": "More Information",
        "Service": "Service",
        "Outreaching": "Outreaching",
        "Show Full Offer": "Show the services for Flanders",
        "Show ELZ Offer": "Show the services in my region",
        "Filter By Need": "Filter by support need:",
        "Choose Support Need": "Choose support need",
        "Password Guide": "Please choose a password of at least 10 characters",
        "Password Changed": "Paswoord changed successfully!",
        "Password Changed S1": "You can now login with your username and chosen password.",
        "Password Changed S2": "You can find your username in the registratino mail you received.",
        "To Login": "To login",
        "Confirm Password": "Confirm password",
        "Register Me": "Sign Me Up",
        "Consent Signup": "I consent to the ",
        "Terms Of Use": "terms of use",
        "And The Zipster": "and the Zipster",
        "Privacy Policy": "privacy policy",
        "Signup Welcome": "Glad you are here!",
        "Signup Guidance": "Choose your password and you can get started with Zipster.",
        "Suggestions No Needs": "There are no support suggestions for this patient",
        "Suggestions No Needs Description": "There are no detected psychosocial needs for which services can be provided.",
        "More Info Organisation": "Click for more information about this organisation",
        "Prescription": "Prescription",
        "More Information Prescription": "Click for more information about this prescription",
        "Prescriptions": "Prescriptions",
        "Suggestions": "Suggestions",
        "Referral VS Informing": "Referring versus Informing",
        "Referral VS Informing Description": "There are 2 types of suggestions in Zipster.",
        "Referral Explanation": "A referral where the organisation to which you refer will contact the patient.",
        "Informing Explanation": "Information about an organisation which the patient can contact. These can not be followed up within Zipster.",
        "Contact Us Main": "Contact Us",
        "FAQ": "FAQ",
        "Tagline": "Bridging health and social care.",
        "Login Again": "Please login again.",
        "Session Expired": "Your session expired",
        "About Us": "About Us",
        "Send New Registration Link": "Send New Registration Link",
        "Reset Password": "Reset Password",
        "Website Organisation": "Website Organisation",
        "Registration Organisation": "Registration Organisation",
        "Choose Primary Care Area": "Choose your primary care area"
      }
    },
    nl: {
      translations: {
        "Referral Options": "Doorverwijzingsopties",
        "Questionnaire": "Vragenlijst",
        "Questionnaire Description": "Bevraag {{givenName}} op psychosociale noden aan de hand van de vragenlijst",
        "Start Questionnaire": "Start de bevraging",
        "Needs Known": "Ik ken de noden",
        "Needs Known Description": "Ik ken de noden van {{givenName}} en wil weten naar welke organisaties ik kan doorverwijzen",
        "Show Offer": "Toon me het aanbod",
        "Direct Referral": "Directe Doorverwijzing",
        "Direct Referral Description": "Ik ken de de noden van {{givenName}} en weet naar welke organisatie ik wil doorverwijzen",
        "Start Referral": "Start de doorverwijzing",
        "Contact Context S1": "Zipster is momenteel actief in 13 Eerstelijnszones in Vlaanderen en Brussel.",
        "Contact Context S2": "We verwelkomen elke vorm van feedback over Zipster.",
        "Contact Context S3": "Mis je een organisatie of een ondersteuningsnood? Loopt de bevraging minder vlot dan je zou willen? Ontbreekt er functionaliteit die je beter zou helpen?",
        "Contact Context S4": "Aarzel niet om ons te contacteren!",
        "Contact FAQ": "De meest gestelde Zipster vragen al bekeken?",
        "Contact Button": "Bekijk ze hier",
        "Direct Referral S1": "1. Kies de dienst naar waar u {{givenName}} wenst door te verwijzen",
        "Direct Referral S1 Placeholder": "Kies de dienst",
        "Direct Referral S2": "2. Duid de ondersteuningsnoden aan waarvoor u {{givenName}} doorverwijst",
        "Direct Referral S2 Placeholder": "Kies de ondersteuningsnood",
        "Direct Referral S3": "3. Voeg extra duiding toe over de aanvraag",
        "Direct Referral S3 Placeholder": 'Vertel ons iets meer over de persoon...',
        "Direct Referral Consent": "De persoon geeft toestemming voor de doorverwijzing",
        "Direct Referral Refer": "Verwijs Door",
        "Refer": "Verwijs Door",
        "Direct Referral Success": "De doorverwijzing werd succesvol geïnitieerd!",
        "Direct Referral Overview": "Terug naar overzicht",
        "Error S1": "Oeps, did hadden we niet verwacht",
        "Error S2": "Er is een onverwacht probleem opgetreden.",
        "Error S3": "Het probleem is gemeld en wordt bekeken.",
        "Error S4": "We brengen je ondertussen terug naar het Zipster dashboard!",
        "Overview": "Overzicht",
        "Requests": "Aanvragen",
        "Patients": "Contacten",
        "Services": "Ondersteuningsaanbod",
        "Referrals": "Doorverwijzingen",
        "Settings": "Beheer",
        "My Profile": "Mijn Profiel",
        "My Organisation": "Mijn Organisatie",
        "Log Out": "Log Uit",
        "Login Failed": "Inloggen niet gelukt",
        "Login Failed S1": "Het inloggen is niet gelukt. Controleer uw gebruikersnaam en wachtwoord.",
        "Login Failed S2": "Bent u uw gebruikersnaam of wachtwoord vergeten? Contacteer ons ",
        "Here": "hier",
        "Invitation": "Zipster is momenteel enkel op uitnodiging beschikbaar.",
        "Contact Us": "Meer weten? Contacteer ons!",
        "Login": "Login",
        "Keep Me Logged In": "Hou me ingelogd",
        "Username": "Gebruikersnaam",
        "Password": "Paswoord",
        "Direct Referrals": "Directe Doorverwijzingen",
        "Other Services": "Ander Ondersteuningsaanbod",
        "Organisation": "Organisatie",
        "Contact Person": "Contactpersoon",
        "Telephone": "Telefoon",
        "Needs": "Noden",
        "Links": "Links",
        "Emails Referrals": "E-mails Verwijzingen",
        "No Offer Found": "We vonden helaas geen aanbod terug voor de geselecteerde ondersteuningsnoden.",
        "Direct Referrals Through Zipster": "Directe doorverwijzing via Zipster",
        "No Direct Referrals Through Zipster": "Geen Directe doorverwijzing via Zipster",
        "Contact Details": "Contactgegevens",
        "Service Description": "Werking",
        "Characteristics": "Kenmerken",
        "Support Needs": "Ondersteuningsnoden",
        "Governing Body": "Inrichtende Macht",
        "Target Audience": "Doelgroep",
        "Close Window": "Sluit Venster",
        "Needs Based Referral S1": "1. Duid de noden aan waarvoor u voor {{givenName}} ondersteuning zoekt",
        "Needs Based Referral S2": "2. Kies de dienst naar waar u {{givenName}}  wenst door te verwijzen",
        "Needs Based Referral S3": "3. Voeg extra duiding toe over de aanvraag",
        "Email Consent": "De persoon geeft toestemming om de informatie via e-mail te bezorgen",
        "Needs Known Of": "Ik ken de noden van {{givenName}}",
        "Source Sociale Kaart": "Deze gegevens komen uit de Sociale Kaart",
        "Source Social Brussels": "Deze gegevens komen uit Sociaal Brussel",
        "Source Zipster": "Deze gegevens komen uit Zipster",
        "Add Employee": "Voeg Medewerker Toe",
        "Email": "E-mail",
        "User Exists": "Er bestaat reeds een gebruiker met dit e-mail adres in Zipster",
        "Add Employee Header": "Voeg een medewerker toe",
        "Add Employee Description": "Wanneer u een medewerker toevoegt aan uw organisatie zal deze een e-mail krijgen om zich te registreren bij Zipster.",
        "Given Name": "Voornaam",
        "Family Name": "Familienaam",
        "Given Name Administrator": "Voornaam Beheerder",
        "Family Name Administrator": "Familienaam Beheerder",
        "Email Administrator": "E-mail Beheerder",
        "Date of Birth": "Geboortedatum",
        "Role": "Rol",
        "Telephone Number": "Telefoonnummer",
        "Add": "Voeg Toe",
        "Employee Added": "Medewerker toegevoegd",
        "Employee Added Description": 'De medewerker zal een e-mail krijgen om zich te registreren bij Zipster.',
        "Save": "Bewaar",
        "Edit": "Pas Aan",
        "Administrator": "Beheerder",
        "Assigned Healthcare Service": "Je maakt deel uit van de dienst {{serviceName}} als onderdeel van {{organisationName}}",
        "Your services": "Uw diensten van ",
        "Employees of": "Medewerkers van ",
        "Employees": "Medewerkers",
        "Services Organisation": "Diensten",
        "Access Services": "U heeft toegang tot alle aanvragen voor de bovenstaande diensten.",
        "Name Organisation": "Naam Organisatie",
        "Street and house number": "Straat en huisnummer",
        "City": "Stad",
        "Postal Code": "Postcode",
        "About the organisation": "Over de organisatie",
        "Description of the organisation": "Beschrijving van de organisatie",
        "Description of the service": "Dienstbeschrijving",
        "Sociale Kaart Unique Source": "De Sociale Kaart als unieke bron",
        "Sociale Kaart Unique Source Description": "De bovenstaande informatie over uw aanbod wordt door Zipster rechtstreeks uit de Vlaamse Sociale Kaart gehaald.",
        "Sociale Kaart Adjustments": "Aanpassingen hieraan kan u dan ook doen via de ",
        "Brussels Social Unique Source": "Brussel Sociaal als unieke bron",
        "Brussels Social Unique Source Description": "De bovenstaande informatie over uw aanbod wordt door Zipster rechtstreeks uit Brussel Sociaal gehaald.",
        "Brussels Social Adjustments": "Aanpassingen hieraan kan u dan ook doen via de ",
        "Name": "Naam",
        "Name service": "Naam deelwerking",
        "Has No Access To": "Heeft geen toegang tot",
        "Has Access To": "Heeft toegang tot",
        "Manage Rights": "Beheer Rechten",
        "Manage User": "Beheer Gebruiker",
        "Give Administrator Rights": "Maak Beheerder",
        "Deactivate Account": "Deactiveer Account",
        "Activate Account": "Activeer Account",
        "Account Activated": "Het account is geactiveerd",
        "Account Not Yet Activated": "Het account is nog niet geactiveerd",
        "Account Deactivated": "Het account is gedeactiveerd",
        "Requested": "Aangevraagd",
        "Planned": "Gesprek gepland",
        "Waiting List": "Wachtlijst",
        "Supported": "Ondersteund",
        "Support Finished": "Traject afgerond",
        "Referred": "Doorverwezen naar andere organisatie",
        "No Support": "Kon geen ondersteuning bieden",
        "Already Supported": "Reeds ondersteund door andere organisatie of persoon",
        "Not Reachable": "Kon {{givenName}} niet bereiken",
        "No Show": "{{givenName}} is niet voor de afspraak komen opdagen",
        "No Support Wanted": "{{givenName}} wenst niet op het aanbod in te gaan",
        "No Support Needs": "{{givenName}} heeft momenteel geen ondersteuningsnoden",
        "Encounter Support": "{{practitionerName}} heeft een gesprek gehad met {{givenName}} en {{serviceName}} zal ondersteuning bieden.",
        "Encounter Support Finished": "{{practitionerName}} heeft het traject van {{givenName}} bij {{serviceName}} afgerond.",
        "Encounter No Support": "{{practitionerName}} ({{serviceName}}) kan {{givenName}} niet ondersteunen",
        "Encounter Referred": '{{practitionerName}} ({{serviceName}}) heeft {{givenName}} doorverwezen naar een andere organisatie.',
        "Encounter Conversation Planned": "{{practitionerName}} ({{serviceName}}) heeft een gesprek ingepland met {{givenName}}.",
        "Enouncter Waiting List": "{{practitionerName}} ({{serviceName}}) heeft {{givenName}} op de wachtlijst gezet.",
        "Encounter Already Supported": "{{givenName}} is reeds ondersteund door andere organisatie of persoon.",
        "Encounter No Show": "{{givenName}} is niet komen opdagen voor de afspraak met {{serviceName}}.",
        "Encounter Conversation": "{{practitionerName}} heeft een gesprek gehad met {{givenName}}.",
        "Support Request For": "Ondersteuningsvraag voor ",
        "Informed About": "Geïnformeerd over",
        "Patient Informed": "{{givenName}} is geïnformeerd in het kader van ",
        "No Contact": "Er werd nog geen contact geregistreerd",
        "New Referral": "Nieuwe Doorverwijzing",
        "History": "Historiek",
        "Last Encounter": "Laatste contact: ",
        "Edit Personal Data": "Pas Persoonsgegevens Aan",
        "SSN": "Rijksregisternummer",
        "Cancel": "Annuleer",
        "Years": "Jaar",
        "Contact": "Contacteer ",
        "View": "Bekijk",
        "Your Organisation": "Uw Organisatie",
        "RIZIV Number": "RIZIV Nummer",
        "PD Planned": " {{serviceName}} heeft een gesprek gepland",
        "PD WL": " {{serviceName}} heeft {{givenName}} op de wachtlijst gezet",
        "PD Support": " {{serviceName}} zorgt voor ondersteuning",
        "PD Already Supported": " Reeds ondersteund door andere organisatie of persoon",
        "PD No Support": " {{serviceName}} kan niet voor ondersteuning zorgen",
        "PD Referred": " {{serviceName}} heeft doorverwezen naar een andere organisatie",
        "PD Informed": " Geïnformeerd over {{serviceName}}",
        "PD Not Reachable": " {{serviceName}} kan {{givenName}} niet bereiken",
        "PD No Show": " {{givenName}} is niet komen opdagen op de eerste verkennende afspraak",
        "PD No Support Wanted": " {{givenName}} wenst niet in te gaan op het aanbod van {{serviceName}}",
        "PD Support Ended": " Het traject bij {{serviceName}} is afgerond",
        "PD Referral": " Doorverwezen naar {{serviceName}}",
        "PD No Referrals": " Er zijn nog geen doorverwijzingen gebeurd voor {{givenName}}",
        "PD Contact": "Contact:",
        "PD Last Update": "Laatste update:",
        "PD No Patients": "We vonden geen personen terug met de naam {{patientName}}",
        "PD No Patients Description": "U kan steeds een nieuw contact registreren indien u de persoon nog niet via Zipster hebt bevraagd.",
        "PD No Patients Registered": "Er zijn nog geen contacten geregistreerd voor uw organisatie.",
        "PD No Patients Registered Description": "U kan bij een volgende consultatie via een nieuw contact een persoon registreren en tevens bevragen op zijn of haar psychosociale noden.",
        "PD New Contact": "Nieuw Contact",
        "Results": " resultaten",
        "Search By Name": "Zoek op naam...",
        "Search Need": "Zoek nood...",
        "Questionnaire Patient Title": "Bevraging Contact",
        "Questionnaire Patient Description": "Over uw gesprek met de persoon",
        "Suggestions Title": "Suggesties",
        "Suggestions Description": "Suggesties ter ondersteuning",
        "Consent Title": "Toestemming",
        "Consent Description": "Registratie toestemming persoon",
        "Profile Title": "Profiel",
        "Profile Description": "Het profiel van de persoon",
        "Needs Title": "Sociale Noden",
        "Needs Description Patient": "De sociale noden van de persoon",
        "Context Title": "Context",
        "Context Description": "Relevante context",
        "Referral Started": "Doorverwijzing Wordt Gestart!",
        "Referral Started S1": "De geselecteerd organisaties worden nu gecontacteerd.",
        "Referral Started S2": "U kan de doorverwijzing verder opvolgen in Zipster.",
        "To Overview": "Naar overzicht",
        "Information Sent": "Informatie Verzonden!",
        "Information Sent S1": "De persoon zal de informatie over het gekozen aanbod via e-mail ontvangen.",
        "Information Sent S2": "Merk op dat dit geen doorverwijzing is maar dat het aan de persoon zelf is om al dan niet contact op te nemen met het gekozen aanbod. Doorverwijzingen zijn enkel mogelijk naar organisaties die doorverwijzingen via Zipster kunnen raadplegen.",
        "Questionnaire Ended": "Bevraging Beeindigd",
        "Questionnaire Ended S1": "De bevraging van de persoon heeft niet geresulteerd tot een doorverwijzing of het versturen van informatie via e-mail.",
        "Next": "Volgende",
        "Back": "Vorige",
        "Consent Organisations": "De persoon geeft toestemming om doorverwezen te worden naar de volgende organisaties:",
        "Consent Organisation": "Ik heb de toestemming van de persoon om deze organisaties contact te laten opnemen",
        "Close": "Sluit Af",
        "Information Services": "Informatie over andere diensten",
        "Information Links": "U kan de volgende informatie raadplegen en indien gewenst afdrukken",
        "Information Email": "De persoon wenst geïnformeerd te worden over het volgende aanbod via e-mail:",
        "Information Email Consent": "Ik heb de toestemming van de persoon om deze informatie via e-mail te laten verzenden",
        "Information Services S1": "Er is nog geen e-mail adres voor de persoon ingegeven",
        "Information Services S2": "U kan de info van de geselecteerde organisaties via e-mail aan de persoon bezorgen, het e-mail adres ontbreekt echter.",
        "Information Services S3": "U kan er ook voor kiezen om deze informatie af te drukken en mee te geven.",
        "Enter Email": "Ik geef het e-mail adres alsnog in",
        "Print Information": "Geen probleem, ik druk de info af",
        "Register Consent": "Registreer Toestemming",
        "Email Patient": "E-mailadres",
        "Patient Registration Failed": "De persoon kan niet worden geregistreerd",
        "Registration New Patient": "Registratie Nieuw Contact",
        "Register": "Registreer",
        "Patient Consent Zipster": "De persoon geeft de toestemming om via Zipster bevraagd te worden",
        "Social Context": "Sociale Context",
        "Answer The Question Again": "Beantwoord de vraag opnieuw",
        "SD Awaiting": "In afwachting",
        "SD Planned": "Gesprek Gepland",
        "SD Waiting List": "Wachtlijst",
        "SD Support": "Ondersteund",
        "SD Already Supported": "Reeds ondersteund",
        "SD Referred": "Doorverwezen",
        "SD Not Supported": "Niet ondersteund",
        "SD No Show": "Niet komen opdagen",
        "SD No Support Wanted": "Gaat niet in op aanbod",
        "SD Unreachable": "Onbereikbaar",
        "SD Support Ended": "Traject afgerond",
        "Question For": "Vraag voor",
        "Person": "Persoon",
        "Requester": "Aanvrager",
        "Request": "Ondersteuningsvraag",
        "Status": "Status",
        "Date": "Datum",
        "SD No Requests": "We vonden nog geen aanvragen terug",
        "SD No Requests Description": "Nieuwe en behandelde aanvragen zal u steeds hier kunnen terugvinden",
        "Filter": "Filter op: ",
        "Register Follow-up": "Registreer Opvolging",
        "SD Support Already Ended": "Dit traject is reeds afgerond",
        "Follow-up Registered": "Opvolging werd geregistreerd!",
        "SR Waiting List": "We zetten {{givenName}} op de wachtlijst",
        "SR Support": "We hebben een gesprek gehad en gaan {{givenName}} ondersteunen",
        "SR Referred": "We hebben een gesprek gehad en hebben {{givenName}} doorverwezen naar een andere organisatie",
        "SR No Support": "We hebben een gesprek gehad maar kunnen {{givenName}} niet ondersteunen",
        "SR Already Supported": "Er is reeds ondersteuning voor {{givenName}}, verdere ondersteuning is niet nodig",
        "SR No Show": "{{givenName}} is niet komen opdagen op onze afspraak",
        "SR No Support Wanted": "{{givenName}} wenst niet in te gaan op ons aanbod",
        "SR Support Ended": "We zullen {{givenName}} niet langer ondersteunen",
        "SR Planned": 'We hebben een gesprek ingepland met {{givenName}}',
        "SR Unreachable": "We kunnen {{givenName}} niet bereiken",
        "Social Context Description": "Relevante context aangegeven door de doorverwijzer",
        "SR Requester": "Aanvrager van de ondersteuning",
        "Person With Support Need": "Persoon met een ondersteuningsnood",
        "Age": "Leeftijd",
        "Residence": "Woonplaats",
        "Needs Description": "De precieze ondersteuningsnoden",
        "More Information": "Meer Informatie",
        "Service": "Dienst",
        "Outreaching": "Outreachend",
        "Show Full Offer": "Toon het volledige aanbod",
        "Show ELZ Offer": "Toon het aanbod voor mijn Eerstelijnszone",
        "Filter By Need": "Filter op ondersteuningsnood:",
        "Choose Support Need": "Kies ondersteuningsnood",
        "Password Guide": "Gelieve een paswoord te kiezen van minstens 10 karakters",
        "Password Changed": "Paswoord is succesvol gewijzigd!",
        "Password Changed S1": "Je kan nu inloggen met je gebruikersnaam en je net gekozen paswoord.",
        "Password Changed S2": "Je gebruikersnaam kan je terugvinden in de registratiemail die je hebt ontvangen.",
        "To Login": "Naar login",
        "Confirm Password": "Bevestig paswoord",
        "Register Me": "Registreer Mij",
        "Consent Signup": "I ga akkoord met de Zipster ",
        "Terms Of Use": "Gebruiksvoorwaarden",
        "And The Zipster": "en het Zipster",
        "Privacy Policy": "Privacybeleid",
        "Signup Welcome": "Fijn dat je er bent!",
        "Signup Guidance": "Kies je paswoord en je kan aan de slag met Zipster.",
        "Suggestions No Needs": "Er zijn geen ondersteuningssuggesties voor deze persoon",
        "Suggestions No Needs Description": "Er zijn geen gedecteerde psychosociale noden die door het bestaande aanbod ondersteund kunnen worden.",
        "More Info Organisation": "Klik voor meer info over deze organisatie",
        "Prescription": "Voorschrift",
        "More Information Prescription": "Klik voor meer info over dit voorschrift",
        "Prescriptions": "Voorschriften",
        "Suggestions": "Suggesties",
        "Referral VS Informing": "Doorverwijzen versus Informeren",
        "Referral VS Informing Description": "Er zijn 2 soorten suggesties in Zipster.",
        "Referral Explanation": "Een doorverwijzing waarbij de organisatie naar waar je doorverwijst zelf contact opneemt met de persoon.",
        "Informing Explanation": "Informatie over een organisatie waar de persoon zelf contact mee kan opnemen. Deze kan je dan ook niet in Zipster opvolgen.",
        "Contact Us Main": "Contacteer Ons",
        "FAQ": "Veelgestelde Vragen",
        "Tagline": "Waar zorg en welzijn samenkomen.",
        "Login Again": "Gelieve opnieuw in te loggen.",
        "Session Expired": "Uw sessie is verstreken",
        "About Us": "Over Ons",
        "Language": "Taal",
        "Languages": "Talen",
        "Send New Registration Link": "Verzend Registratielink",
        "Reset Password": "Reset Paswoord",
        "Website Organisation": "Website Organisatie",
        "Registration Organisation": "Registratie Organisatie",
        "Choose Primary Care Area": "Kies je Eerstelijnszone"
      }
    },
    fr: {
      translations: {
        "Referral Options": "Options d'orientation",
        "Questionnaire": "Questionnaire",
        "Questionnaire Description": "Interrogez {{givenName}} sur ses besoins psychosociaux à l'aide du questionnaire",
        "Start Questionnaire": "Démarrez le questionnaire",
        "Needs Known": "Je connais les besoins",
        "Needs Known Description": "Je connais les besoins de {{givenName}} et je veux savoir vers quelles organisations je peux l’orienter",
        "Show Offer": "Montrez-moi l'offre",
        "Direct Referral": "Orientation directe",
        "Direct Referral Description": "Je connais les besoins de {{givenName}} et je sais vers quelle organisation l’orienter",
        "Start Referral": "Démarrez l’orientation",
        "Contact Context S1": "Zipster est actuellement active dans 13 zones de première ligne en Flandre et à Bruxelles.",
        "Contact Context S2": "Tout commentaire sur Zipster est le bienvenu.",
        "Contact Context S3": "Une organisation ou possibilité de réponse à un besoin spécifique fait défaut? Le questionnaire n’est pas aussi efficace que vous le souhaiteriez? Il manque une fonctionnalité qui vous serait utile?",
        "Contact Context S4": "N'hésitez pas à nous contacter!",
        "Contact FAQ": "Avez-vous déjà consulté la foire aux questions concernant Zipster?",
        "Contact Button": "Découvrez-la ici",
        "Direct Referral S1": "1. Choisissez le service vers lequel vous souhaitez orienter {{givenName}}",
        "Direct Referral S1 Placeholder": "Choisissez le service",
        "Direct Referral S2": "2. Indiquez les besoins de soutien pour lesquels vous orientez {{givenName}}",
        "Direct Referral S2 Placeholder": "Choisissez le besoin de soutien",
        "Direct Referral S3": "3. Ajoutez des précisions supplémentaires sur la demande",
        "Direct Referral S3 Placeholder": 'Dites-nous en un peu plus sur le patient...',
        "Direct Referral Consent": "Le patient consent à l’orientation",
        "Direct Referral Refer": "Procédez à l’orientation",
        "Refer": "Procédez à l’orientation",
        "Direct Referral Success": "L’orientation a été initiée avec succès!",
        "Direct Referral Overview": "Retour à l'aperçu",
        "Error S1": "Oups, ce n’était pas prévu",
        "Error S2": "Un problème inattendu s'est produit.",
        "Error S3": "Le problème a été signalé et est en cours d'examen.",
        "Error S4": "En attendant, nous vous ramenons au tableau de bord de Zipster!",
        "Overview": "Aperçu",
        "Requests": "Demandes",
        "Patients": "Patients",
        "Services": "Offre de soutien",
        "Referrals": "Orientations",
        "Settings": "Gestion",
        "My Profile": "Mon Profil",
        "My Organisation": "Mon Organisation",
        "Log Out": "Déconnexion",
        "Login Failed": "Échec de la connexion",
        "Login Failed S1": "La connexion a échoué. Vérifiez votre nom d'utilisateur et votre mot de passe.",
        "Login Failed S2": "Vous avez oublié votre nom d'utilisateur ou votre mot de passe ? Contactez-nous",
        "Here": "ici",
        "Invitation": "Zipster est actuellement disponible uniquement sur invitation.",
        "Contact Us": "En savoir plus? Contactez-nous!",
        "Login": "Connexion",
        "Keep Me Logged In": "Rester connecté",
        "Username": "Nom d'utilisateur",
        "Password": "Mot de passe",
        "Direct Referrals": "Orientations Directes",
        "Other Services": "Autre offre de soutien",
        "Organisation": "Organisation",
        "Contact Person": "Contact",
        "Telephone": "Téléphone",
        "Needs": "Besoins",
        "Links": "Liens",
        "Emails Referrals": "Adresses email pour les orientations",
        "No Offer Found": "Malheureusement, nous n'avons pas trouvé d'offre pour les besoins de soutien sélectionnés.",
        "Direct Referrals Through Zipster": "Orientation directe via Zipster",
        "No Direct Referrals Through Zipster": "Aucune orientation directe via Zipster",
        "Contact Details": "Coordonnées",
        "Service Description": "Fonctionnement",
        "Characteristics": "Caractéristiques",
        "Support Needs": "Besoins de soutien",
        "Governing Body": "Pouvoir organisateur",
        "Target Audience": "Groupe cible",
        "Close Window": "Fermer la fenêtre",
        "Needs Based Referral S1": "1. Veuillez indiquer les besoins pour lesquels vous cherchez un soutien pour {{givenName}}",
        "Needs Based Referral S2": "2. Choisissez le service vers lequel vous souhaitez orienter {{givenName}}",
        "Needs Based Referral S3": "3. Ajoutez des précisions supplémentaires sur la demande",
        "Email Consent": "Le patient consent à ce que les informations soient transmises par courrier électronique",
        "Needs Known Of": "Je connais les besoins de {{givenName}}",
        "Source Sociale Kaart": "Ces données proviennent de la carte sociale",
        "Source Social Brussels": "Ces données proviennent de Bruxelles Social",
        "Source Zipster": "Ces données proviennent de Zipster",
        "Add Employee": "Ajouter collaborateur",
        "Email": "E-mail",
        "User Exists": "Cette adresse e-mail est déjà utilisée dans Zipster",
        "Add Employee Header": "Ajouter un collaborateur",
        "Add Employee Description": "Lorsque vous ajoutez un collaborateur à votre organisation, celui-ci reçoit un e-mail pour s'inscrire sur Zipster.",
        "Given Name": "Prénom",
        "Family Name": "Nom",
        "Given Name Administrator": "Prénom de l'administrateur",
        "Family Name Administrator": "Nom de l'administrateur",
        "Email Administrator": "E-mail de l'administrateur",
        "Date of Birth": "Date de naissance",
        "Role": "Rôle",
        "Telephone Number": "Numéro de téléphone",
        "Add": "Ajouter",
        "Employee Added": "Collaborateur ajouté",
        "Employee Added Description": "Le collaborateur recevra un e-mail pour s'inscrire sur Zipster.",
        "Save": "Enregistrer",
        "Edit": "Modifier",
        "Administrator": "Administrateur",
        "Assigned Healthcare Service": "Vous faites partie du service {{serviceName}} dans le cadre de {{organisationName}}",
        "Your services": "Vos services de ",
        "Employees": "Collaborateurs",
        "Employees of": "Collaborateurs de ",
        "Services Organisation": "Services",
        "Access Services": "Vous pouvez accéder à toutes les demandes pour les services ci-dessus.",
        "Name Organisation": "Nom de l’organisation",
        "Street and house number": "Rue et numéro de maison",
        "City": "Ville",
        "Postal Code": "Code postal",
        "About the organisation": "À propos de l'organisation",
        "Description of the organisation": "Description de l'organisation",
        "Description of the service": "Description du service",
        "Sociale Kaart Unique Source": "La carte sociale comme ressource unique",
        "Sociale Kaart Unique Source Description": "Les informations ci-dessus concernant votre offre sont directement extraites de Bruxelles Social par Zipster.",
        "Sociale Kaart Adjustments": "Les adaptations peuvent donc être effectuées par le biais de Bruxelles Social ",
        "Brussels Social Unique Source": "Bruxelles Social comme ressource unique",
        "Brussels Social Unique Source Description": "Les informations ci-dessus concernant votre offre sont directement extraites de Bruxelles Social par Zipster.",
        "Brussels Social Adjustments": "Les adaptations peuvent donc être effectuées par le biais de Bruxelles Social ",
        "Name": "Nom",
        "Name service": "Nom de la subdivision",
        "Has No Access To": "N'a pas accès à",
        "Has Access To": "A accès à",
        "Manage Rights": "Gestion des droits",
        "Manage User": "Gestion d'utilisateur",
        "Give Administrator Rights": "Créer un administrateur",
        "Deactivate Account": "Désactiver le compte",
        "Activate Account": "Activer le compte",
        "Account Activated": "Le compte est activé",
        "Account Not Yet Activated": "Le compte n'est pas encore activé",
        "Account Deactivated": "Le compte est désactivé",
        "Requested": "Demandé",
        "Planned": "Entretien planifié",
        "Waiting List": "Liste d'attente",
        "Supported": "Pris en charge",
        "Support Finished": "Trajet terminé",
        "Referred": "Orienté vers une autre organisation",
        "No Support": "N'a pas pu fournir de soutien",
        "Already Supported": "Déjà pris en charge par une autre organisation ou personne",
        "Not Reachable": "{{givenName}} n’a pas pu être contacté(e)",
        "No Show": "{{givenName}} ne s'est pas présenté(e) au rendez-vous",
        "No Support Wanted": "{{givenName}} ne souhaite pas accepter l'offre",
        "No Support Needs": "{{givenName}} ne présente actuellement aucun besoin de soutien",
        "Encounter Support": "{{practitionerName}} a eu un entretien avec {{givenName}} et {{serviceName}} lui apportera son soutien.",
        "Encounter Support Finished": "{{practitionerName}} a achevé le trajet de {{givenName}} auprès de {{serviceName}}.",
        "Encounter No Support": "{{practitionerName}} ({{serviceName}}) ne peut pas accompagner {{givenName}}",
        "Encounter Referred": "{{practitionerName}} ({{serviceName}}) a orienté {{givenName}} vers une autre organisation.",
        "Encounter Conversation Planned": "{{practitionerName}} ({{serviceName}}) a programmé un entretien avec {{givenName}}.",
        "Enouncter Waiting List": "{{practitionerName}} ({{serviceName}}) a inscrit {{givenName}} sur la liste d'attente.",
        "Encounter Already Supported": "{{givenName}} est déjà pris en charge par une autre organisation ou personne.",
        "Encounter No Show": "{{givenName}} ne s'est pas présenté(e) au rendez-vous avec {{serviceName}}.",
        "Encounter Conversation": "{{practitionerName}} a eu un entretien avec {{givenName}}.",
        "Support Request For": "Demande de soutien pour ",
        "Informed About": "Informé(e) sur",
        "Patient Informed": "{{givenName}} a été informé(e) dans le cadre de ",
        "No Contact": "Aucun contact n'a encore été enregistré",
        "New Referral": "Nouvelle orientation",
        "History": "Historique",
        "Last Encounter": "Dernier contact: ",
        "Edit Personal Data": "Modifier les données à caractère personnel",
        "SSN": "Numéro de registre national",
        "Cancel": "Annuler",
        "Years": "Ans",
        "Contact": "Contacter ",
        "View": "Consulter",
        "Your Organisation": "Votre Organisation",
        "RIZIV Number": "Numéro INAMI",
        "PD Planned": " {{serviceName}} a planifié un entretien",
        "PD WL": " {{serviceName}} a inscrit {{givenName}} sur la liste d'attente",
        "PD Support": " {{serviceName}} assure un accompagnement",
        "PD Already Supported": " Prise en charge déjà effectuée par une autre organisation ou personne",
        "PD No Support": " {{serviceName}} ne peut pas fournir de soutien",
        "PD Referred": " {{serviceName}} a procédé à une orientation vers une autre organisation",
        "PD Informed": " Informé(e) sur {{serviceName}}",
        "PD Not Reachable": " {{serviceName}} ne parvient pas à joindre {{givenName}}",
        "PD No Show": " {{givenName}} ne s'est pas présenté(e) au premier rendez-vous préliminaire",
        "PD No Support Wanted": " {{givenName}} ne souhaite pas accepter l'offre de {{serviceName}}",
        "PD Support Ended": " Le trajet auprès de {{serviceName}} est achevé",
        "PD Referral": " Orienté(e) vers {{serviceName}}",
        "PD No Referrals": " Aucune orientation n'a encore été effectuée pour {{givenName}}",
        "PD Contact": "Contact:",
        "PD Last Update": "Dernière mise à jour:",
        "PD No Patients": "Nous n'avons trouvé aucun patient sous le nom {{patientName}}",
        "PD No Patients Description": "Vous pouvez toujours enregistrer un nouveau patient si vous ne l'avez pas encore interrogé via Zipster.",
        "PD No Patients Registered": "Aucun patient n'a encore été enregistré pour votre organisation.",
        "PD No Patients Registered Description": "Vous pourrez créer un patient lors d'une consultation ultérieure via un nouveau contact et l'interroger sur ses besoins psychosociaux.",
        "PD New Contact": "Nouveau Contact",
        "Results": " résultats",
        "Search By Name": "Recherche par nom...",
        "Search Need": "Recherche par soutien...",
        "Questionnaire Patient Title": "Questionnaire Patient",
        "Questionnaire Patient Description": "À propos de votre entretien avec le patient",
        "Suggestions Title": "Suggestions",
        "Suggestions Description": "Suggestions de soutien",
        "Consent Title": "Consentement",
        "Consent Description": "Enregistrement du consentement du patient",
        "Profile Title": "Profil",
        "Profile Description": "Le profil du patient",
        "Needs Title": "Besoins Sociaux",
        "Needs Description Patient": "Les besoins sociaux du patient",
        "Context Title": "Contexte",
        "Context Description": "Contexte pertinent",
        "Referral Started": "L’orientation est lancée!",
        "Referral Started S1": "Les organisations sélectionnées sont maintenant contactées.",
        "Referral Started S2": "Vous pouvez assurer le suivi ultérieur de l’orientation dans Zipster.",
        "To Overview": "Vers l’aperçu",
        "Information Sent": "Informations envoyées!",
        "Information Sent S1": "Le patient recevra par e-mail les informations relatives à l'offre choisie.",
        "Information Sent S2": "Notez qu'il ne s'agit pas d'une orientation, mais qu'il appartient au patient de contacter ou non l'offre choisie. Les orientations ne sont possibles que vers les organisations qui peuvent accéder aux orientations via Zipster.",
        "Questionnaire Ended": "Questionnaire Terminé",
        "Questionnaire Ended S1": "L’interrogation du patient n'a pas donné lieu à une orientation ou à l'envoi d'informations par e-mail.",
        "Next": "Suivant",
        "Back": "Retour",
        "Consent Organisations": "Le patient consent à être orienté vers les organisations suivantes:",
        "Consent Organisation": "J'ai le consentement du patient pour que ces organisations le contactent.",
        "Close": "Terminer",
        "Information Services": "Informations sur les autres services",
        "Information Links": "Vous pouvez consulter les informations suivantes et les imprimer si nécessaire.",
        "Information Email": "Le patient souhaite être informé de l'offre suivante par e-mail:",
        "Information Email Consent": "J'ai le consentement du patient pour que ces informations soient envoyées par e-mail",
        "Information Services S1": "Aucune adresse électronique n'a encore été encodée pour le patient",
        "Information Services S2": "Vous pouvez fournir les informations des organisations sélectionnées par e-mail au patient, mais l'adresse électronique est manquante.",
        "Information Services S3": "Vous pouvez également choisir d'imprimer ces informations et de les remettre.",
        "Enter Email": "J’encode l'adresse électronique",
        "Print Information": "Aucun problème, j'imprime les informations",
        "Register Consent": "Enregistrer le consentement",
        "Email Patient": "Adresse électronique du patient",
        "Patient Registration Failed": "Le patient ne peut pas être enregistré",
        "Registration New Patient": "Enregistrement d’un nouveau patient",
        "Register": "Enregistrer",
        "Patient Consent Zipster": "Le patient donne son consentement pour être interrogé via Zipster",
        "Social Context": "Contexte Social",
        "Answer The Question Again": "Répondez à nouveau à la question",
        "SD Awaiting": "En attente",
        "SD Planned": "Entretien planifié",
        "SD Waiting List": "Liste d'attente",
        "SD Support": "Pris en charge",
        "SD Already Supported": "Déjà pris en charge",
        "SD Referred": "Orienté",
        "SD Not Supported": "Non pris en charge",
        "SD No Show": "Ne s’est pas présenté",
        "SD No Support Wanted": "N'accepte pas l'offre",
        "SD Unreachable": "Injoignable",
        "SD Support Ended": "Trajet achevé",
        "Question For": "Question pour",
        "Person": "Personne",
        "Requester": "Demandeur",
        "Request": "Demande de soutien",
        "Status": "Statut",
        "Date": "Date",
        "SD No Requests": "Nous n'avons pas encore trouvé de demandes",
        "SD No Requests Description": "Vous trouverez toujours ici les nouvelles demandes et les demandes traitées",
        "Filter": "Filtrer par: ",
        "Register Follow-up": "Enregistrer le suivi",
        "SD Support Already Ended": "Ce trajet est déjà achevé",
        "Follow-up Registered": "Le suivi a été enregistré!",
        "SR Waiting List": "Nous inscrivons {{givenName}} sur la liste d'attente",
        "SR Support": "Nous avons eu un entretien et nous allons accompagner {{givenName}}",
        "SR Referred": "Nous avons eu un entretien et avons orienté {{givenName}} vers une autre organisation",
        "SR No Support": "Nous avons eu un entretien, mais nous ne sommes pas en mesure d’accompagner {{givenName}}",
        "SR Already Supported": "Un soutien a déjà été mis en place pour {{givenName}}, aucun soutien supplémentaire n'est nécessaire",
        "SR No Show": "{{givenName}} ne s'est pas présenté(e) à notre rendez-vous",
        "SR No Support Wanted": "{{givenName}} ne souhaite pas accepter notre offre",
        "SR Support Ended": "Nous n’accompagnerons plus {{givenName}}",
        "SR Planned": "Nous avons programmé un entretien avec {{givenName}}",
        "SR Unreachable": "Nous n'arrivons pas à joindre {{givenName}}",
        "Social Context Description": "Indication du contexte pertinent par le référent",
        "SR Requester": "Personne à l’initiative de la demande de soutien",
        "Person With Support Need": "Personne ayant un besoin de soutien",
        "Age": "Âge",
        "Send New Registration Link": "Envoyer le lien d'inscription",
        "Residence": "Domicile",
        "Needs Description": "Les besoins de soutien précis",
        "More Information": "Plus d'informations",
        "Service": "Service",
        "Outreaching": "Sensibilisation",
        "Show Full Offer": "Afficher l'offre pour toute la Belgique",
        "Show ELZ Offer": "Afficher l'offre pour ma zone de première ligne",
        "Filter By Need": "Filtrer par besoin de soutien:",
        "Choose Support Need": "Sélectionner le besoin de soutien",
        "Password Guide": "Veuillez choisir un mot de passe d'au moins 10 caractères",
        "Password Changed": "Le mot de passe a été modifié avec succès!",
        "Password Changed S1": "Vous pouvez maintenant vous connecter avec votre nom d'utilisateur et votre nouveau mot de passe.",
        "Password Changed S2": "Vous trouverez votre nom d'utilisateur dans l'e-mail d'enregistrement que vous avez reçu.",
        "To Login": "Vers la connexion",
        "Confirm Password": "Confirmer le mot de passe",
        "Register Me": "M’enregistrer",
        "Consent Signup": "Je souscris aux ",
        "Terms Of Use": "Conditions d'utilisation de Zipster",
        "And The Zipster": "et à la",
        "Privacy Policy": "Politique de confidentialité de Zipster",
        "Signup Welcome": "Bienvenue!",
        "Signup Guidance": "Choisissez votre mot de passe et commencez à utiliser Zipster.",
        "Suggestions No Needs": "Aucune suggestion de soutien pour ce patient",
        "Suggestions No Needs Description": "Aucun besoin psychosocial détecté qui peut être pris en charge par l'offre existante.",
        "More Info Organisation": "Cliquez pour en savoir plus sur cette organisation",
        "Prescription": "Prescription",
        "More Information Prescription": "Cliquez pour en savoir plus sur cette prescription",
        "Prescriptions": "Prescriptions",
        "Suggestions": "Suggestions",
        "Referral VS Informing": "Orienter ou Informer",
        "Referral VS Informing Description": "Il y a 2 types de suggestions dans Zipster.",
        "Referral Explanation": "Procéder à une orientation, et l'organisation vers laquelle vous orientez contacte elle-même le patient.",
        "Informing Explanation": "Fournir des informations sur une organisation que le patient peut contacter lui-même. Dans ce cas, vous ne pouvez pas suivre celui-ci dans Zipster.",
        "Contact Us Main": "Nous contacter",
        "FAQ": "Foire aux questions",
        "Tagline": "Convergences des soins et de l’aide sociale",
        "Login Again": "Veuillez vous connecter à nouveau.",
        "Session Expired": "Votre session a expiré",
        "About Us": "À propos de nous",
        "Website Organisation": "Site Web de l'Organisation",
        "Registration Organisation": "Enregistrement de l' Organisation",
        "Choose Primary Care Area": "Choisissez votre Zone de Première Ligne",
        "Medische Achtergrond": "Antécédents Médicaux",
        "Heeft een chronische ziekte": "Souffre d'une maladie chronique",
        "Heeft een beperking": "Souffre d’un handicap",
        "Heeft een probleem met gezondheidsgeletterdheid": "A un problème de littératie en santé",
        "Kan rekenen op professionele steun/ steun uit de omgeving": "Peut compter sur un soutien professionnel/ de l’entourage",
        "Is ouder/ voogd en/ of mantelzorger": "Est un parent / tuteur et / ou un aidant",
        "Is alleenstaand": "Est isolé(e)",
        "Is er ondersteuning nodig bij het voldoen aan basisbehoeften?": "Un soutien est-il nécessaire concernant les besoins de base ?",
        "Er is ondersteuning nodig bij het voldoen aan basisbehoeften": "Soutien est nécessaire concernant les besoins de base",
        "Er is geen ondersteuning nodig bij het voldoen aan basisbehoeften": "Soutien n'est pas nécessaire concernant les besoins de base",
        "Denk aan maandelijkse kosten voor eten, drinken, wonen en zorg": "Pensez aux coûts mensuels pour la nourriture, les boissons, le logement et les soins",
        "Heeft u voldoende geld om uw rekeningen te betalen?": "Avez-vous suffisamment d'argent pour payer vos factures?",
        "Denk aan voeding/drinken, huur, nutsvoorzieningen, zorg, …": "Pensez à nourriture/boisson, loyer, équipements d’utilité publique, soins, ...",
        "Voldoende geld om de rekeningen te betalen": "Suffisamment d'argent pour payer les factures",
        "Neen, onvoldoende inkomsten": "Non, revenus insuffisants",
        "Niet voldoende geld om de rekeningen te betalen": "Revenus insuffisants",
        "Neen, geen inkomsten": "Non, aucun revenu",
        "Geen inkomsten om de rekeningen te betalen": "Aucun revenu",
        "Ontvangt u als hulpbehoevende/mantelzorger een financiële tegemoetkoming?": "Recevez-vous une aide financière en tant que personne en situation de dépendance/aidant ?",
        "Ontvangt financiële tegemoetkoming": "Reçoit une aide financière",
        "Neen, ik wens geen financiële tegemoetkoming te ontvangen": "Non, je ne souhaite pas recevoir d’aide financière",
        "Wenst geen financiële tegemoetkoming te ontvangen": "Ne souhaite pas recevoir d’aide financière",
        "Neen, ik wens wel een financiële tegemoetkoming te ontvangen": "Non, je souhaite bénéficier d'une aide financière",
        "Wenst een financiële tegemoetkoming te ontvangen": "Souhaite bénéficier d'une aide financière",
        "Is er nood aan ondersteuning met betrekking tot daginvulling?": "Un soutien est-il nécessaire concernant les activités de la journée ?",
        "Er is ondersteuning nodig met betrekking tot daginvulling": "Soutien est nécessaire concernant les activités de la journée",
        "Er is geen ondersteuning nodig met betrekking tot daginvulling": "Soutien n'est pas nécessaire concernant les activités de la journée",
        "Denk aan werk, opleiding en vrije tijd": "Pensez au travail, formation et loisirs",
        "Welke daginvulling is bij u van toepassing?": "Quelle affirmation s'applique à votre cas ?",
        "Ik ga naar school (basisschool, secundair onderwijs)": "Je vais à l'école (école primaire, école secondaire)",
        "Schoolgaand": "Va à l'école",
        "Ervaart u stress/angst op school?": "Ressentez - vous du stress/de l'anxiété à l'école ?",
        "Ervaart stress/angst op school": "Ressent du stress ou de l'anxiété à l'école",
        "Ervaart geen stress/angst op school": "Ne ressent pas du stress ou de l'anxiété à l'école",
        "Bent u tevreden over de richting die u volgt?": "Êtes-vous satisfait(e) de l’orientation que vous suivez ?",
        "Tevreden over de gevolgde schoolrichting": "Satisfait de l'orientation à l'école",
        "Niet tevreden over de gevolgde schoolrichting": "Pas satisfait de l'orientation à l'école",
        "Ik volg een opleiding (volwassenenonderwijs, hoger onderwijs)": "Je suis une formation (enseignement pour adultes, enseignement supérieur)",
        "Volgt opleiding": "Suit une formation",
        "Ervaart u stress/angst bij de opleiding die u volgt?": "Ressentez-vous du stress ou de l'anxiété dans le cadre de la formation que vous suivez ?",
        "Ervaart stress/angst bij opleiding": "Ressent du stress ou de l'anxiété dans le cadre de la formation",
        "Ervaart geen stress/angst bij opleiding": "Ne ressent pas du stress ou de l'anxiété dans le cadre de la formation",
        "Bent u tevreden van de opleiding die u volgt?": "Êtes-vous satisfait(e) de la formation que vous suivez ?",
        "Is tevreden over gevolgde opleiding": "Satisfait de la formation",
        "Is niet tevreden over gevolgde opleiding": "Pas satisfait de la formation",
        "Ik ben werkloos en zoek werk": "Je suis sans emploi et je cherche du travail",
        "Werkloos en op zoek naar werk": "Sans emploi et cherche du travail",
        "Ik werk": "Je travaille",
        "Heeft een job": "A un emploi",
        "Ervaart u stress/angst bij uw job?": "Ressentez-vous du stress ou de l'anxiété dans votre travail ?",
        "Ervaart stress/angst bij job": "Ressent du stress ou de l'anxiété dans le travail",
        "Ervaart geen stress/angst bij job": "Ne ressent pas du stress ou de l'anxiété dans le travail",
        "Bent u tevreden over uw job?": "Êtes-vous satisfait(e) de votre travail ?",
        "Is tevreden over de job": "Satisfait du travail",
        "Is niet tevreden over de job": "Pas satisfait du travail",
        "Ik ben werkloos en zoek geen werk": "Je suis sans emploi et ne cherche pas de travail",
        "Werkloos maar niet op zoek naar werk": "Sans emploi mais ne cherche pas du travail",
        "Heeft u hulp nodig bij het vinden van werk?": "Avez-vous besoin d'aide pour trouver du travail ?",
        "Heeft hulp nodig bij het vinden van werk": "Besoin d'aide pour trouver du travail",
        "Heeft geen hulp nodig bij het vinden van werk": "Pas besoin d'aide pour trouver du travail",
        "Ervaart u stress/angst bij deze zoektocht?": "Cette recherche est-elle une source de stress/d’anxiété ?",
        "Ervaart stress/angst bij het zoeken van werk": "Ressent du stress ou de l'anxiété pour trouver du travail",
        "Ervaart geen stress/angst bij het zoeken van werk": "Ne ressent pas du stress ou de l'anxiété pour trouver du travail",
        "Ik doe vrijwilligerswerk": "J’effectue du bénévolat",
        "Ervaart u stress/angst bij het vrijwilligerswerk?": "Le bénévolat est-il une source de stress/d’anxiété ?",
        "Heeft u ondersteuning nodig bij administratie?": "Avez-vous besoin d'aide pour les formalités administratives ?",
        "Bent u tevreden over de invulling van uw tijd?": "Êtes-vous satisfait(e) de la façon dont vous occupez votre temps ?",
        "Ja": "Oui",
        "Neen": "Non",
        "Neen, ik voel me eenzaam": "Non, je me sens souvent seul(e)",
        "Neen, ik ervaar stress/angst": "Non, je ressens du stress/de l'anxiété",
        "Tevreden over daginvulling": "Satisfait concernant les activités de la journée",
        "Ervaart stress/angst bij daginvulling": "Ressent du stress/de l'anxiété",
        "Voelt zich eenzaam": "Se sent seul",
        "Ik ben met pensioen": "Je suis retraité(e)",
        "Is met pensioen": "Retraité(e)",
        "Is er nood aan ondersteuning met betrekking tot uw woonsituatie/thuiscontext?": "Un soutien est-il nécessaire concernant votre situation de vie/contexte familial ?",
        "Er is ondersteuning nodig met betrekking tot woonsituatie/thuiscontext": "Soutien est nécessaire concernant la situation de vie/contexte familial",
        "Er is geen ondersteuning nodig met betrekking tot woonsituatie/thuiscontext": "Soutien n'est pas nécessaire concernant la situation de vie/contexte familial",
        "Kan u hulp gebruiken bij het huishouden/onderhouden van uw woning?": "Auriez-vous besoin d'aide pour le ménage et l'entretien de votre habitation ?",
        "Kan hulp gebruiken bij het huishouden/onderhouden van uw woning": "Besoin d'aide pour le ménage et l'entretien de l'habitation",
        "Heeft geen nood aan hulp bij het huishouden/onderhouden van uw woning": "Pas besoin d'aide pour le ménage et l'entretien de l'habitation",
        "Kan u hulp gebruiken bij het invullen van administratie?": "Auriez-vous besoin d'aide pour remplir des documents administratifs ?",
        "Kan hulp gebruiken bij het invullen van administratie": "Besoin d'aide pour remplir des documents administratifs",
        "Heeft geen nood aan hulp bij het invullen van administratie": "Pas besoin d'aide pour remplir des documents administratifs",
        "Denk aan administratie zoals belastingsbrieven, mutualiteiten, …": "Pensez aux feuilles d'impôts, mutuelles, ...",
        "Voelt u zich veilig in uw thuissituatie?": "Vous sentez-vous en sécurité dans votre foyer ?",
        "Voelt zich veilig in thuissituatie": "Se sent en sécurité dans le foyer",
        "Voelt zich niet veilig in thuissituatie": "Se ne sent pas en sécurité dans le foyer",
        "Bent u tevreden over uw woning?": "Êtes-vous satisfait(e) de votre logement ?",
        "Tevreden over woning": "Satisfait du logement",
        "Niet tevreden over woning": "Pas satisfait du logement",
        "Is dakloos": "Sans abri",
        "Neen, ik ben niet tevreden over mijn woning (te klein, vochtproblemen …)": "Non, je ne suis pas satisfait(e) de mon logement (trop petit, problèmes d'humidité ...)",
        "Neen, ik ben dakloos": "Non, je suis sans abri",
        "Heeft u als ouder ondersteuning nodig bij de opvoeding van uw kind? Heeft u vragen bij de opvoeding van uw kind?": "En tant que parent, avez-vous besoin de soutien pour élever votre enfant? Avez-vous des questions sur l'éducation de votre enfant ?",
        "Ja, ik ondervind problemen bij het opvoeden van mijn kind": "Oui, j'ai des difficultés à élever mon enfant",
        "Nood aan opvoedingsondersteuning": "Besoin de soutien pour élever l'enfant",
        "Geen nood aan opvoedingsondersteuning": "Pas de besoin de soutien pour élever l'enfant",
        "Is er nood aan ondersteuning op vlak van verslavingsproblematiek?": "Un soutien est-il nécessaire en termes de problèmes de dépendance ?",
        "Let op: volgende vragen polsen naar ondersteuningsnoden die gevoelig (kunnen) liggen bij uw patiënt.": "Remarque: les questions suivantes portent sur les besoins de soutien qui sont (ou pourraient être) sensibles pour votre patient.",
        "Er is nood aan ondersteuning op vlak van migratie/integratie of een verlavingsproblematiek": "Soutien est nécessaire en termes d’immigration/intégration ou de problèmes de dépendance",
        "Er is geen nood aan ondersteuning op vlak van van migratie/integratie of een verlavingsproblematiek": "Soutien n'est pas nécessaire en termes d’immigration/intégration ou de problèmes de dépendance",
        "Is er nood aan ondersteuning bij migratie/integratie?": "Un soutien est-il nécessaire en matière d’immigration/intégration ?",
        "Nood aan ondersteuning bij integratie of migratie": "Soutien nécessaire en matière d’immigration/intégration",
        "Geen nood aan ondersteuning bij integratie of migratie": "Pas de soutien nécessaire en matière d’immigration/intégration",
        "Is er nood aan ondersteuning bij verslavingsproblematiek?": "Un soutien est-il nécessaire dans le cadre d’un problème de dépendance ?",
        "Er is nood aan ondersteuning op vlak van een verslavingsproblematiek": "Il y a un besoin de soutien dans le cadre d’un problème de dépendance",
        "Nood aan ondersteuning bij verslavingsproblematiek": "Soutien est nécessaire dans le cadre d’un problème de dépendance",
        "Geen nood aan ondersteuning bij verslavingsproblematiek": "Soutien n'est pas nécessaire dans le cadre d’un problème de dépendance",
        "Gezondheidsgeletterdheid": "Littératie en santé",
        "Therapietrouw": "Observance thérapeutique",
        "Begeleiding geestelijke gezondheid": "Accompagnement de la santé mentale",
        "Geestelijke gezondheid": "Santé mentale",
        "Geestelijke gezondheid jong volwassenen": "Santé mentale des jeunes adultes",
        "Relatieproblemen": "Problèmes relationnels",
        "Opvoedingsondersteuning": "Soutien à la parentalité",
        "Leerproblemen": "Troubles de l'apprentissage",
        "Eenzaamheid": "Solitude",
        "Hulpbehoevend": "Personne démunie",
        "Nood aan administratieve ondersteuning": "Besoin de soutien administratif",
        "Mantelzorger met nood aan administratieve ondersteuning": "Aidant ayant besoin d'un soutien administratif",
        "Mantelzorger met nood aan ondersteuning": "Aidant ayant besoin de soutien",
        "Mantelzorger met nood aan geestelijke ondersteuning": "Aidant ayant besoin d'un soutien spirituel",
        "Mantelzorger zonder ondersteuningsnood": "Aidant sans besoin de soutien",
        "Zonder inkomsten": "Sans revenus",
        "Onvoldoende inkomsten": "Revenus insuffisants",
        "Rouwverwerking": "Accompagnement au deuil",
        "Persoon met een beperking met nood aan ondersteuning": "Personne handicapée ayant besoin de soutien",
        "Persoon met een chronische ziekte met nood aan ondersteuning": "Personne souffrant d'une maladie chronique et ayant besoin de soutien",
        "Voeding": "Alimentation",
        "Woonproblemen": "Problèmes de logement",
        "Onderhoudsproblemen aan woning": "Problèmes d'entretien du logement",
        "Uithuiszetting": "Expulsion",
        "Beheer financiën": "Gestion des finances",
        "Loopbaanbegeleiding": "Orientation professionnelle",
        "Mobiliteit": "Mobilité",
        "Nood aan financiële tegemoetkoming": "Besoin d'une aide financière",
        "Stress/angst op school": "Stress/anxiété scolaire",
        "Studiekeuze op school": "Choix des études à l'école",
        "Stress/angst tijdens opleiding": "Stress/anxiété pendant la formation",
        "Studiekeuze tijdens opleiding": "Choix des études pendant la formation",
        "Stress/angst op het werk": "Stress/anxiété au travail",
        "Jobkeuze": "Choix de l'emploi",
        "Stress/angst tijdens werkloosheid": "Stress/anxiété pendant le chômage",
        "Zoektocht naar werk": "Recherche d'emploi",
        "Stress/angst": "Stress/anxiété",
        "Onveilige thuiscontext": "Contexte familial insécurisant",
        "Kinderwens": "Désir d’enfant",
        "Tienerzwangerschap": "Grossesse adolescente",
        "Ongewenste zwangerschap": "Grossesse non désirée",
        "Gewenste zwangerschap": "Grossesse désirée",
        "Nood aan kraamzorg": "Besoin de soins de maternité",
        "Nood aan naschoolse opvang": "Besoin de garderie postscolaire",
        "Nood aan opvang tijdens vakantie": "Besoin de garderie pendant les vacances",
        "Zoektocht school": "Recherche d'école",
        "Zoektocht hobby": "Recherche de loisirs",
        "Migratie": "Immigration",
        "Gender en/of seksuele identiteit": "Genre et/ou identité sexuelle",
        "Verslavingsproblematiek": "Problèmes de dépendance",
        "Seksueel geweld/misbruik": "Violence/abus sexuel(le)",
        "Persoon met nood aan juridische ondersteuning": "Personne ayant besoin d'un soutien juridique",
        "Gebruik digitale tools": "Utilisation des outils numériques",
        "Mobiliteit - vervoer": "Mobilité - transport",
        "Mobiliteit - begeleiding": "Mobilité - accompagnement",
        "Mobiliteit - hulpmiddelen": "Mobilité - aides",
        "Mobiliteit - veilige woning": "Mobilité - habitation sûre",
        "Patient empowerment - meer inspraak": "Autonomisation des patients - participation accrue",
        "Patient empowerment - onduidelijk taalgebruik": "Autonomisation des patients - usage linguistique imprécis",
        "Patient empowerment - gehoorproblemen": "Autonomisation des patients - problèmes d'audition",
        "Patient empowerment - te snel gesproken": "Autonomisation des patients - discours trop rapide",
        "Intrafamiliaal geweld": "Violence intrafamiliale",
        "Nood aan opvang kind (-2,5 jaar)": "Besoin de garde d'enfants (-2,5 ans)",
        "Nood aan contact met andere ouders": "Besoin de contact avec d'autres parents",
        "Analfabetisme": "Analphabétisme",
        "Talenkennis": "Connaissances linguistiques",
        "Laaggeletterdheid": "Faible niveau d'alphabétisation",
        "Slaapproblemen": "Problèmes de sommeil",
        "Zoektocht naar de juiste zorgverlener": "Recherche du prestataire de soins adéquat",
        "Ervaren van een ontwikkelingsachterstand": "Retard de développement",
        "Verbeteren fysieke conditie": "Amélioration de la condition physique",
        "Eerste seksuele ervaring": "Première expérience sexuelle",
        "Verwerking scheiding": "Soutien après une séparation",
        "Verwerking miskraam of abortus": "Soutien après une fausse couche ou un avortement",
        "Pestgedrag": "Harcèlement",
        "Suïcide": "Suicide",
        "Gebrek aan toekomstperspectief": "Absence de perspectives d'avenir",
        "Automutilatie": "Automutilation",
        "Stemmen in het hoofd": "Voix dans la tête",
        "Zorgen over dierbare": "Inquiétudes au sujet d'un être cher",
        "Rechtenverkenning": "Exploration des droits",
        "Vergroten weerbaarheid": "Renforcement de la résilience",
        "Nood aan ondersteuning tijdens puberteit": "Besoin de soutien pendant la puberté",
        "Zoektocht naar identiteit": "Quête d'identité",
        "Religie of spiritualiteit": "Religion ou spiritualité",
        "Stress/angst bij studiekeuze": "Stress/anxiété lié(e) au choix d’études",
        "Teleurstellen ouders in schoolcontext": "Déception des parents dans le contexte scolaire",
        "Onaangepaste thuiswerkomgeving in schoolcontext": "Environnement inapproprié pour les devoirs dans le contexte scolaire",
        "Zoektocht naar bijles": "Recherche de cours particuliers",
        "Vinden van aansluiting op school": "Recherche de connexion à l'école",
        "Problemen met leerkracht": "Problèmes avec l’enseignant",
        "Vinden van aansluiting op het werk": "Recherche de connexion au travail",
        "Probleem met leidinggevende": "Problème avec le dirigeant",
        "Zoektocht tijdsbesteding - sport": "Recherche de loisirs - sport",
        "Zoektocht tijdsbesteding - jeugdbeweging": "Recherche de loisirs - mouvement de jeunesse",
        "Zoektocht tijdsbesteding - creatieve invulling": "Recherche de loisirs - épanouissement créatif",
        "Zoektocht tijdsbesteding - sociale invulling": "Recherche de loisirs - épanouissement social",
        "Zoektocht tijdsbesteding - socio-culturele invulling": "Recherche de loisirs - épanouissement socio-culturel",
        "Zoektocht tijdsbesteding - vrijwilligerswerk": "Recherche de loisirs - bénévolat",
        "Zoektocht tijdsbesteding - kamp": "Recherche de loisirs - camp",
        "Zoektocht tijdsbesteding - beperking door thuissituatie": "Recherche de loisirs - limitation par la situation familiale",
        "Nood aan ondersteuning bij het leggen van contacten": "Besoin de soutien pour établir des contacts",
        "Nood aan vertrouwenspersoon": "Besoin de personne de confiance",
        "Gezinshereniging": "Regroupement familial",
        "Dakloosheid": "Sans-abrisme",
        "Zoektocht studentenkot": "Recherche de chambre d'étudiants",
        "Zoektocht nieuwe woonst": "Recherche d'un nouveau logement",
        "Zoektocht pleeggezin": "Recherche de famille d'accueil",
        "Nood aan ondersteuning als niet-begeleide minderjarige": "Besoin de soutien en tant que mineur non accompagné",
        "Nood aan ondersteuning bij energiebeheer": "Besoin d'un soutien pour la gestion de l'énergie",
        "Bewegen op verwijzing": "Bouger sur recommandation",
        "Schuldbemiddeling": "Dettes/médiation de dettes",
        "Mutualiteit": "Mutualité",
        "Andere Diensten": "Autres Services",
        "Palliatieve Zorg": "Soins Palliatifs",
        "Jeugd": "Jeunesse",
        "Senioren": "Seniors",
        "Language": "Langue",
        "Languages": "Langues",
        "Nee, ik heb geen financiële middelen om vrije tijd in te vullen": "Non, je n'ai pas les moyens financiers pour occuper mon temps libre",
        "Heeft geen financiële middelen om vrije tijd in te vullen": "N'a pas les moyens financiers pour occuper son temps libre",
        "Ja, mijn kind heeft een beperking of er is een vermoeden van een beperking": "Oui, mon enfant a un handicap/il y a une suspicion de handicap",
        "Kind met een beperking of een vermoeden van een beperking": "Enfant avec un handicap ou une suspicion de handicap",
        "Is er nood aan ondersteuning bij digitale kwetsbaarheid?": "Y a-t-il besoin de soutien en cas de vulnérabilité numérique ?",
        "Er is nood aan ondersteuning bij digitale kwetsbaarheid": "Il y a un besoin de soutien en cas de vulnérabilité numérique",
        "Heeft u toegang tot het internet?": "Avez-vous accès à l'internet?",
        "Ja, enkel op PC/Laptop": "Oui, uniquement sur PC/ordinateur portable",
        "Heeft toegang tot het internet via PC/Laptop": "A un accès à internet via PC/ordinateur portable",
        "Ja, via de smartphone": "Oui, via le smartphone",
        "Heeft toegang tot het internet via de smartphone": "A un accès à internet via le smartphone",
        "Neen, maar ik zou graag toegang krijgen en het leren": "Non, mais j'aimerais avoir accès et apprendre",
        "Zou graag toegang krijgen tot het internet en het leren": "Aimerait avoir accès à internet et apprendre",
        "Neen, ik heb geen interesse of zie geen meerwaarde in het internet": "Non, je n'ai pas d'intérêt ou je ne vois pas de valeur ajoutée à l'internet",
        "Heeft geen interesse in het internet": "N'a pas d'intérêt pour l'internet",
        "Ervaart u het als een probleem om niet mee te zijn in digitale vernieuwingen?": "Considérez-vous comme un problème de ne pas être à jour avec les innovations numériques ?",
        "Ziet het niet als een probleem om niet mee te zijn in digitale vernieuwingen": "Ne voit pas cela comme un problème de ne pas être à jour avec les innovations numériques",
        "Ja, ik wil graag bijleren (algemeen of specifiek)": "Oui, je souhaite apprendre davantage (en général ou spécifiquement)",
        "Wil graag bijleren op digitaal vlak": "Souhaite apprendre davantage dans le domaine numérique",
        "Ja, maar ik heb hulp": "Oui, mais j'ai de l'aide",
        "Heeft hulp bij het gebruik van digitale tools": "A besoin d'aide pour l'utilisation des outils numériques",
        "Nederlands": "Néerlandais",
        "Frans": "Français"
      }
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;