export function authHeader() {
    // return authorization header with basic auth credentials
    let userCredentials = localStorage.getItem('userCredentials');
    if (userCredentials) {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCredentials
        };
    } else {
        return {};
    }
}

export function authProblemHeader() {
    // return authorization header with basic auth credentials
    let userCredentials = localStorage.getItem('userCredentials');
    if (userCredentials) {
        return {
            'Accept': 'application/problem+json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCredentials
        };
    } else {
        return {};
    }
}

export function authHeaderSet() {
    // return authorization header with basic auth credentials
    let userCredentials = localStorage.getItem('userCredentials');

    if (userCredentials) {
        return {
            'Accept': 'application/hal+json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCredentials
        };
    } else {
        return {};
    }
}

export function authHeaderPatch() {
    // return authorization header with basic auth credentials
    let userCredentials = localStorage.getItem('userCredentials');

    if (userCredentials) {
        return {
            'Accept': 'application/problem+json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + userCredentials
        };
    } else {
        return {};
    }

}

export function anonHeader() {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
}

export function recaptchaHeader(recaptchaValue) {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'recaptcha-token': recaptchaValue
    }
}