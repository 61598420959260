import React, { Component } from 'react';
import { Segment, Header, Accordion, Icon, Divider, Label, List } from 'semantic-ui-react';

class Faq extends Component {

    state = { activeIndex: 0 }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
    }

    render() {
        const { activeIndex } = this.state
        return <Segment>
            <Segment basic>
                <Header>Algemene Vragen</Header>
                <Divider />
                <Accordion fluid>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Wat is Zipster?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <p>Zipster is een digitaal doorverwijzingsplatform dat zorgverleners helpt bij het doorverwijzen van patiënten naar lokale welzijnsactoren. Op basis van een psychosociale bevraging van de patiënt kan de zorgverlener via Zipster de ondersteuningsnoden van de patiënt detecteren én een doorverwijzing initiëren naar de juiste lokale ondersteunende welzijnsactoren.</p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Wie zijn de initiatiefnemers?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <p>Zipster is een iniatief van <a href="https://healthendeavour.agency/">Health Endeavour</a> en <a href="http://normalizedsystems.org/">NSX</a> in samenwerking met de volgende kennispartners:</p>
                        <List>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>VIVEL</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Intermutualistisch Overleg</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Domus Medica</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>CAW</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>SAM vzw</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Centrum voor Huisartsengeneeskunde Universiteit Antwerpen</List.Content>
                            </List.Item>
                        </List>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Is Zipster gratis?</Label></Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <p>Zipster werd mogelijk gemaakt dankzij de financiële steun van VIVEL en is een gratis platform voor zowel zorgverleners als dienstverleners.</p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 3}
                        index={3}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Vanwaar de naam Zipster?</Label></Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                        <p>De naam Zipster heeft een viervoudige betekenis:</p>
                        <List>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Zip van Zip Code wat Engels is voor postcode en wat het lokale aspect van Zipster benadrukt.</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Zip van Zipper of rits in het Engels. Zipster wil zorg en welzijn ritsgewijs verbinden!</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Zip staat ook voor enthousiasme en energie, iets wat er in zorg en welzijn absoluut aanwezig is!</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name="right triangle" color='blue' />
                                <List.Content>Vanwege de link met het Zorg in Huisartspraktijk project van Stad Antwerpen of kortweg ZIHP waarbij sociaal verpleegkundigen van de stad huisartsenpraktijken ondersteunen met hun netwerk van gezondheids- en welzijnsactoren. Deze sociaal verpleegkundigen worden ook wel ZIPPERS genoemd. </List.Content>
                            </List.Item>
                        </List>
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment basic>
                <Header>Vragen van Doorverwijzers</Header>
                <Divider />
                <Accordion fluid >
                    <Accordion.Title
                        active={activeIndex === 4}
                        index={4}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Kan ik Zipster gebruiken voor dringende ondersteuning?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                        <p>Neen. Voor dringende ondersteuning is het belangrijk dat u telefonisch contact opneemt met de juiste dienstverleners. Zipster kan geen garanties geven over wanneer een ondersteuningsvraag kan worden behandelt door een dienstverlenende organisatie.</p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 5}
                        index={5}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Dien ik bij de vragenlijst alle vragen te overlopen met de patiënt?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                        <p>Dit is geenszins verplicht in Zipster.</p><p>Heeft u enkel nog de tijd om te polsen naar de gezinssituatie bijvoorbeeld dan kan u enkel deze vraag beantwoorden en zal u suggesties krijgen op basis van de antwoorden.</p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 6}
                        index={6}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Er ontbreekt een organisatie in het aanbod. Waar kan ik dit melden?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 6}>
                        <p>Zipster is een neutraal platform dat een zo goed mogelijk overzicht wil geven van het lokale ondersteuningsaanbod.</p><p>Ontbreekt er een organisatie? Laat het ons weten via <a href="mailto:hallo@zipster.care">hallo@zipster.care</a></p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 7}
                        index={7}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Welke informatie krijgt de patiënt?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 7}>
                        <p>Wanneer u het e-mail adres van de patient registreert in Zipster zal de patiënt een mail ontvangen wanneer hij wordt doorverwezen naar een bepaalde organisatie. Hierin staat enkel dat u hem als huisarts heeft doorverwezen naar deze organisatie en die organisatie met hem contact zal opnemen.</p>
                        <p>Voor organisaties waar u niet kan naar doorverwijzen kan u in Zipster wel informatiebrochures selecteren. Wanneer u dit doet zal de patiënt deze ook via e-mail ontvangen. Voor patiënten zonder e-mail kan u deze brochure ook afdrukken indien gewenst.</p>
                    </Accordion.Content>
                </Accordion>
            </Segment>
            <Segment basic>
                <Header>Vragen van Dienstverlenende Organisaties</Header>
                <Divider />
                <Accordion fluid >
                    <Accordion.Title
                        active={activeIndex === 8}
                        index={8}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Kan ik een collega toegang geven tot Zipster?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 8}>
                        <p>Dit kan wanneer u beheerder bent voor uw organisatie. Dan kan u via <a href="/organisation">Mijn Organisatie</a> medewerkers toevoegen.</p>
                    </Accordion.Content>
                    <Accordion.Title
                        active={activeIndex === 9}
                        index={9}
                        onClick={this.handleClick}>
                        <Icon name='dropdown' /><Label color='blue'>Kan het e-mail adres wijzingen waarop ik een notificatie krijg van een nieuwe aanmelding?</Label>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 9}>
                        <p>Dit kan u aanvragen door ons te contacteren via <a href="mailto:hallo@zipster.care">hallo@zipster.care</a>.</p>
                    </Accordion.Content>
                </Accordion>
            </Segment>
        </Segment >
    }
}

export default Faq;
