import React, { Component, Fragment } from 'react';
import { Menu, Image, Responsive, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeader } from '../helpers/auth-header.js';
import Configuration from '../config/Configuration.js';

import logo from '../images/logo-colour-small.png';
import icon from '../images/zipster-symbol-orange.png';

class ZipsterMenu extends Component {

    constructor(props) {
        super(props);
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            organizationType: '',
        }
    }

    handleError(error) {
        console.log(error.message);
    }

    componentDidMount() {
        this.retrieveOrganization();
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.activeUser.organization.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.setState({
                    organizationType: organization.type
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getActiveUserMenuItem = () => {
        if (this.state.activeUser) {
            let name = this.state.activeUser.name;
            if (this.state.activeUser.speciality && this.state.activeUser.speciality === "Huisarts") {
                name = "Dr. " + name;
            } else if (this.state.activeUser.speciality && this.state.activeUser.speciality === "Apotheker") {
                name = "Apotheker " + name;
            }
            return <Menu.Menu position='right'>
                <Menu.Item as={Link} to='/profile' name={name} />
            </Menu.Menu>;
        }
    }

    getSubMenus = () => {
        const { t } = this.props;
        if (this.state.activeUser.isObserver) {
            if (this.state.organizationType.includes(this.config.HYBRID_ORGANISATION)) {
                return <Fragment>
                    <Menu.Item
                        name='doorverwijzingen'
                        as={Link} to='/referrals'>
                        <Icon name='mail forward' />
                        {t("Referrals")}
                    </Menu.Item>
                    <Menu.Item
                        name='aanvragen'
                        as={Link} to='/requests'>
                        <Icon name='street view' />
                        {t("Requests")}
                    </Menu.Item>
                </Fragment>
            } else {
                return <Menu.Item
                    name='aanvragen'
                    as={Link} to='/'>
                    <Icon name='street view' />
                    {t("Requests")}
                </Menu.Item>
            }
        } else {
            return <Menu.Item
                name='doorverwijzingen'
                as={Link} to='/'>
                <Icon name='mail forward' />
                {t("Referrals")}
            </Menu.Item>
        }
    }

    getMenu = () => {
        const { t } = this.props;
        return <Fragment>
            <Responsive minWidth={1025}>
                <Menu fixed='top' borderless inverted color='blue' size='small'>
                    <Menu.Item as={Link} to='/' header>
                        <Image size='small' src={logo} spaced='left' />
                    </Menu.Item>
                    {this.getActiveUserMenuItem()}
                </Menu>
            </Responsive>
            <Responsive maxWidth={1024}>
                <Menu fixed='top' borderless inverted color='blue' size='mini' icon='labeled'>
                    <Menu.Menu>
                        <Menu.Item as={Link} to='/' header>
                            <Image src={icon} size='mini' spaced='left' align='right' />
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right'>
                        {this.getSubMenus()}
                        <Menu.Item
                            name='beheer'
                            as={Link} to='/profile'>
                            <Icon name='edit' />
                            {t("My Profile")}
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Responsive>
        </Fragment>;
    }

    render() {
        return this.getMenu();
    }
}

export default withTranslation()(ZipsterMenu);;