import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, Header, Segment, Button, Divider, Grid, Menu, Form, Checkbox, Tab, Label, Table, Modal, List, Input, Message, Popup, Loader } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import ServiceUtils from '../utils/ServiceUtils.js';
import { authHeader, authHeaderSet, anonHeader } from '../helpers/auth-header.js';
import { withTranslation } from 'react-i18next';


class NeedsBasedReferral extends Component {

    OTHER_SERVICES = "Andere Diensten";
    LEAFLET_TYPE = "LEAFLET";
    REFERRAL_TYPE = "REFERRAL";

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            services: [],
            codableConcepts: [],
            selectedCodableConcepts: [],
            codableConceptMappings: [],
            referralSuccessful: false,
            showServices: false,
            selectedServices: [],
            patientName: '',
            patientGivenName: '',
            postalCode: ''
        }
    }

    createEncounter = (patientId, practitionerRoleId) => {
        let pid = patientId;
        if (!patientId) {
            pid = this.state.patientId;
        }
        return fetch(this.config.ENCOUNTERS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                subject: pid,
                participant: practitionerRoleId,
                date: new Date(),
                type: this.config.ENCOUNTER_CONSULT_NEEDS_BASED
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(encounter => {
            this.setState({ encounterId: encounter.id });
            return encounter.id;
        }).catch(error => {
            this.handleError(error);
        });
    }

    createConsent(patientId, practitionerRoleId) {
        return fetch(this.config.CONSENTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                status: "Active",
                scope: "patient-privacy",
                patient: patientId,
                performer: practitionerRoleId,
                organization: this.state.chosenService.provider.uuid,
                policy: "limited"
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    createReferral = () => {
        let scope = this;
        let practitionerRoleId = this.state.activeUser.uuid;
        let patientId = this.props.location.state.patientId;
        let encounterPromise = this.createEncounter(patientId, practitionerRoleId);
        if (encounterPromise) {
            encounterPromise.then(function (encounter) {
                scope.registerServiceRequests();
            })
        }
    }

    getMailableResource = (healthcareService) => {
        return fetch(this.config.MAILABLE_RESOURCES_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(mailableResources => {
                let resources = mailableResources._embedded.mailableResources;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    registerServiceRequests() {
        let chosenSupportNeeds = [];
        for (var i = 0; i < this.state.selectedCodableConcepts.length; i++) {
            let codableConcept = this.state.selectedCodableConcepts[i];
            chosenSupportNeeds.push(codableConcept.uuid);
        }
        for (var j = 0; j < this.state.selectedServices.length; j++) {
            let selectedService = this.state.selectedServices[j];
            let intent = '';
            if (selectedService.referralMethod === "NONE") {
                intent = this.config.SERVICE_REQUEST_INTENT_SIGNPOSTING;
            }
            fetch(this.config.SERVICEREQUESTS_URL, {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    encounter: this.state.encounterId,
                    performer: selectedService.defaultAssignedHealthcareService.uuid,
                    notes: this.state.socialContextAnswer,
                    codableConcepts: chosenSupportNeeds,
                    intent: intent
                })
            }).then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                } else {
                    return response.json();
                }
            }).catch(error => {
                this.handleError(error);
            });
        }
        this.setState({ referralSuccessful: true })
    }

    addServiceToOffer = (service) => {
        let serviceAlreadyOffered = false;
        for (var i = 0; i < this.state.services.length; i++) {
            let offeredService = this.state.services[i];
            if (offeredService.name === service.name) {
                serviceAlreadyOffered = true;
            }
        }
        if (!serviceAlreadyOffered) {
            let currentServices = this.state.services;
            currentServices.push(service);
            this.setState({
                services: currentServices
            });
        }
    }

    getServiceDetailsBxlSocial = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsSocialeKaart = (serviceId) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetails = (reference) => {
        let bxlSocialId = ServiceUtils.getBxlSocialReference(reference);
        if (bxlSocialId) {
            let serviceDetails = this.getServiceDetailsBxlSocial(bxlSocialId);
            return serviceDetails;
        } else {
            let serviceDetails = this.getServiceDetailsSocialeKaart(reference);
            return serviceDetails;
        }
    }

    getBxlSocialDetails = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.config.BXL_SOCIAL;
                let healthcareService = this.state.healthcareService;
                healthcareService.serviceDetails = serviceDetails;
                this.setState({
                    healthcareService: healthcareService
                })
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getSocialeKaartLeaflet = (healthcareService) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + healthcareService.socialeKaartReference, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.config.SOCIALE_KAART;
                healthcareService.serviceDetails = leaflet;
                this.setState({
                    healthcareService: healthcareService
                })
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServices = (concept) => {
        let query = "?pagesize=100&location=" + this.state.location;
        if (concept) {
            query = query + "&codableConcept=" + concept;
        } else {
            return;
        }
        return fetch(this.config.HEALTHCARESERVICES_URL + query, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(services => {
                let currentComponent = this;
                for (var i = 0; i < services._embedded.healthcareServices.length; i++) {
                    let service = services._embedded.healthcareServices[i];
                    let resourcePromise = this.getMailableResource(service);
                    if (resourcePromise) {
                        resourcePromise.then(function (resources) {
                            service.mailableResources = resources;
                            if (service.socialeKaartReference) {
                                currentComponent.getServiceDetails(service.socialeKaartReference).then(function (serviceDetails) {
                                    service.serviceDetails = serviceDetails;
                                    currentComponent.addServiceToOffer(service);
                                })
                            } else {
                                currentComponent.addServiceToOffer(service);
                            }
                        })
                    };
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveLocation = () => {
        let postalCode = this.state.postalCode;
        if (!postalCode) {
            postalCode = this.props.location.state.postalCode;
        }
        return fetch(this.config.LOCATIONS_URL + "?code=" + postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.setState({ location: location.uuid });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConcepts() {
        let codableTexts = [];
        this.setState({ codableConcepts: [] });
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=100&system="
            + this.config.CODABLE_CONCEPT_SYSTEM
            + "&location=" + this.state.location, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConceptMappings() {
        let codableConceptMappings = [];
        return fetch(this.config.CODABLECONCEPTMAPPINGS_URL + "?pagesize=800", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConceptMappings;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let codableConceptUuid = concept.codableConcept.uuid;
                    codableConceptMappings.push({ uuid, codableConceptUuid });
                }
                this.setState({ codableConceptMappings: codableConceptMappings });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    handleSocialContextChange = (e, { value }) => { this.setState({ socialContextAnswer: value }) }

    handleError(error) {
        console.log(error.message);
    }

    registerConsent = () => {
        this.setState({
            consentGiven: !this.state.consentGiven
        });
    }

    simplifySuggestionCategories = (categories) => {
        let remainingCategories = [];
        let simplifiedCategory = [this.OTHER_SERVICES, []];
        let toBeRemovedCategories = [];
        for (var i = 0; i < categories.length; i++) {
            let category = categories[i];
            if (category[1].length === 1) {
                toBeRemovedCategories.push(category[0]);
                simplifiedCategory[1].push(category[1][0]);
            }
        }
        for (var j = 0; j < categories.length; j++) {
            let category = categories[j];
            if (!toBeRemovedCategories.includes(category[0])) {
                remainingCategories.push(category);
            }
        }
        remainingCategories.push(simplifiedCategory);
        return remainingCategories;
    }

    addSuggestionToCategories = (suggestion, categories) => {
        let type = suggestion.type;
        let resultingCategories = categories;
        let added = false;
        if (type === null || type === "") {
            type = this.OTHER_SERVICES;
        }
        for (var i = 0; i < categories.length; i++) {
            let category = categories[i];
            if (category[0] === type) {
                let suggestions = category[1];
                suggestions.push(suggestion);
                resultingCategories[i][1] = suggestions;
                added = true;
                break;
            }
        }
        if (!added) {
            let suggestions = [suggestion];
            let newCategory = [type, suggestions];
            resultingCategories.push(newCategory);
        }
        return resultingCategories;
    }

    getSuggestionCategories = (referralMethod) => {
        let categories = [];
        for (var i = 0; i < this.state.services.length; i++) {
            let suggestion = this.state.services[i];
            if (suggestion.referralMethod === referralMethod) {
                categories = this.addSuggestionToCategories(this.state.services[i], categories);
            }
        }
        categories = this.simplifySuggestionCategories(categories);
        return categories;
    }

    getPractitionerNameInOverview = (service) => {
        if (service.practitioner) {
            return <p>{service.practitioner.name}</p>;
        }
    }

    getMatchingConcepts = (concepts) => {
        let matchingConcepts = [];
        for (var i = 0; i < this.state.selectedCodableConcepts.length; i++) {
            let selectedConcept = this.state.selectedCodableConcepts[i];
            for (var j = 0; j < concepts.length; j++) {
                let concept = concepts[j];
                if (concept.uuid === selectedConcept.uuid) {
                    matchingConcepts.push(selectedConcept);
                }
            }
        }
        return matchingConcepts;
    }

    getCodableConcepts = (suggestion) => {
        const { t } = this.props;
        let concepts = this.getMatchingConcepts(suggestion.codableConcepts);
        if (concepts) {
            return <Fragment>
                <List divided>{
                    concepts.slice(0, concepts.length).map((concept, index) => {
                        return <List.Item key={index}>{t(concept.text)}</List.Item>;
                    }
                    )
                }</List></Fragment>;
        }
    }

    selectService = (healthcareService) => {
        if (this.state.selectedServices.includes(healthcareService)) {
            const index = this.state.selectedServices.indexOf(healthcareService);
            if (index > -1) {
                this.state.selectedServices.splice(index, 1);
                this.setState({ selectedServices: this.state.selectedServices });
            }
        } else {
            this.state.selectedServices.push(healthcareService);
            this.setState({ selectedServices: this.state.selectedServices });
        }
    }

    serviceSelected = (healthcareService) => {
        if (this.state.selectedServices.includes(healthcareService)) {
            return true
        } else {
            return false;
        }
    }

    getDownloadReference = (healthcareService) => {
        return healthcareService.mailableResources.slice(0, healthcareService.mailableResources.length).map((mailableResource, index) => {
            return <a href={mailableResource.resoureceUrl} target="_blank" rel="noopener noreferrer"> <Button size='mini' icon circular color='blue'><Icon name="paperclip" /></Button></a>
        });
    }

    getReferralSuggestionsForCategory = (category) => {
        const { t } = this.props;
        let type = category[0];
        let suggestions = category[1];
        if (!type) {
            type = t("Other Services");
        } else if (type.trim() === "") {
            type = t("Other Services");
        }
        if (suggestions.length > 0) {
            return <Fragment>
                {this.getHealthcareServiceModal()}
                <Segment>
                    <Label color='blue' size='large' ribbon>{t(type)}</Label>
                    <Table basic='very'>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell width={6}>{t("Organisation")}</Table.HeaderCell>
                                <Table.HeaderCell width={4}>{t("Contact Person")}</Table.HeaderCell>
                                <Table.HeaderCell width={3}>{t("Telephone")}</Table.HeaderCell>
                                <Table.HeaderCell width={3}>{t("Needs")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {suggestions.slice(0, suggestions.length).map((suggestion, index) => {
                                return <Fragment>
                                    <Table.Row key={index}>
                                        <Table.Cell collapsing>
                                            <Checkbox name={suggestion.name} checked={this.serviceSelected(suggestion)} onChange={this.selectService.bind(this, suggestion)} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.getHealthcareServiceHeader(suggestion)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.getPractitionerNameInOverview(suggestion)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {suggestion.phone}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.getCodableConcepts(suggestion)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Fragment>
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>;
        }
    }

    getLeafletSuggestionsForCategory = (category) => {
        const { t } = this.props;
        let type = category[0];
        let suggestions = category[1];
        if (!type) {
            type = t("Other Services");
        } else if (type.trim() === "") {
            type = t("Other Services");
        }
        if (suggestions.length > 0) {
            return <Fragment>
                {this.getHealthcareServiceModal()}
                <Segment>
                    <Label color='blue' size='large' ribbon>{type}</Label>
                    <Table basic='very'>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell width={6}>{t("Organisation")}</Table.HeaderCell>
                                <Table.HeaderCell width={7}>{t("Links")}</Table.HeaderCell>
                                <Table.HeaderCell width={3}>{t("Needs")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {suggestions.slice(0, suggestions.length).map((suggestion, index) => {
                                return <Fragment>
                                    <Table.Row key={index}>
                                        <Table.Cell collapsing>
                                            <Checkbox name={suggestion.name} checked={this.serviceSelected(suggestion)} onChange={this.selectService.bind(this, suggestion)} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.getHealthcareServiceHeader(suggestion)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.getDownloadReference(suggestion)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.getCodableConcepts(suggestion)}
                                        </Table.Cell>
                                    </Table.Row>
                                </Fragment>
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>;
        }
    }

    getLeaflets = () => {
        const { t } = this.props;
        let suggestionCategories = this.getSuggestionCategories("NONE");
        if (suggestionCategories.length > 0 && (suggestionCategories[0][1].length > 0)) {
            return <Fragment>
                <Segment basic>
                    {suggestionCategories.slice(0, suggestionCategories.length).map((category, index) => {
                        return this.getLeafletSuggestionsForCategory(category);
                    })
                    }
                </Segment>
            </Fragment>;
        } else {
            return <Segment basic>
                <Message>{t("No Offer Found")}</Message>
            </Segment>;
        }
    }

    getReferrals = () => {
        const { t } = this.props;
        let suggestionCategories = this.getSuggestionCategories("email");
        if (suggestionCategories.length > 0 && (suggestionCategories[0][1].length > 0)) {
            return <Fragment>
                <Segment basic>
                    {suggestionCategories.slice(0, suggestionCategories.length).map((category, index) => {
                        return this.getReferralSuggestionsForCategory(category);
                    })
                    }
                </Segment>
            </Fragment>
        } else {
            return <Segment basic>
                <Message>{t("No Offer Found")}</Message>
            </Segment>;
        }
    }

    getHCSDescription = (comment) => {
        let description = comment;
        try {
            let commentObj = JSON.parse(comment);
            description = commentObj.description;
            description = description.split('\n').map(str => <p>{str}</p>);
        } catch (e) {
        }
        return description;
    }

    getReferralMethod = (referralMethod) => {
        const { t } = this.props;
        let directReferral = false;
        if (referralMethod === "email") {
            directReferral = true;
        }
        if (directReferral) {
            return <p><Icon color='green' name='check' />{t("Direct Referrals Through Zipster")}</p>;
        } else {
            return <p><Icon color='red' name='times' />{t("No Direct Referrals Through Zipster")}</p>;
        }
    }

    getHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>{healthcareService.name}</Header>;
    }

    handleOpenHealthcareServiceModal = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            }, () => {
                let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
                if (bxlSocialId) {
                    this.getBxlSocialDetails(bxlSocialId);
                } else {
                    this.getSocialeKaartLeaflet(healthcareService);
                }
            })
        } else {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            });
        }
    }

    handleCloseHealthcareServiceModal = () => {
        this.setState({ showHealthcareServiceModal: false });
    }

    ////// SERVICE RENDERING ELEMENTS //////

    // ZIPSTER AS SOURCE //

    getEmail = (service) => {
        if (service.email) {
            return <p><Icon name='mail' color='blue' />{service.email}</p>;
        }
    }

    getPhone = (service) => {
        if (service.phone) {
            return <p><Icon name='phone' color='blue' />{service.phone}</p>;
        }
    }

    getPractitionerName = (service) => {
        if (service.practitioner) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='user' color='blue' />
                </Grid.Column>
                <Grid.Column width={14}>
                    {service.practitioner.name}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getCodableConceptsForService = (service) => {
        let codableConceptTexts = [];
        if (this.state.codableConcepts) {
            for (var i = 0; i < service.codableConcepts.length; i++) {
                let serviceCodableConcept = service.codableConcepts[i];
                for (var j = 0; j < this.state.codableConcepts.length; j++) {
                    let codableConcept = this.state.codableConcepts[j];
                    if (codableConcept.uuid === serviceCodableConcept.uuid) {
                        codableConceptTexts.push(codableConcept.text);
                    }
                }
            }
            if (this.state.compactView) {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) =>
                        <List.Item key={index}><List.Icon name='check circle outline' color='blue' key={index} />{codableConceptText}</List.Item>
                    )}
                </List>;
            } else {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) =>
                        <List.Item key={index}><List.Icon name='angle double right' color='blue' key={index} />{codableConceptText}</List.Item>
                    )}
                </List>;
            }
        } else {
            return <Loader active inline='centered' />;
        }

    }

    getHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>{healthcareService.name}</Header>;
    }

    getSocialeKaartLabel = (healthcareService) => {
        const { t } = this.props;
        if (healthcareService.socialeKaartReference) {
            if (this.state.showHealthcareServiceModal) {
                return <Popup content={t("Source Sociale Kaart")} size='mini' trigger={<Icon floated size='large' color='orange' name='database' />} />;
            } else {
                return <Popup content={t("Source Sociale Kaart")} size='mini' trigger={<Icon floated size='mini' color='orange' name='database' />} />;
            }
        } else {
            if (this.state.showHealthcareServiceModal) {
                return <Popup content={t("Source Zipster")} size='mini' trigger={<Icon floated size='large' color='blue' name='database' />} />;
            } else {
                return <Popup content={t("Source Zipster")} size='mini' trigger={<Icon floated size='mini' name='database' />} />;
            }
        }
    }

    getPhoneFromService = (service) => {
        if (service.phone) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='phone' color='blue' />
                </Grid.Column>
                <Grid.Column>
                    {service.phone}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getEmailFromService = (service) => {
        if (service.healthcareServiceEmails && service.healthcareServiceEmails.length > 0 && service.healthcareServiceEmails[0]) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='mail' color='blue' />
                </Grid.Column>
                <Grid.Column>
                    {service.healthcareServiceEmails[0]}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getContactInfoFromService = (service) => {
        const { t } = this.props;
        return <Fragment>
            <Header size='small'>{t("Contact Details")}</Header>
            <Divider />
            <Grid columns={2} relaxed>
                {this.getPhoneFromService(service)}
                {this.getEmailFromService(service)}
            </Grid>
        </Fragment>
    }

    showZipsterService = (healthcareService) => {
        const { t } = this.props;
        return <Segment basic>
            <Grid columns={2}>
                <Grid.Column>
                    <Segment basic>
                        <Header size='small'>{t("Service Description")}</Header>
                        <Divider />
                        <p>{this.getHCSDescription(healthcareService.comment)}</p>
                        <Header size='small'>{t("Characteristics")}</Header>
                        <Divider />
                        <p>{this.getReferralMethod(healthcareService.referralMethod)}</p>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment basic>
                        {this.getSupportNeeds(healthcareService)}
                        {this.getParentOrganizationFromService(healthcareService)}
                        {this.getContactInfoFromService(healthcareService)}
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>;
    }

    showZipsterServiceForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return this.showZipsterService(this.state.healthcareService);
        } else {
            return this.showZipsterService(healthcareService);
        }
    }

    getParentOrganizationFromService = (healthcareService) => {
        const { t } = this.props;
        return <Fragment>
            <Header size='small'>{t("Organisation")}</Header>
            <Divider />
            {healthcareService.provider.name}
        </Fragment>;
    }

    getSupportNeeds = (healthcareService) => {
        const { t } = this.props;
        return <Segment secondary raised>
            <Header size='small'>{t("Support Needs")}</Header>
            <Divider />
            {this.getCodableConceptsForService(healthcareService)}
        </Segment>;
    }

    showExternalServiceInfoForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showExternalServiceInfo(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showExternalServiceInfo(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    getServiceBody = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            return this.showExternalServiceInfoForMode(healthcareService);
        } else {
            return this.showZipsterServiceForMode(healthcareService);
        }
    }

    // MODAL OPERATIONS //

    getHealthcareServiceModal = () => {
        const { t } = this.props;
        if (this.state.healthcareService) {
            return <Modal
                open={this.state.showHealthcareServiceModal}
                onClose={this.handleCloseHealthcareServiceModal}>
                <Modal.Header>
                    {ServiceUtils.getSocialeKaartLabel(this.state.healthcareService, this.state.showHealthcareServiceModal, this.props)}
                    {this.state.healthcareService.name}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.getServiceBody(this.state.healthcareService)}
                        {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={this.handleCloseHealthcareServiceModal}>
                        <Icon name='close' />{t("Close Window")}
                    </Button>
                </Modal.Actions>
            </Modal>
        }
    }

    getColor = (concept) => {
        if (this.state.selectedCodableConcepts.includes(concept)) {
            return 'blue';
        } else {
            return 'white';
        }
    }

    cleanUpServices = () => {
        var allServices = [...this.state.services];
        for (var i = 0; i < this.state.services.length; i++) {
            let service = this.state.services[i];
            let concepts = service.codableConcepts;
            let offersSupportForSelectedConcepts = false;
            for (var j = 0; j < concepts.length; j++) {
                let serviceConcept = service.codableConcepts[j];
                for (var k = 0; k < this.state.selectedCodableConcepts.length; k++) {
                    let selectedConcept = this.state.selectedCodableConcepts[k];
                    if (selectedConcept.uuid === serviceConcept.uuid) {
                        offersSupportForSelectedConcepts = true;
                        break;
                    }
                }
                if (offersSupportForSelectedConcepts) {
                    break;
                }
            }
            if (!offersSupportForSelectedConcepts) {
                var index = allServices.indexOf(service);
                if (index !== -1) {
                    allServices.splice(index, 1);
                }
            }
        }
        this.setState({
            services: allServices
        });
    }

    selectConcept = (concept) => {
        let serviceRemoved = false;
        if (this.state.selectedCodableConcepts.includes(concept)) {
            var selected = [...this.state.selectedCodableConcepts];
            var index = selected.indexOf(concept)
            if (index !== -1) {
                selected.splice(index, 1);
                serviceRemoved = true;
                this.setState({
                    selectedCodableConcepts: selected
                }, () => {
                    if (selected.length === 0) {
                        this.setState({
                            services: []
                        });
                    } else {
                        this.cleanUpServices();
                    }
                }
                );
            }
        } else {
            let selected = this.state.selectedCodableConcepts;
            selected.push(concept);
            this.setState({
                selectedCodableConcepts: selected
            });
        }
        if (!serviceRemoved) {
            this.retrieveServices(concept.uuid);
        }
    }

    editNeeds = () => {
        this.setState({
            showServices: false
        });
    }


    getCodableConceptsWithSearch = (concepts) => {
        const { t } = this.props;
        if (this.state.codableConceptQuery) {
            let filteredNeeds = [];
            for (var i = 0; i < concepts.length; i++) {
                let concept = concepts[i];
                if (t(concept.text).toLowerCase().includes(this.state.codableConceptQuery.toLowerCase())) {
                    filteredNeeds.push(concept);
                }
            }
            return filteredNeeds;
        } else {
            return concepts;
        }
    }

    showCodableConcepts = () => {
        const { t } = this.props;
        if (this.state.codableConcepts && this.state.codableConcepts.length > 0) {
            let availableConcepts = this.getCodableConceptsWithSearch(this.state.codableConcepts);
            availableConcepts = availableConcepts.sort((a, b) => t(a.text).localeCompare(t(b.text)));
            if (this.state.showServices) {
                return <Fragment>
                    <Label.Group size='medium'>
                        {this.state.selectedCodableConcepts.slice(0, this.state.selectedCodableConcepts.length).map((codableConcept, index) =>
                            <Label color={this.getColor(codableConcept)}>{t(codableConcept.text)}</Label>
                        )}
                        <Button circular icon='edit' color='orange'
                            size='mini' onClick={this.editNeeds} />
                    </Label.Group>
                </Fragment>;
            } else {
                return <Label.Group size='medium'>
                    {availableConcepts.slice(0, availableConcepts.length).map((codableConcept, index) =>
                        <Label as='a' onClick={this.selectConcept.bind(this, codableConcept)} color={this.getColor(codableConcept)}>{t(codableConcept.text)}</Label>
                    )}
                </Label.Group>;
            }
        } else {
            return <Loader active inline='left' />;
        }

    }

    handleQueryChange = (e, { name, value }) => { this.setState({ [name]: value }) }

    getSocialNeeds = (givenName) => {
        const { t } = this.props;
        const { codableConceptQuery } = this.state
        return <Fragment>
            <Header as='h2' size='small' textAlign='right'>
                <Icon circular name='handshake' color='blue' inverted size='mini' />
                <Header.Content>{t("Needs Based Referral S1", { givenName: givenName })}</Header.Content>
            </Header>
            <Segment basic>
                <Input size='small' name='codableConceptQuery' value={codableConceptQuery} onChange={this.handleQueryChange} icon='search' placeholder={t("Search Need")} />
                <Divider hidden />
                {this.showCodableConcepts()}
            </Segment>
        </Fragment>;
    }

    getServices = () => {
        const { t } = this.props;
        let PANES = [
            {
                menuItem: (
                    <Menu.Item key='referrals'><Icon size='large' name='mail forward' color='blue' />
                        {t("Direct Referrals")}
                    </Menu.Item>
                ), render: () => <Tab.Pane attached={true}>{this.getReferrals()} </Tab.Pane>
            },
            {
                menuItem: (
                    <Menu.Item key='leaflets'><Icon size='large' name='handshake ' color='blue' />
                        {t("Other Services")}
                    </Menu.Item>
                ), render: () => <Tab.Pane attached={true}> {this.getLeaflets()} </Tab.Pane>
            }
        ]
        if (this.state.showServices) {
            if (this.state.services.length > 0) {
                return <Segment basic><Tab panes={PANES} /></Segment>;
            } else {
                return <Message>{t("No Offer Found")}</Message>
            }
        }
    }

    showServices = () => {
        this.setState({
            showServices: true
        });
    }

    getHealthcareServices = (givenName) => {
        const { t } = this.props;
        const { selectedCodableConcepts } = this.state;
        if (selectedCodableConcepts.length > 0) {
            return <Fragment>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Header as='h2' size='small'>
                                <Icon circular name='building outline' color='blue' inverted size='mini' />
                                <Header.Content>{t("Needs Based Referral S2", { givenName: givenName })}</Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Button color='blue' size='small' onClick={this.showServices}>{t("Show Offer")}</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.getServices()}
            </Fragment>
        }
    }

    includesDirectReferral = () => {
        for (var i = 0; i < this.state.selectedServices.length; i++) {
            let selectedService = this.state.selectedServices[i];
            if (selectedService.referralMethod === 'email') {
                return true;
            }
        }
        return false;
    }

    getSocialContext = () => {
        const { t } = this.props;
        const { socialContextAnswer } = this.state;
        if (this.state.selectedServices.length > 0 && this.includesDirectReferral()) {
            return <Fragment>
                <Grid columns={2}>
                    <Grid.Column width={6}>
                        <Header as='h2' size='small'>
                            <Icon circular name='edit' color='blue' inverted size='mini' />
                            <Header.Content>{t("Direct Referral S3")}</Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.TextArea
                                rows={5}
                                placeholder={t("Direct Referral S3 Placeholder")}
                                name='socialContext'
                                value={socialContextAnswer}
                                onChange={this.handleSocialContextChange} />
                        </Form>
                    </Grid.Column>
                </Grid></Fragment>
        }
    }

    getConsentCheckbox = () => {
        const { t } = this.props;
        if (this.state.selectedServices.length > 0) {
            if (this.includesDirectReferral()) {
                return <Segment basic textAlign='right'>
                    <Checkbox label={t("Direct Referral Consent")} onChange={this.registerConsent} />
                </Segment>;
            } else {
                return <Segment basic textAlign='right'>
                    <Checkbox label={t("Email Consent")} onChange={this.registerConsent} />
                </Segment>;
            }
        }
    }

    getReferralButton = () => {
        const { t } = this.props;
        if (this.state.consentGiven) {
            return <Segment basic><Button onClick={this.createReferral} icon labelPosition='right' color='blue' floated='right'>
                {t("Refer")}
                <Icon name='right arrow' />
            </Button></Segment>
        } else {
            return <Segment basic><Button disabled icon labelPosition='right' color='blue' floated='right'>
                {t("Refer")}
                <Icon name='right arrow' />
            </Button></Segment>
        }
    }

    getServiceOptions = () => {
        let options = [];
        for (var i = 0; i < this.state.services.length; i++) {
            let service = this.state.services[i];
            let option = { key: i, text: service.name, value: service };
            options.push(option);
        }
        return options;
    }

    componentDidMount() {
        if (!this.state.patientName) {
            this.setState({
                patientName: this.props.location.state.patientName,
                patientGivenName: this.props.location.state.givenName,
                postalCode: this.props.location.state.postalCode
            });
        }
        let currentComponent = this;
        let locationPromise = this.retrieveLocation();
        if (locationPromise) {
            locationPromise.then(function () {
                currentComponent.loadCodableConcepts();
                currentComponent.loadCodableConceptMappings();
            })
        }
    }

    getBody = (givenName) => {
        const { t } = this.props;
        if (this.state.referralSuccessful) {
            return <Segment basic>
                <Header><Icon name='check' color='green' />{t("Direct Referral Success")}</Header>
                <Divider hidden />
                <Button color='blue' as={Link} to={{
                    pathname: '/patient', state: {
                        activeUser: this.state.activeUser,
                        patientId: this.props.location.state.patientId
                    }
                }}>{t("Direct Referral Overview")}</Button></Segment>;
        } else {
            return <Segment basic>
                {this.getSocialNeeds(givenName)}
                {this.getHealthcareServices(givenName)}
                {this.getSocialContext()}
                <Segment basic>
                    {this.getConsentCheckbox()}
                    {this.getReferralButton()}
                </Segment>
            </Segment>
        }
    }

    render() {
        const { t } = this.props;
        return <Grid columns={3}>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
                <Segment>
                    <Header>{t("Needs Known Of", { givenName: this.state.patientName })}</Header>
                    <Divider />
                    {this.getBody(this.state.patientGivenName)}
                </Segment>
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
        </Grid>;
    }
}

export default withTranslation()(withRouter(NeedsBasedReferral));
