import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Grid, Header, Form, Icon, Container, Divider, Button, Label } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeaderPatch, authHeader } from '../helpers/auth-header.js';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

class Profile extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            identifier: '',
            familyName: '',
            givenName: '',
            phone: '',
            email: '',
            gender: '',
            birthdate: '',
            organizationName: '',
            organizationType: '',
            organizationPhone: '',
            organizationEmail: '',
            organizationLogo: '',
            organizationComment: '',
            patientCreationError: false,
            patientCreated: false,
            errorMsg: '',
            readOnlyMode: true
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    retrieveProfile() {
        return fetch(this.config.PRACTITIONERS_URL + "/" + this.state.activeUser.practitioner.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(practitioner => {
                this.setState({
                    identifier: practitioner.identifier,
                    familyName: practitioner.familyName,
                    givenName: practitioner.givenName,
                    phone: practitioner.phone,
                    email: practitioner.email,
                    gender: practitioner.gender,
                    birthdate: moment(practitioner.birthdate).format('YYYY-MM-DD')
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                let addressString = address.lines + " " + address.postalCode + " " + address.city;
                this.setState({
                    organizationAddress: addressString
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.activeUser.organization.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.setState({
                    organizationName: organization.name,
                    organizationPhone: organization.phone,
                    organizationEmail: organization.email
                });
                this.retrieveAddress(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    updateProfile = () => {
        return fetch(this.config.PRACTITIONERS_URL + "/" + this.state.activeUser.practitioner.uuid, {
            method: 'PUT',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                name: this.state.givenName + " " + this.state.familyName,
                identifier: this.state.identifier,
                familyName: this.state.familyName,
                givenName: this.state.givenName,
                phone: this.state.phone,
                email: this.state.email,
                gender: this.state.gender,
                birthdate: this.state.birthdate
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    enableEditMode = () => {
        this.setState({ readOnlyMode: false });
    }

    getEditButton = () => {
        const { t } = this.props;
        if (this.state.readOnlyMode) {
            return <Form.Button onClick={this.enableEditMode} icon labelPosition='right' color='blue'>
                {t("Edit")}<Icon name='edit' />
            </Form.Button>;
        } else {
            return <Form.Button onClick={this.updateProfile} icon labelPosition='right' color='green'>
                {t("Save")}<Icon name='save' />
            </Form.Button>;
        }
    }

    componentDidMount() {
        this.retrieveProfile();
        this.retrieveOrganization();
    }

    getAdminButton = () => {
        const { t } = this.props;
        if (this.state.activeUser.authorizationLevel === 'ADMIN' ||
            this.state.activeUser.authorizationLevel === 'ELZADMIN') {
            return <Button as={Link} to='/organisation' icon labelPosition='right' color='blue' size='small'>
                {t("View")}
                <Icon name='eye' />
            </Button>;
        } else return;
    }

    getDutchColor = () => {
        if (i18n.language.startsWith("nl")) {
            return 'blue';
        } else return 'gray';
    }

    getFrenchColor = () => {
        if (i18n.language.startsWith("fr")) {
            return 'blue';
        } else return 'gray';
    }

    setFrench = () => {
      i18n.changeLanguage('fr');
    }
  
    setDutch = () => {
      i18n.changeLanguage('nl');
    }
  
    changeLanguage = () => {
      if (i18n.language.startsWith("nl")) {
        this.setFrench();
      } else if (i18n.language.startsWith("fr")) {
        this.setDutch();
      }
    }

    render() {
        const { t } = this.props;
        return <Grid columns={16} padded='horizontally' stackable>
            <Grid.Column width={6}>
                <Segment textAlign='center'>
                    <Header textAlign='center'>{t("Your Organisation")}</Header>
                    <Divider />
                    <Container fluid>
                        <Header size='small' textAlign='center'>{this.state.organizationName}</Header>
                        <p><Icon name='phone' color='blue' />{this.state.organizationPhone}</p>
                        <p><Icon name='mail' color='blue' />{this.state.organizationEmail}</p>
                        <p><Icon name='map marker' color='blue' />{this.state.organizationAddress}</p>
                        {this.getAdminButton()}
                    </Container>
                </Segment>
                <Segment textAlign='center'>
                    <Header textAlign='center'>{t("Language")}</Header>
                    <Divider />
                    <Container fluid>
                        <Label as={Link} onClick={this.changeLanguage} basic size='large' color={this.getDutchColor()}>Nederlands</Label>
                        <Label as={Link} onClick={this.changeLanguage} basic size='large' color={this.getFrenchColor()}>Français</Label>
                    </Container>
                </Segment>
            </Grid.Column>
            <Grid.Column width={10}>
                <Segment>
                    <Header size='large' textAlign='center'>{this.state.givenName} {this.state.familyName}</Header>
                    <Divider />
                    <Segment basic>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    fluid
                                    readOnly={this.state.readOnlyMode}
                                    label={t("Given Name")}
                                    placeholder={t("Given Name")}
                                    name='givenName'
                                    value={this.state.givenName}
                                    required
                                    onChange={this.handleChange} />
                                <Form.Input
                                    fluid
                                    readOnly={this.state.readOnlyMode}
                                    label={t("Family Name")}
                                    placeholder={t("Family Name")}
                                    name='familyName'
                                    value={this.state.familyName}
                                    required
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    fluid
                                    type="date"
                                    readOnly={this.state.readOnlyMode}
                                    label={t("Date of Birth")}
                                    placeholder={t("Date of Birth")}
                                    name='birthdate'
                                    value={this.state.birthdate}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    fluid
                                    readOnly={this.state.readOnlyMode}
                                    label={t("RIZIV Number")}
                                    placeholder={t("RIZIV Number")}
                                    name='identifier'
                                    value={this.state.identifier}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    fluid
                                    readOnly={this.state.readOnlyMode}
                                    label={t("Telephone Number")}
                                    placeholder={t("Telephone Number")}
                                    name='phone'
                                    required
                                    value={this.state.phone}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    fluid
                                    readOnly={this.state.readOnlyMode}
                                    label={t("Email")}
                                    placeholder={t("Email")}
                                    name='email'
                                    required
                                    value={this.state.email}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            {this.getEditButton()}
                        </Form>
                    </Segment>
                </Segment>
            </Grid.Column>
        </Grid>;
    }
}

export default withTranslation()(Profile);
