import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class ChoiceAnswer extends Component {

    state = {
        answeredQuestion: '',
        value: null
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.question !== prevProps.question) {
            this.setState({ value: null });
        }
    }

    handleChange = (e, { value }) => {
        this.setState({ value });
    }

    answerQuestion = (answer) => {
        this.props.answer(answer);
    }

    getChecked = (answer) => {
        if (this.state.value === answer.uuid) {
            return true
        } else if (!this.state.value && this.props.responses) {
            for (var i = 0; i < this.props.responses.length; i++) {
                let response = this.props.responses[i];
                if (response.chosenAnswer && response.chosenAnswer.uuid === answer.uuid) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const { t } = this.props;
        if (this.props.registrationMode) {
            return <Form.Group grouped>
                {this.props.answers.slice(0, this.props.answers.length).map((answer, index) =>
                    <Form.Field key={index}>
                        <Form.Radio onClick={this.answerQuestion.bind(this, answer)}
                            onChange={this.handleChange}
                            label={t(answer.text)}
                            value={answer.uuid}
                            name={this.props.question}
                            checked={this.getChecked(answer)}
                        />
                    </Form.Field>
                )}
            </Form.Group>
        } else {
            return <Form.Group grouped>
                {this.props.answers.slice(0, this.props.answers.length).map((answer, index) =>
                    <Form.Field key={index}>
                        <Form.Radio
                            label={t(answer.text)}
                            value={answer.uuid}
                            name={this.props.question}
                            checked={this.getChecked(answer)}
                            readOnly={true}
                        />
                    </Form.Field>
                )}
            </Form.Group>
        }
    }

}

export default withTranslation() (ChoiceAnswer);
