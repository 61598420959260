import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, Grid, Image, Segment, Modal, Button, Icon } from 'semantic-ui-react';
import queryString from 'query-string';
import Configuration from '../config/Configuration.js';
import logo from '../images/logo-colour.png';
import logoblue from '../images/logo-blue.png';
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

class SignupForm extends Component {

  state = {
    passwordconfirm: '',
    password: '',
    invalidPasswords: false,
    code: '',
    uuid: '',
    invalidUrl: false,
    showModalToLogin: false,
    passwordPatchFailed: false,
    registrationButtonEnabled: false
  }

  constructor() {
    super();
    this.config = new Configuration();
  }

  handleError = (error) => {
    console.log(error.message);
  }

  patchPassword = () => {
    return fetch(this.config.PRACTITIONERROLES_URL + "/" + this.state.uuid + "/account", {
      method: 'PATCH',
      headers: {
        'Accept': 'application/problem+json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: this.state.code,
        password: this.state.password,
        uuid: this.state.uuid,
      })
    }).then(response => {
      if (!response.ok) {
        this.setState({ passwordPatchFailed: true })
      } else {
        this.setState({ showModalToLogin: true })
        return response.json();
      }
    }).catch(error => {
      this.handleError(error);
    });
  }

  getCodeAndUuid = () => {
    const values = queryString.parse(this.props.location.search);
    if (values.code && values.uuid) {
      this.setState({ code: values.code, uuid: values.uuid });
    } else {
      this.setState({ invalidUrl: true });
    };
  }

  handleChange = ((e, { name, value }) => {
    this.setState({ [name]: value }, () => {
      if (this.state.password && this.state.passwordconfirm) {
        if (this.state.password === this.state.passwordconfirm) {
          if (this.state.password.length > 9) {
            this.setState({ invalidPasswords: false });
          }
        } else {
          this.setState({ invalidPasswords: true });
        }
      }
    })
  })

  handleSubmit = () => {
    const { code, uuid, password, passwordconfirm, invalidPasswords } = this.state;
    if (code && uuid && password && passwordconfirm) {
      if (!invalidPasswords) {
        this.patchPassword();
      }
    }
  }

  getFormInput = () => {
    const { t } = this.props;
    const { password } = this.state;
    if (this.state.invalidPasswords) {
      return <Form.Input
        fluid
        icon='lock'
        iconPosition='left'
        placeholder={t("Password")}
        name='password'
        type='password'
        value={password}
        onChange={this.handleChange}
        error={{ content: t("Password Guide"), pointing: 'below' }} />;
    } else {
      return <Form.Input
        fluid
        icon='lock'
        iconPosition='left'
        placeholder={t("Password")}
        name='password'
        type='password'
        value={password}
        onChange={this.handleChange} />;
    }
  }

  handleCloseModal = () => {
    this.setState({ showModalToLogin: false });
    this.props.history.push("/login");
  }

  componentDidMount() {
    this.getCodeAndUuid();
  }

  enableRegistrationButton = () => {
    this.setState({ registrationButtonEnabled: !this.state.registrationButtonEnabled });
  }

  getTermsOfUse = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/conditions-dutilisation/';
    } else {
      return 'https://www.zipster.care/gebruikersvoorwaarden/';
    }
  }

  getPrivacyPolicy = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/politique-de-confidentialite/';
    } else {
      return 'https://www.zipster.care/privacybeleid/';
    }
  }

  getLanguage = () => {
    if (i18n.language.startsWith("fr")) {
      return "Nederlands";
    } else {
      return 'Français';
    }
  }

  setFrench = () => {
    i18n.changeLanguage('fr');
  }

  setDutch = () => {
    i18n.changeLanguage('nl');
  }

  changeLanguage = () => {
    if (i18n.language.startsWith("nl")) {
      this.setFrench();
    } else if (i18n.language.startsWith("fr")) {
      this.setDutch();
    }
  }

  render() {
    const { t } = this.props;
    const { passwordconfirm } = this.state;
    if (this.state.invalidUrl) {
      this.props.history.push("/login");
      return null;
    } else {
      return <Fragment>
        <Modal
          open={this.state.showModalToLogin}
          onClose={this.handleCloseModal}
          dimmer="inverted"
          size='small'>
          <Modal.Header>{t("Password Changed")}</Modal.Header>
          <Modal.Content image>
            <Image wrapped size='small' src={logoblue} />
            <Modal.Description>
              <p>{t("Password Changed S1")}</p>
              <p>{t("Password Changed S2")}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={this.handleCloseModal}>
              <Icon name='checkmark' /> {t("To Login")}
            </Button>
          </Modal.Actions>
        </Modal>
        <Fragment>
          <style>{'body { background-color: #385773; }'}</style><Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
              <Image size='medium' src={logo} centered />
              <Form size='large' onSubmit={this.handleSubmit} style={{ marginTop: "2%" }}>
                <Segment stacked>
                  {this.getFormInput()}
                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder={t("Confirm Password")}
                    name='passwordconfirm'
                    type='password'
                    value={passwordconfirm}
                    onChange={this.handleChange}
                    error={this.state.invalidPasswords}
                  />
                  <Form.Checkbox onChange={this.enableRegistrationButton} label={<label>{t("Consent Signup")}<b><a href={this.getTermsOfUse()} target="_blank" rel="noopener noreferrer">{t("Terms Of Use")}</a></b> {t("And The Zipster")} <b><a href={this.getPrivacyPolicy()} target="_blank" rel="noopener noreferrer">{t("Privacy Policy")}</a></b></label>} />
                  <Form.Button color='orange' fluid size='large' disabled={!this.state.registrationButtonEnabled}>{t("Register Me")}</Form.Button>
                  <Link onClick={this.changeLanguage}>{this.getLanguage()}</Link>
                </Segment>
              </Form>
              <Segment attached='top'>
                <p>{t("Signup Welcome")}</p>
                <p>{t("Signup Guidance")}</p>
              </Segment>
            </Grid.Column>
          </Grid >
        </Fragment>
      </Fragment>
    }
  }

}

export default withTranslation()(withRouter(SignupForm));