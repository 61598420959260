class CredentialService {

    startSession(username, password) {
        const encodedString = new Buffer(username + ':' + password).toString('base64');
        localStorage.setItem('userCredentials', encodedString);
    }

    hasActiveSession() {
        if (localStorage.getItem('userCredentials') === null) {
            return false;
        } else
            return true;
    }

    endSession() {
        localStorage.removeItem('userCredentials');
        localStorage.removeItem('activeUser');
    }

    storeActiveUser(activeUser) {
        localStorage.setItem('activeUser', JSON.stringify(activeUser));
    }

    getActiveUser() {
        let activeUserItem = localStorage.getItem('activeUser');
        if (activeUserItem) {
            let activeUser = JSON.parse(activeUserItem);
            return activeUser;
        } else {
            return null;
        }
    }

}

export default CredentialService;