import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Grid, Header, Button, Icon, Divider } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class Contact extends Component {

    render() {
        const { t } = this.props;
        return <Fragment>
            <Segment attached='top'>
                <Header color='blue' size="large" textAlign='center'>{t("Contact Header")}</Header>
                <Divider />
                <Grid columns={16} textAlign='center' >
                    <Grid.Column width={8}>
                        <Icon size='big' name='mail' color='blue' circular inverted />
                        <Header><a href="mailto:hallo@zipster.care">hallo@zipster.care</a> </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Icon size='big' name='phone' color='blue' circular inverted />
                        <Header color='blue'>0476/33.25.47</Header>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment style={{ fontSize: '16px' }} attached>
                <Grid columns={16}>
                    <Grid.Column width={4} textAlign='center' verticalAlign='middle'>
                        <Icon size='massive' name='comments' color='blue' />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <p>{t("Contact Context S1")}</p>
                        <p>{t("Contact Context S2")}</p>
                        <p>{t("Contact Context S3")}</p>
                        <p>{t("Contact Context S4")}</p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached='bottom'>
                <Segment basic textAlign='center'>
                    <p><Icon name='info' color='orange' inverted circular/>{t("Contact FAQ")}</p>
                    <Button
                        content={t("Contact Button")}
                        as={Link}
                        to='/faq'
                        color='blue'
                        compact
                    />
                </Segment>
            </Segment>
        </Fragment >
    }
}

export default withTranslation()(Contact);
