import React, { Component } from 'react';
import { Segment, Header, Loader, Image, Icon, Dropdown, Divider, Grid, Statistic } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { authHeader, authHeaderSet } from '../helpers/auth-header.js';
import moment from 'moment';
import symbol from '../images/zipster-symbol-orange.png';

const options = [
    {
        key: 'afgelopen week',
        text: 'afgelopen week',
        value: 'afgelopen week',
        content: 'afgelopen week',
    },
    {
        key: 'afgelopen maand',
        text: 'afgelopen maand',
        value: 'afgelopen maand',
        content: 'afgelopen maand',
    },
    {
        key: 'afgelopen kwartaal',
        text: 'afgelopen kwartaal',
        value: 'afgelopen kwartaal',
        content: 'afgelopen kwartaal',
    },
    {
        key: 'afgelopen maand',
        text: 'afgelopen maand',
        value: 'afgelopen maand',
        content: 'afgelopen maand',
    },
]

class ZoneDashboard extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            startDate: new Date(new Date() - 365 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            totalNrOfReferrals: null,
            totalNrOfNeeds: null,
            totalNrOfReferrers: null,
            totalNrOfQuestionaires: null,
            totalNrOfServices: null,
            healthcareServices: null,
            organizations: null,
            codableConcepts: null,
            existingCodableConcepts: null,
            referralDays: null,
            requestTypes: null,
            elzName: null,
            referralsByAge: [
                {
                    "name": "0-18"
                },
                {
                    "name": "18-25"
                },
                {
                    "name": "26-40"
                },
                {
                    "name": "41-50"
                },
                {
                    "name": "51-65"
                },
                {
                    "name": "66-80"
                },
                {
                    "name": "80+"
                }
            ]
        }
    }



    retrieveELZForLocation = (locationId) => {
        return fetch(this.config.LOCATIONS_URL + "?childId=" + locationId + "&level=Eerstelijnszone", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.setState({
                    elzName: location.name
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveLocationForPostalCode = (postalCode) => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.retrieveELZForLocation(location.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.retrieveLocationForPostalCode(address.postalCode);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.activeUser.organization.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.retrieveAddress(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ existingCodableConcepts: codableTexts });
                return concepts;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveGeneralQuestionnaireAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        return fetch(this.config.QUESTIONNAIRES_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {

                this.setState({ totalNrOfQuestionaires: requests[0].count })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveGeneralRequestAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                this.setState({ totalNrOfReferrals: requests[0].count })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveRequestsByDay = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByDay=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                let sunday = 0;
                let monday = 0;
                let tuesday = 0;
                let wednesday = 0;
                let thursday = 0;
                let friday = 0;
                let saturday = 0;
                for (var i = 0; i < requests.length; i++) {
                    let day = new Date(requests[i].day).getDay();
                    let count = requests[i].count;
                    if (day === 0) {
                        sunday += count;
                    } else if (day === 1) {
                        monday += count;
                    } else if (day === 2) {
                        tuesday += count;
                    } else if (day === 3) {
                        wednesday += count;
                    } else if (day === 4) {
                        thursday += count;
                    } else if (day === 5) {
                        friday += count;
                    } else if (day === 6) {
                        saturday += count;
                    }
                }
                let overview = [
                    {
                        "name": "Maandag",
                        "Doorverwijzingen": monday
                    },
                    {
                        "name": "Dinsdag",
                        "Doorverwijzingen": tuesday
                    },
                    {
                        "name": "Woensdag",
                        "Doorverwijzingen": wednesday
                    },
                    {
                        "name": "Donderdag",
                        "Doorverwijzingen": thursday
                    },
                    {
                        "name": "Vrijdag",
                        "Doorverwijzingen": friday
                    },
                    {
                        "name": "Zaterdag",
                        "Doorverwijzingen": saturday
                    },
                    {
                        "name": "Zondag",
                        "Doorverwijzingen": sunday
                    }
                ];
                this.setState({ referralDays: overview })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveRequestsByAge = (patientBirthDateBegin, patientBirthDateEnd) => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&patientBirthDateBegin=" + moment(patientBirthDateBegin).toJSON();
        query += "&patientBirthDateEnd=" + moment(patientBirthDateEnd).toJSON();
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                if (requests[0]) {
                    return requests[0].count;
                } else {
                    return 0;
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAgeCategories = () => {
        let zero = new Date();
        let eightteen = new Date();
        eightteen.setFullYear(eightteen.getFullYear() - 18);
        let twentyfive = new Date();
        twentyfive.setFullYear(twentyfive.getFullYear() - 25);
        let fourty = new Date();
        fourty.setFullYear(fourty.getFullYear() - 40);
        let fifty = new Date();
        fifty.setFullYear(fifty.getFullYear() - 50);
        let sixtyFive = new Date();
        sixtyFive.setFullYear(sixtyFive.getFullYear() - 65);
        let eighty = new Date();
        eighty.setFullYear(eighty.getFullYear() - 80);
        let infinity = new Date();
        infinity.setFullYear(infinity.getFullYear() - 150);
        let currentComponent = this;
        let rangeOne = this.retrieveRequestsByAge(eightteen, zero);
        if (rangeOne) {
            rangeOne.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[0].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
        let rangeTwo = this.retrieveRequestsByAge(twentyfive, eightteen);
        if (rangeTwo) {
            rangeTwo.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[1].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
        let rangeThree = this.retrieveRequestsByAge(fourty, twentyfive);
        if (rangeThree) {
            rangeThree.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[2].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
        let rangeFour = this.retrieveRequestsByAge(fifty, fourty);
        if (rangeFour) {
            rangeFour.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[3].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
        let rangeFive = this.retrieveRequestsByAge(sixtyFive, fifty);
        if (rangeFive) {
            rangeFive.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[4].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
        let rangeSix = this.retrieveRequestsByAge(eighty, sixtyFive);
        if (rangeSix) {
            rangeSix.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[5].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
        let rangeSeven = this.retrieveRequestsByAge(infinity, eighty);
        if (rangeSeven) {
            rangeSeven.then(function (count) {
                let referrals = [...currentComponent.state.referralsByAge];
                referrals[6].Doorverwijzingen = count;
                currentComponent.setState({ referralsByAge: referrals });
            });
        }
    }

    retrieveNeedsRequestAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByCodableConcept=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                let concepts = [];
                for (var i = 0; i < requests.length; i++) {
                    for (var j = 0; j < this.state.existingCodableConcepts.length; j++) {
                        let codableConcept = this.state.existingCodableConcepts[j];
                        if (codableConcept.uuid === requests[i].codableConcept.uuid) {
                            let name = codableConcept.text;
                            let count = requests[i].count;
                            concepts.push({ "name": name, "Doorverwijzingen": count });
                            break;
                        }
                    }
                    if (i > 8) {
                        break;
                    }
                }
                this.setState({
                    totalNrOfNeeds: requests.length,
                    codableConcepts: concepts
                })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveReferrersAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByPractitionerRole=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                this.setState({ totalNrOfReferrers: requests.length })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByHealthCareService=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                let services = [];
                for (var i = 0; i < requests.length; i++) {
                    let name = requests[i].healthcareService.name;
                    let count = requests[i].count;
                    services.push({ "name": name, "Doorverwijzingen": count });
                    if (i > 8) {
                        break;
                    }
                }
                this.setState({
                    totalNrOfServices: requests.length,
                    healthcareServices: services
                })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganizationAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByOrganization=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                let organizations = [];
                for (var i = 0; i < requests.length; i++) {
                    let name = requests[i].organization.name;
                    let count = requests[i].count;
                    organizations.push({ "name": name, "Doorverwijzingen": count });
                    if (i > 8) {
                        break;
                    }
                }
                this.setState({
                    totalNrOfOrganizations: requests.length,
                    organizations: organizations
                })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveTypeAnalytics = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByType=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                let types = [];
                for (var i = 0; i < requests.length; i++) {
                    let name = requests[i].type;
                    let count = requests[i].count;
                    if (name === "consult") {
                        name = "Vragenlijst";
                    } else if (name === "consult-questionnaire") {
                        name = "Vragenlijst";
                    } else if (name === "consult-direct-referral") {
                        name = "Directe Doorverwijzing";
                    } else if (name === "consult-needs-based") {
                        name = "Vanuit De Noden";
                    }
                    types.push({ "name": name, "Doorverwijzingen": count });
                }
                this.setState({
                    requestTypes: types
                })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    secondsToDhms = (seconds) => {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24) / 4);
        var h = Math.floor(seconds % (3600 * 24) / 3600);

        var dDisplay = d > 0 ? d + (d === 1 ? "D " : "D ") : "";
        var hDisplay = h > 0 ? h + (h === 1 ? "U " : "U ") : "";
        return dDisplay + hDisplay;
    }

    retrieveResponseTime = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&includeAverageResponseTime=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                let totalCount = 0;
                let responseCount = 0;
                let idleCount = 0;
                for (var i = 0; i < requests.length; i++) {
                    if (requests[i].responseTime) {
                        this.setState({ totalResponseTime: this.secondsToDhms(requests[i].responseTime) });
                        responseCount = requests[i].count;
                    } else {
                        idleCount = requests[i].count;
                    }
                }
                totalCount = responseCount + idleCount;
                this.setState({ responsesHandled: Math.round(((responseCount / totalCount) * 100)) });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadAllData = () => {
        let promise = this.loadCodableConcepts();
        this.retrieveOrganization();
        let currentComponent = this;
        if (promise) {
            promise.then(function () {
                currentComponent.retrieveGeneralRequestAnalytics();
                currentComponent.retrieveNeedsRequestAnalytics();
                currentComponent.retrieveReferrersAnalytics();
                currentComponent.retrieveGeneralQuestionnaireAnalytics();
                currentComponent.retrieveServiceAnalytics();
                currentComponent.retrieveResponseTime();
                currentComponent.retrieveRequestsByDay();
                currentComponent.retrieveAgeCategories();
                currentComponent.retrieveOrganizationAnalytics();
                currentComponent.retrieveTypeAnalytics();
            })
        }
    }

    componentDidMount = () => {
        this.loadAllData();
    }

    handleError = (error) => {
        console.log(error.message);
    }

    displayNumber = (number) => {
        if (number) {
            return number;
        } else {
            return <Loader active size='large' />;
        }
    }

    handleTimeFrameClick = (e, { value }) => {
        if (value === 'afgelopen week') {
            let end = new Date();
            let start = new Date(end - 7 * 24 * 60 * 60 * 1000);
            this.setState({
                startDate: start,
                endDate: new Date()
            }, () => { this.loadAllData() });
        } else if (value === 'afgelopen maand') {
            let end = new Date();
            let start = end.setMonth(end.getMonth() - 1);
            this.setState({
                startDate: start,
                endDate: new Date()
            }, () => { this.loadAllData() });
        } else if (value === 'afgelopen kwartaal') {
            let end = new Date();
            let start = end.setMonth(end.getMonth() - 3);
            this.setState({
                startDate: start,
                endDate: new Date()
            }, () => { this.loadAllData() });
        } else if (value === 'afgelopen jaar') {
            let end = new Date();
            let start = end.setFullYear(end.getFullYear() - 1);
            this.setState({
                startDate: start,
                endDate: new Date()
            }, () => { this.loadAllData() });
        }
    }

    renderCodableConcepts = () => {
        if (this.state.codableConcepts) {
            return <BarChart
                width={1000}
                height={300}
                data={this.state.codableConcepts}
                layout='vertical' barCategoryGap={1}
                margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
            ><XAxis type="number" />
                <YAxis type="category" width={400} padding={{ left: 20 }} dataKey="name" />
                <CartesianGrid strokeDasharray="2 2" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Doorverwijzingen" fill="#385773" />
            </BarChart>;
        }
    }

    renderHealthcareServices = () => {
        if (this.state.healthcareServices) {
            return <BarChart
                width={800}
                height={300}
                data={this.state.healthcareServices}
                layout='vertical' barCategoryGap={1}
                margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
            ><XAxis type="number" />
                <YAxis type="category" width={200} padding={{ left: 20 }} dataKey="name" />
                <CartesianGrid strokeDasharray="2 2" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Doorverwijzingen" fill="#385773" />
            </BarChart>
        }
    }

    renderRequestTypes = () => {
        let COLORS = ['#385773', '#ACC3D7', '#0D151C'];
        if (this.state.requestTypes) {
            return <PieChart width={500} height={300}>
                <Pie
                    dataKey="Doorverwijzingen"
                    data={this.state.requestTypes}
                    cx="50%"
                    cy="50%"
                    innerRadius={40}
                    outerRadius={80}
                    label
                >
                    {this.state.requestTypes.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        }
    }

    renderOrganizations = () => {
        if (this.state.organizations) {
            return <BarChart
                width={500}
                height={300}
                data={this.state.organizations}
                layout='vertical' barCategoryGap={1}
                margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
            ><XAxis type="number" />
                <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" />
                <CartesianGrid strokeDasharray="2 2" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Doorverwijzingen" fill="#385773" />
            </BarChart>
        }
    }

    render() {
        const { chosenTimeFrame } = this.state;
        return <Segment>
            <Header size='huge' textAlign='center'>
                <Image size='small' circular src={symbol} />Dashboard {this.state.elzName}</Header>
            <Divider />
            <Grid columns={16} stretched textAlign='center'>
                <Grid.Column width={10}>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Doorverwijzingen</Header>
                    <Segment textAlign='center' attached>
                        <Grid columns={2} stretched>
                            <Grid.Column>
                                <Segment basic textAlign='center'>
                                    <Statistic color='blue' size='tiny'>
                                        <Statistic.Value>{this.displayNumber(this.state.totalNrOfQuestionaires)}</Statistic.Value>
                                        <Statistic.Label>Vragenlijsten Doorlopen</Statistic.Label>
                                    </Statistic>
                                </Segment>
                                <Segment basic textAlign='center'>
                                    <Statistic color='blue' size='tiny'>
                                        <Statistic.Value>{this.displayNumber(this.state.totalNrOfReferrers)}</Statistic.Value>
                                        <Statistic.Label>Verwijzers</Statistic.Label>
                                    </Statistic>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment basic textAlign='center'>
                                    <Statistic color='blue' size='tiny'>
                                        <Statistic.Value>{this.displayNumber(this.state.totalNrOfReferrals)}</Statistic.Value>
                                        <Statistic.Label>Doorverwijzingen</Statistic.Label>
                                    </Statistic>
                                </Segment>
                                <Segment basic textAlign='center'>
                                    <Statistic color='blue' size='tiny'>
                                        <Statistic.Value>{this.displayNumber(this.state.totalNrOfNeeds)}</Statistic.Value>
                                        <Statistic.Label>Ondersteuningsnoden</Statistic.Label>
                                    </Statistic>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={6} textAlign='center'>
                    <Segment textAlign='center' basic>
                        <Segment basic textAlign='center'><Header as='h3'>
                            <Icon name='calendar' color='blue' />
                            <Header.Content textAlign='center'>
                                Toon overzicht {' '}
                                <Dropdown
                                    inline
                                    header='Pas periode aan'
                                    options={options}
                                    defaultValue={options[3].value}
                                    onChange={this.handleTimeFrameClick}
                                    value={chosenTimeFrame}
                                />
                            </Header.Content>
                        </Header>
                        </Segment>
                    </Segment>
                </Grid.Column>
            </Grid>
            <Grid columns={2}>
                <Grid.Column width={12}>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Diensten</Header>
                    <Segment textAlign='center' attached>
                        {this.renderHealthcareServices()}
                    </Segment>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Ondersteuning</Header>
                    <Segment textAlign='center' attached>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>{this.displayNumber(this.state.totalNrOfServices)}</Statistic.Value>
                                <Statistic.Label>Actieve Diensten</Statistic.Label>
                            </Statistic>
                        </Segment>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>{this.displayNumber(this.state.totalResponseTime)}</Statistic.Value>
                                <Statistic.Label>Gemiddelde Responstijd</Statistic.Label>
                            </Statistic>
                        </Segment>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>82 %</Statistic.Value>
                                <Statistic.Label>Behandeld</Statistic.Label>
                            </Statistic>
                        </Segment>
                    </Segment>
                </Grid.Column>
            </Grid>
            <Grid>
                <Grid.Column>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Ondersteuningsnoden</Header>
                    <Segment textAlign='center' attached>
                        {this.renderCodableConcepts()}
                    </Segment>
                </Grid.Column>
            </Grid>
            <Grid columns={2}>
                <Grid.Column>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Type Verwijzing</Header>
                    <Segment textAlign='center' attached>
                        {this.renderRequestTypes()}
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Verwijzers</Header>
                    <Segment textAlign='center' attached>
                        {this.renderOrganizations()}
                    </Segment></Grid.Column>
            </Grid>
            <Grid columns={2}>
                <Grid.Column>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Evolutie</Header>
                    <Segment textAlign='center' attached>
                        <BarChart
                            width={600}
                            height={300}
                            data={this.state.referralDays}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        ><XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Bar type="monotone" dataKey="Doorverwijzingen" fill="#385773" />
                        </BarChart>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Leeftijdsgroepen</Header>
                    <Segment textAlign='center' attached>
                        <BarChart
                            width={600}
                            height={300}
                            data={this.state.referralsByAge}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        ><XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="2 2" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Doorverwijzingen" fill="#385773" />
                        </BarChart>
                    </Segment></Grid.Column>
            </Grid>
        </Segment>;
    }

}

export default withRouter(ZoneDashboard);
