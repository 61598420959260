import React, { Component } from 'react';
import { Icon, Step } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class QuestionnaireSteps extends Component {

    getActive = (step) => {
        if (this.props.stage === step) {
            return true;
        }
    }

    getCompleted = (step) => {
        if (this.props.stage > step) {
            return true;
        }
    }

    isSpecificQuestionnaire = () => {
        if (this.props.organizationType === "Apotheek") {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { t } = this.props;
        if (this.isSpecificQuestionnaire()) {
            return <Step.Group vertical size='tiny'>
                <Step active={this.getActive(1)} completed={this.getCompleted(1)}>
                    <Icon name='assistive listening systems' />
                    <Step.Content>
                        <Step.Title>{t("Questionnaire Patient Title")}</Step.Title>
                        <Step.Description>{t("Questionnaire Patient Description")}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(2)} completed={this.getCompleted(2)}>
                    <Icon name='list layout' />
                    <Step.Content>
                        <Step.Title>{t("Suggestions Title")}</Step.Title>
                        <Step.Description>{t("Suggestions Description")}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(3)} completed={this.getCompleted(3)}>
                    <Icon name='thumbs up' />
                    <Step.Content>
                        <Step.Title>{t("Consent Title")}</Step.Title>
                        <Step.Description>{t("Consent Description")}</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>;
        } else {
            return <Step.Group vertical size='tiny'>
                <Step active={false} completed={false}>
                    <Icon name='user circle outline' />
                    <Step.Content>
                        <Step.Title>{this.props.patientName}</Step.Title>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(1)} completed={this.getCompleted(1)}>
                    <Icon name='street view' />
                    <Step.Content>
                        <Step.Title>{t("Profile Title")}</Step.Title>
                        <Step.Description>{t("Profile Description")}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(2)} completed={this.getCompleted(2)}>
                    <Icon name='assistive listening systems' />
                    <Step.Content>
                        <Step.Title>{t("Needs Title")}</Step.Title>
                        <Step.Description>{t("Needs Description Patient")}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(3)} completed={this.getCompleted(3)}>
                    <Icon name='edit' />
                    <Step.Content>
                        <Step.Title>{t("Context Title")}</Step.Title>
                        <Step.Description>{t("Context Description")}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(4)} completed={this.getCompleted(4)}>
                    <Icon name='list layout' />
                    <Step.Content>
                        <Step.Title>{t("Suggestions Title")}</Step.Title>
                        <Step.Description>{t("Suggestions Description")}</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.getActive(5)} completed={this.getCompleted(5)}>
                    <Icon name='thumbs up' />
                    <Step.Content>
                        <Step.Title>{t("Consent Title")}</Step.Title>
                        <Step.Description>{t("Consent Description")}</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>;
        }
    }
}

export default withTranslation() (QuestionnaireSteps);
