import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, Grid, Image, Segment, Header, Divider, Container } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import { withTranslation } from 'react-i18next';
import i18n from "../i18n.js";
import { recaptchaHeader } from '../helpers/auth-header.js';
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../images/logo-colour.png';
import bravio from '../images/zones/bravio.jpg';
import bruzel from '../images/zones/bruzel.jpg';
import elzacentrum from '../images/zones/elza-centrum.jpg';
import elzanoord from '../images/zones/elza-noord.jpg';
import elzaoost from '../images/zones/elza-oost.jpg';
import elzazuid from '../images/zones/elza-zuid.jpg';
import hep from '../images/zones/hep.jpg';
import meka from '../images/zones/mechelen-katelijne.jpg';
import ob from '../images/zones/oostende-bredene.jpg';
import wep from '../images/zones/wep.jpg';

class RegistrationForm extends Component {

  zones = [
    {
      name: 'Eerstelijnszone BraViO',
      image: bravio,
      id: 'b712913f-80fc-431b-96a6-1943074c0a1c'
    },
    {
      name: 'Eerstelijnszone BruZEL',
      image: bruzel,
      id: '46b92418-2f13-4bef-be5b-dbd5b08bc24a'
    },
    {
      name: 'Eerstelijnszone Antwerpen Centrum',
      image: elzacentrum,
      id: '49112378-3950-4a59-a110-d3713ac6eb1b'
    },
    {
      name: 'Eerstelijnszone Noord Antwerpen',
      image: elzanoord,
      id: '73498226-c893-4186-9b05-ae70e2187b18'
    },
    {
      name: 'Eerstelijnszone Antwerpen Oost',
      image: elzaoost,
      id: '68f93ada-c984-4b85-85ef-f6f6b1db226f'
    },
    {
      name: 'Eerstelijnszone Antwerpen Zuid',
      image: elzazuid,
      id: '6c2130fe-db30-4439-b072-c5295950e9b1'
    },
    {
      name: 'Eerstelijnszone Houtland en Polder',
      image: hep,
      id: '1db540c1-442f-40b3-b3a3-a67232480d68'
    },
    {
      name: 'Eerstelijnszone Mechelen-Katelijne',
      image: hep,
      id: '543e82a3-575d-4897-974c-ee5f658c748b'
    },
    {
      name: 'Eerstelijnszone Oostende-Bredene',
      image: ob,
      id: 'fb7f2d7a-f198-47ca-a9dd-d3f955f237ca'
    },
    {
      name: 'Eerstelijnszone Westkust & Polder',
      image: wep,
      id: 'cc50afee-e0b2-4461-8409-5b67d15897b6'
    }
  ]

  constructor() {
    super();
    this.config = new Configuration();
    this.recaptchaRef = React.createRef();
    this.state = {
      registrationButtonEnabled: false,
      nameOrganisation: '',
      organisationAddressLines: '',
      organisationCity: '',
      organisationPostalCode: '',
      adminGivenName: '',
      adminFamilyName: '',
      adminEmail: '',
      websiteOrganisation: '',
      recaptchaValue: null,
      selectedZone: null
    }
  }

  handleError = (error) => {
    console.log(error.message);
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  componentDidMount() {

  }

  register = () => {
    return fetch(this.config.REGISTER_URL, {
      method: 'POST',
      headers: recaptchaHeader(this.state.recaptchaValue),
      body: JSON.stringify({
        organizationName: this.state.nameOrganisation,
        streetAndNumber: this.state.organisationAddressLines,
        city: this.state.organisationCity,
        postalCode: this.state.organisationPostalCode,
        country: 'Belgium',
        elzLocation: this.state.selectedZone,
        website: this.state.websiteOrganisation,
        familyName: this.state.adminFamilyName,
        givenName: this.state.adminGivenName,
        email: this.state.adminEmail,
        isPersonalEmail: false,
        userName: this.state.adminEmail
      })
    }).then(response => {
      if (!response.ok) {
        this.setState({ organizationCreationError: true })
      } else {
        this.setState({
          organizationCreated: true
        });
        return response.json();
      }
    }).catch(error => {
      this.handleError(error);
    });
  }

  enableRegistrationButton = () => {
    this.setState({ registrationButtonEnabled: !this.state.registrationButtonEnabled });
  }

  getTermsOfUse = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/conditions-dutilisation/';
    } else {
      return 'https://www.zipster.care/gebruikersvoorwaarden/';
    }
  }

  getPrivacyPolicy = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/politique-de-confidentialite/';
    } else {
      return 'https://www.zipster.care/privacybeleid/';
    }
  }

  getLanguage = () => {
    if (i18n.language.startsWith("fr")) {
      return "Nederlands";
    } else {
      return 'Français';
    }
  }

  setFrench = () => {
    i18n.changeLanguage('fr');
  }

  setDutch = () => {
    i18n.changeLanguage('nl');
  }

  changeLanguage = () => {
    if (i18n.language.startsWith("nl")) {
      this.setFrench();
    } else if (i18n.language.startsWith("fr")) {
      this.setDutch();
    }
  }

  recaptchaSuccess = (value) => {
    console.log("ReCaptcha filled: " + value);
    this.setState({ recaptchaValue: value });
  }

  recaptchaExpired = (value) => {
    this.setState({ recaptchaValue: null });
  }

  getFormFragment = () => {
    const { t } = this.props;
    if (!this.state.recaptchaValue) {
      return <Segment textAlign='center' basic>
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY}
          ref={this.recaptchaRef}
          onChange={(this.recaptchaSuccess)}
          onExpired={(this.recaptchaExpired)} />
      </Segment>;
    } else {
      return <Fragment>
        <Form.Input
          required
          fluid
          label={t("Name Organisation")}
          placeholder={t("Name Organisation")}
          name='nameOrganisation'
          value={this.state.nameOrganisation}
          onChange={this.handleChange}
        />
        <Form.Input
          required
          fluid
          label={t("Street and house number")}
          placeholder={t("Street and house number")}
          name='organisationAddressLines'
          value={this.state.organisationAddressLines}
          onChange={this.handleChange} />
        <Form.Group widths='equal'>
          <Form.Input
            required
            fluid
            label={t("City")}
            placeholder={t("City")}
            name='organisationCity'
            value={this.state.organisationCity}
            onChange={this.handleChange} />
          <Form.Input
            required
            fluid
            label={t("Postal Code")}
            placeholder={t("Postal code")}
            name='organisationPostalCode'
            value={this.state.organisationPostalCode}
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            required
            fluid
            label={t("Given Name Administrator")}
            placeholder={t("Given Name Administrator")}
            name='adminGivenName'
            value={this.state.adminGivenName}
            onChange={this.handleChange} />
          <Form.Input
            required
            fluid
            label={t("Family Name Administrator")}
            placeholder={t("Family Name Administrator")}
            name='adminFamilyName'
            value={this.state.adminFamilyName}
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            required
            fluid
            label={t("Email Administrator")}
            placeholder={t("Email Administrator")}
            name='adminEmail'
            value={this.state.adminEmail}
            onChange={this.handleChange} />
          <Form.Input
            fluid
            label={t("Website Organisation")}
            placeholder={t("Website Organisation")}
            name='websiteOrganisation'
            value={this.state.websiteOrganisation}
            onChange={this.handleChange} />
        </Form.Group>
        <Form.Checkbox onChange={this.enableRegistrationButton} label={<label>{t("Consent Signup")}<b><a href={this.getTermsOfUse()} target="_blank" rel="noopener noreferrer">{t("Terms Of Use")}</a></b> {t("And The Zipster")} <b><a href={this.getPrivacyPolicy()} target="_blank" rel="noopener noreferrer">{t("Privacy Policy")}</a></b></label>} />
        <Form.Button onClick={this.register} color='orange' fluid size='large' disabled={!this.state.registrationButtonEnabled}>{t("Register Me")}</Form.Button>
      </Fragment>;
    }
  }

  setZone = (zoneId) => {
    console.log(zoneId);
    this.setState({ selectedZone: zoneId });
  }

  selectZone = () => {
    const { t } = this.props;
    return <Fragment>
      <style>{'body { background-color: #385773; }'}</style>
      <Grid textAlign='center' style={{ height: '40vh' }} verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column>
            <Image size='medium' src={logo} centered />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Container>
        <Header as='h2' attached='top' textAlign='center'>{t("Choose Primary Care Area")}</Header>
        <Segment centered raised textAlign='center' attached>
          <Grid columns={5} textAlign='center' verticalAlign='middle'>
            <Grid.Row>
              {this.zones.slice(0, 5).map((zone, index) =>
                <Grid.Column key={index}>
                  <Image as={Link} src={zone.image} onClick={this.setZone.bind(this, zone.id)} />
                </Grid.Column>)}
            </Grid.Row>
            <Grid.Row>
              {this.zones.slice(5, 10).map((zone, index) =>
                <Grid.Column key={index}>
                  <Image as={Link} src={zone.image} onClick={this.setZone.bind(this, zone.id)} />
                </Grid.Column>)}
            </Grid.Row>
          </Grid >
          <Divider hidden />
          <Link onClick={this.changeLanguage}>{this.getLanguage()}</Link>
        </Segment>
      </Container>
    </Fragment >
  }

  showNextSteps = () => {
    const { t } = this.props;
    return <Fragment>
      <style>{'body { background-color: #385773; }'}</style>
      <Grid textAlign='center' style={{ height: '40vh' }} verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column>
            <Image size='medium' src={logo} centered />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Container>
        <Header as='h2' attached='top' textAlign='center'>{t("Registratie Succesvol!")}</Header>
        <Segment centered raised textAlign='center' attached>
        </Segment>
      </Container>
    </Fragment >
  }


  render() {
    if (this.state.organizationCreated) {
      return this.showNextSteps();
    } else if (!this.state.selectedZone) {
      return this.selectZone();
    } else {
      const { t } = this.props;
      return <Fragment>
        <Fragment>
          <style>{'body { background-color: #385773; }'}</style><Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 600 }}>
              <Image size='medium' src={logo} centered />
              <Form size='large' onSubmit={this.handleSubmit} style={{ marginTop: "2%" }}>
                <Segment stacked>
                  <Header>{t("Registration Organisation")}</Header>
                  <Divider />
                  {this.getFormFragment()}
                  <Link onClick={this.changeLanguage}>{this.getLanguage()}</Link>
                </Segment>
              </Form>
              <Segment attached='top'>
                <p>{t("Signup Welcome")}</p>
              </Segment>
            </Grid.Column>
          </Grid >
        </Fragment>
      </Fragment>
    }
  }

}

export default withTranslation()(withRouter(RegistrationForm));