import React, { Component, Fragment } from 'react';
import { Button, Icon, Header, Segment, Divider, Form } from 'semantic-ui-react';
import MultipleChoiceAnswer from '../components/MultipleChoiceAnswer.jsx';
import ChoiceAnswer from '../components/ChoiceAnswer.jsx';
import { withTranslation } from 'react-i18next';

class RegisterSpecificQuestionsForm extends Component {

    state = {
        answeredQuestions: []
    }

    answerMultipleChoiceQuestion = (answers) => {
        // First clean up existing answers from this question
        for (var j = 0; j < answers.length; j++) {
            for (var i = 0; i < this.state.answeredQuestions.length; i++) {
                let answered = this.state.answeredQuestions[i];
                if (answered.questionnaireItem.uuid === answers[j].questionnaireItem.uuid) {
                    const index = this.state.answeredQuestions.indexOf(answered);
                    if (index > -1) {
                        this.state.answeredQuestions.splice(index, 1);
                        i = i - 1;
                    }
                }
            }
        }
        let recordedAnswers = this.state.answeredQuestions;
        recordedAnswers = recordedAnswers.concat(answers)
        this.setState({ answeredQuestions: recordedAnswers });
    }

    answerQuestion = (answer) => {
        for (var i = 0; i < this.state.answeredQuestions.length; i++) {
            let answered = this.state.answeredQuestions[i];
            if (answered.questionnaireItem.uuid === answer.questionnaireItem.uuid) {
                const index = this.state.answeredQuestions.indexOf(answered);
                if (index > -1) {
                    this.state.answeredQuestions.splice(index, 1);
                }
            }
        }
        let answeredQuestions = this.state.answeredQuestions;
        answeredQuestions.push(answer);
        this.setState({ answeredQuestions: answeredQuestions });
    }

    getAnswers = (question) => {
        return question.questionnaireAnswerOptionOutputListModel.questionnaireAnswerOptions;
    }

    handleChange = (e, { name, value }) => {
        let answer = {
            questionnaireItem: { uuid: name },
            text: value
        }
        this.answerQuestion(answer)
    }

    getAnswerInput = (question, answers) => {
        if (question.type === "choice") {
            return <ChoiceAnswer answers={answers}
                question={question.uuid} answer={this.answerQuestion}
                registrationMode={true} />;
        } else if (question.type === "multipleChoice") {
            return <MultipleChoiceAnswer answers={answers}
                question={question.uuid}
                answer={this.answerMultipleChoiceQuestion}
                registrationMode={true} />;
        } else if (question.type === "text") {
            return <Form.Input name={question.uuid} onChange={this.handleChange} />;
        } else if (question.type === "textLong") {
            return <Form.TextArea name={question.uuid} onChange={this.handleChange} />;
        } else if (question.type === "date") {
            return <Form.Input type="date" name={question.uuid} onChange={this.handleChange} />;
        }
    }

    doesAnswerMatch = (enableQuestionId, enableAnswerText) => {
        for (var i = 0; i < this.state.answeredQuestions.length; i++) {
            let answered = this.state.answeredQuestions[i];
            if (answered.questionnaireItem.uuid === enableQuestionId &&
                answered.text === enableAnswerText) {
                return true;
            }
        }
        return false;
    }

    getQuestion = (question) => {
        let answers = this.getAnswers(question);
        let showQuestion = true;
        if (question.enableQuestion) {
            let enableQuestionId = question.enableQuestion.uuid;
            let enableAnswerText = question.enableAnswer;
            showQuestion = this.doesAnswerMatch(enableQuestionId, enableAnswerText);
        }
        if (showQuestion) {
            return <Fragment>
                <Header attached='top' content={question.text} />
                <Segment attached>
                    {this.getAnswerInput(question, answers)}
                </Segment>
            </Fragment>;
        }
    }

    toNextStage = () => {
        this.props.answers(this.state.answeredQuestions);
        this.setState({ answeredQuestions: [] });
        this.props.nextStage();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    sortQuestions = (questions) => {
        return [].concat(questions).sort((a, b) => a.orderNumber > b.orderNumber ? 1 : -1);
    }

    render() {
        const { t } = this.props;
        let questions = this.sortQuestions(this.props.questions);
        return <Fragment>
            <Header as='h1'>{t("Questionnaire Patient Title")}</Header>
            <Divider hidden />
            {questions.slice(0, questions.length).map((item, index) =>
                <Form size='large' key={index}>{this.getQuestion(item)}</Form>)}
            <Divider hidden />
            <Button onClick={this.toNextStage} icon labelPosition='right' color='blue' floated='right'>
                {t("Next")}
                <Icon name='right arrow' />
            </Button>
        </Fragment>;
    }

}

export default withTranslation() (RegisterSpecificQuestionsForm);
