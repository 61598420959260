import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, Header, Segment, Button, Divider, Grid, Dropdown, Form, Checkbox } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeader, authHeaderSet } from '../helpers/auth-header.js';
import { withTranslation } from 'react-i18next';


class DirectReferral extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            services: [],
            codableConcepts: [],
            codableConceptMappings: [],
            referralSuccessful: false
        }
    }

    createEncounter = (patientId, practitionerRoleId) => {
        let pid = patientId;
        if (!patientId) {
            pid = this.state.patientId;
        }
        return fetch(this.config.ENCOUNTERS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                subject: pid,
                participant: practitionerRoleId,
                date: new Date(),
                type: this.config.ENCOUNTER_CONSULT_DIRECT_REFERRAL
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(encounter => {
            this.setState({ encounterId: encounter.id });
            return encounter.id;
        }).catch(error => {
            this.handleError(error);
        });
    }

    createConsent(patientId, practitionerRoleId) {
        return fetch(this.config.CONSENTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                status: "Active",
                scope: "patient-privacy",
                patient: patientId,
                performer: practitionerRoleId,
                organization: this.state.chosenService.provider.uuid,
                policy: "limited"
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    createReferral = () => {
        let scope = this;
        let practitionerRoleId = this.state.activeUser.uuid;
        let patientId = this.props.location.state.patientId;
        let encounterPromise = this.createEncounter(patientId, practitionerRoleId);
        if (encounterPromise) {
            encounterPromise.then(function (encounter) {
                let consentPromise = scope.createConsent(patientId, practitionerRoleId);
                if (consentPromise) {
                    consentPromise.then(function (consent) {
                        scope.registerServiceRequests();
                    })
                }
            })
        }
    }

    registerServiceRequests() {
        return fetch(this.config.SERVICEREQUESTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                encounter: this.state.encounterId,
                performer: this.state.chosenService.defaultAssignedHealthcareService.uuid,
                notes: this.state.socialContextAnswer,
                codableConcepts: this.state.chosenSupportNeeds
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(response => {
            this.setState({ referralSuccessful: true })
        }).catch(error => {
            this.handleError(error);
        });
    }

    retrieveServices = (locationUuid) => {
        return fetch(this.config.HEALTHCARESERVICES_URL + "?pagesize=200&location=" + locationUuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(services => {
                this.setState({ services: services._embedded.healthcareServices })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServicesForPostalCode = () => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + this.props.location.state.postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.retrieveServices(location.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConceptMappings() {
        let codableConceptMappings = [];
        return fetch(this.config.CODABLECONCEPTMAPPINGS_URL + "?pagesize=800", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConceptMappings;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let codableConceptUuid = concept.codableConcept.uuid;
                    codableConceptMappings.push({ uuid, codableConceptUuid });
                }
                this.setState({ codableConceptMappings: codableConceptMappings });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceOptions = () => {
        let options = [];
        for (var i = 0; i < this.state.services.length; i++) {
            let service = this.state.services[i];
            let option = { key: i, text: service.name, value: service };
            if (service.referralMethod === "email") {
                options.push(option);
            }
        }
        return options;
    }

    handleServiceDropdownChange = (e, { value }) => { this.setState({ chosenService: value }) }

    handleCodableConceptDropdownChange = (e, { value }) => { this.setState({ chosenSupportNeeds: value }) }

    handleSocialContextChange = (e, { value }) => { this.setState({ socialContextAnswer: value }) }

    handleError(error) {
        console.log(error.message);
    }

    registerConsent = () => {
        this.setState({
            consentGiven: !this.state.consentGiven
        });
    }

    getHealthcareServices = (givenName) => {
        const { t } = this.props;
        const { chosenService } = this.state;
        return <Fragment>
            <Grid columns={2} stackable>
                <Grid.Column width={10}>
                    <Header as='h2' size='small' textAlign='right'>
                        <Icon name='building outline' color='blue' size='mini' />
                        <Header.Content>{t("Direct Referral S1", { givenName: givenName })}</Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column width={6}><Dropdown
                    placeholder={t("Direct Referral S1 Placeholder")}
                    selection
                    search
                    options={this.getServiceOptions()}
                    fluid
                    clearable
                    value={chosenService}
                    onChange={this.handleServiceDropdownChange}
                /></Grid.Column>
            </Grid>
        </Fragment>;
    }

    getSocialNeeds = (givenName) => {
        const { t } = this.props;
        const { chosenService, chosenSupportNeeds } = this.state;
        if (chosenService) {
            return <Fragment>
                <Grid columns={2} stackable>
                    <Grid.Column width={10}>
                        <Header as='h2' size='small'>
                            <Icon name='handshake' color='blue' size='mini' />
                            <Header.Content>{t("Direct Referral S2", { givenName: givenName })}</Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Dropdown
                            placeholder={t("Direct Referral S2 Placeholder")}
                            selection
                            search
                            options={this.getCodableConceptOptions()}
                            fluid
                            clearable
                            value={chosenSupportNeeds}
                            multiple
                            onChange={this.handleCodableConceptDropdownChange}
                        />
                    </Grid.Column>
                </Grid></Fragment>
        }
    }

    getSocialContext = () => {
        const { t } = this.props;
        const { chosenSupportNeeds, socialContextAnswer } = this.state;
        if (chosenSupportNeeds) {
            return <Fragment>
                <Grid columns={2} stackable>
                    <Grid.Column width={6}>
                        <Header as='h2' size='small'>
                            <Icon name='edit' color='blue' size='mini' />
                            <Header.Content>{t("Direct Referral S3")}</Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.TextArea
                                rows={5}
                                placeholder={t("Direct Referral S3 Placeholder")}
                                name='socialContext'
                                value={socialContextAnswer}
                                onChange={this.handleSocialContextChange} />
                        </Form>
                    </Grid.Column>
                </Grid></Fragment>
        }
    }

    getConsentCheckbox = () => {
        const { t } = this.props;
        const { chosenSupportNeeds } = this.state;
        if (chosenSupportNeeds) {
            return <Segment basic textAlign='right'>
                <Checkbox label={t("Direct Referral Consent")} onChange={this.registerConsent} />
            </Segment>;
        }
    }

    getReferralButton = () => {
        const { t } = this.props;
        if (this.state.consentGiven) {
            return <Segment basic><Button onClick={this.createReferral} icon labelPosition='right' color='blue' floated='right'>
                {t("Direct Referral Refer")}
                <Icon name='right arrow' />
            </Button></Segment>
        } else {
            return <Segment basic><Button disabled onClick={this.createReferral} icon labelPosition='right' color='blue' floated='right'>
                {t("Direct Referral Refer")}
                <Icon name='right arrow' />
            </Button></Segment>
        }
    }

    getCodableConceptOptions = () => {
        let options = [];
        const { chosenService } = this.state;
        const { t } = this.props;
        if (this.state.codableConcepts) {
            for (var i = 0; i < chosenService.codableConcepts.length; i++) {
                let serviceCodableConcept = chosenService.codableConcepts[i];
                for (var j = 0; j < this.state.codableConcepts.length; j++) {
                    let codableConcept = this.state.codableConcepts[j];
                    if (codableConcept.uuid === serviceCodableConcept.uuid) {
                        let option = { key: i, text: t(codableConcept.text), value: codableConcept.uuid };
                        options.push(option);
                    }
                }
            }
        }
        return options;
    }

    componentDidMount() {
        this.loadCodableConcepts();
        this.loadCodableConceptMappings();
        this.retrieveServicesForPostalCode();
    }

    getBody = (givenName) => {
        const { t } = this.props;
        if (this.state.referralSuccessful) {
            return <Segment basic>
                <Header><Icon name='check' color='green' />{t("Direct Referral Success")}</Header>
                <Divider hidden />
                <Button color='blue' as={Link} to={{
                    pathname: '/patient', state: {
                        activeUser: this.state.activeUser,
                        patientId: this.props.location.state.patientId
                    }
                }}>{t("Direct Referral Overview")}</Button></Segment>;
        } else {
            return <Segment basic>
                {this.getHealthcareServices(givenName)}
                {this.getSocialNeeds(givenName)}
                {this.getSocialContext()}
                <Segment basic>
                    {this.getConsentCheckbox()}
                    {this.getReferralButton()}
                </Segment>
            </Segment>
        }
    }

    render() {
        const { t } = this.props;
        let name = this.props.location.state.patientName;
        let givenName = this.props.location.state.givenName;
        return <Grid columns={3}>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
                <Segment>
                    <Header>{t("Direct Referral")} {name}</Header>
                    <Divider />
                    {this.getBody(givenName)}
                </Segment>
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
        </Grid>;
    }
}

export default withTranslation()(withRouter(DirectReferral));
