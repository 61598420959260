import Configuration from '../config/Configuration.js';
import CredentialService from '../helpers/CredentialService.js';
import { authHeader } from '../helpers/auth-header.js';

class AuthenticationService {

    state = {
        username: '',
        password: '',
        authenticationStatus: ''
    }

    constructor() {
        this.config = new Configuration();
        this.credentialService = new CredentialService();
    }

    retrieveMyProfile() {
        return fetch(this.config.PRACTITIONERROLES_URL + "/self", {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    return null;
                }
                return response.json();
            })
            .then(practitionerRole => {
                return practitionerRole;
            })
    }

    login(username, password, keepMeLoggedIn, loginCallback) {
        this.credentialService.startSession(username, password);
        if (keepMeLoggedIn) {
            localStorage.setItem('keepMeLoggedIn', true);
        }
        this.retrieveMyProfile().then((profile) => {
            if (profile) {
                this.credentialService.storeActiveUser(profile);
                loginCallback(true);
            } else {
                this.logout();
                loginCallback(false);
            }
        })
    }

    logout() {
        this.credentialService.endSession();
        localStorage.setItem('keepMeLoggedIn', false);
    }

    keepUserLoggedIn() {
        return localStorage.getItem('keepMeLoggedIn');
    }

    isAuthenticated() {
        return this.credentialService.hasActiveSession();
    }

    getActiveUser() {
        return this.credentialService.getActiveUser();
    }

}
export default AuthenticationService;