import React, { Component, Fragment } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Container, Divider, Grid, Header, Image, List, Segment, Modal, Button, Icon, Responsive } from 'semantic-ui-react';
import IdleTimer from 'react-idle-timer';
import AuthenticationService from './api/AuthenticationService.js';
import HealthMatchBody from './components/HealthMatchBody';
import ZipsterMenu from './components/ZipsterMenu';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import RegistrationForm from './components/RegistrationForm';
import { withTranslation } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language';

import './App.css';
import logo from './images/logo-colour-small.png';
import i18n from "./i18n";

class App extends Component {

  constructor(props) {
    super(props);
    this.authenticationService = new AuthenticationService();
    this.idleTimer = null
    this.state = {
      timeout: 1200000,
      isIdle: false,
      showLogoutModal: false,
      activeUser: null
    }
    // Bind event handlers and methods
    this.onIdle = this.onIdle.bind(this)
  }

  detectLanguage = () => {
    var languageDetected = false;
    if (!languageDetected) {
      var language = detectBrowserLanguage();
      if (language !== null) {
        if (language.startsWith("nl")) {
          this.setDutch();
        } else if (language.startsWith("fr")) {
          this.setFrench();
        } else {
          this.setDutch();
        }
      }
    }
  }

  getLanguage = () => {
    var language = detectBrowserLanguage();
    if (language !== null) {
      if (i18n.language.startsWith("nl")) {
        return "Français";
      } else if (i18n.language.startsWith("fr")) {
        return "Nederlands";
      } else {
        return "Français";
      }
    }
  }

  changeLanguage = () => {
    var language = detectBrowserLanguage();
    if (language !== null) {
      if (i18n.language.startsWith("nl")) {
        this.setFrench();
      } else if (i18n.language.startsWith("fr")) {
        this.setDutch();
      } else {
        return "Français";
      }
    }
  }

  setFrench = () => {
    i18n.changeLanguage('fr');
  }

  setDutch = () => {
    i18n.changeLanguage('nl');
  }

  componentDidMount() {
    this.detectLanguage();
  }

  onIdle() {
    if (!this.authenticationService.keepUserLoggedIn()) {
      this.setState({ isIdle: true, showLogoutModal: true, activeUser: null })
    }
  }

  logout = () => {
    this.setState({ showLogoutModal: false })
    this.authenticationService.logout();
    this.props.history.push("/login");
    this.setState({ isIdle: false, activeUser: null })
  }

  authenticated = () => {
    let activeUser = this.authenticationService.getActiveUser();
    if (activeUser) {
      this.setState({ activeUser: activeUser })
    }
  }

  getLoginPage = () => {
    return <LoginForm authenticated={this.authenticated} />;
  }

  getSignupPage = () => {
    return <SignupForm />;
  }

  getRegistrationPage = () => {
    return <RegistrationForm />;
  }

  getBody = () => {
    return <HealthMatchBody logout={this.logout} />;
  }

  getTermsOfUse = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/conditions-dutilisation/';
    } else {
      return 'https://www.zipster.care/gebruikersvoorwaarden/';
    }
  }

  getPrivacyPolicy = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/politique-de-confidentialite/';
    } else {
      return 'https://www.zipster.care/privacybeleid/';
    }
  }

  getFooter = () => {
    const { t } = this.props;
    return <Segment color='blue' inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>
        <Fragment>
          <Responsive minWidth={1025}>
            <Grid divided inverted stackable>
              <Grid.Column width={5}>
                <Header inverted as='h4' content={t("My Profile")} />
                <List link inverted>
                  <List.Item as={Link} to='/profile'>{t("My Profile")}</List.Item>
                  <List.Item as={Link} to='/organisation'>{t("My Organisation")}</List.Item>
                  <List.Item as={Link} onClick={this.changeLanguage}>{this.getLanguage()}</List.Item>
                  <List.Item onClick={this.logout} as='a'>{t("Log Out")}</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header inverted as='h4' content={t("About Us")} />
                <List link inverted>
                  <List.Item as={Link} to='/contact'>{t("Contact Us Main")}</List.Item>
                  <List.Item as={Link} to='/faq'>{t("FAQ")}</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header inverted as='h4' style={{ fontFamily: 'Montserrat' }} content='zipster.care' />
                <p>
                  {t("Tagline")}
                </p>
              </Grid.Column>
            </Grid>
            <Divider inverted section />
          </Responsive>
        </Fragment>
        <Image centered size='tiny' src={logo} />
        <Responsive minWidth={1025}>
          <List horizontal inverted divided link size='small'>
            <List.Item as={Link} to='/contact'>
              {t("Contact Us Main")}
            </List.Item>
            <List.Item as='a' href={this.getTermsOfUse()} target='_blank'>
              {t("Terms Of Use")}
            </List.Item>
            <List.Item as='a' href={this.getPrivacyPolicy()} target='_blank'>
              {t("Privacy Policy")}
            </List.Item>
          </List>
        </Responsive>
        <Responsive maxWidth={1024}>
          <Fragment>
            <Divider hidden />
            <List inverted link relaxed size='small' >
              <List.Item onClick={this.logout} as='a'>{t("Log Out")}</List.Item>
            </List>
          </Fragment>
        </Responsive>
      </Container>
    </Segment>;
  }

  getHomePage = () => {
    return <div>
      <ZipsterMenu />
      {this.getBody()}
      {this.getFooter()}
    </div>;
  }

  render() {
    const { t } = this.props;
    let activeUser = this.authenticationService.getActiveUser()
    if (this.state.activeUser || activeUser) {
      return <Fragment>
        <Modal
          open={this.state.showLogoutModal}
          onClose={this.logout}
          basic
          size='small'
          dimmer='blurring'>
          <Header icon='lock' content={t("Session Expired")} />
          <Modal.Content>
            <h3>{t("Login Again")}</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={this.logout} inverted>
              <Icon name='checkmark' />OK
            </Button>
          </Modal.Actions>
        </Modal>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          timeout={this.state.timeout}
          startOnLoad />
        <Switch>
          <Route path='*' render={(props) =>
            this.getHomePage()} />
        </Switch>
      </Fragment>;
    } else {
      return <Switch>
        <Route exact path='/register' render={(props) =>
          this.getRegistrationPage()} />
        <Route exact path='/signup' render={(props) =>
          this.getSignupPage()} />
        <Route exact path='*' render={(props) =>
          this.getLoginPage()} />
      </Switch>;
    }
  }

}

export default withTranslation()(withRouter(App));
