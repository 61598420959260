import React, { Component, Fragment } from 'react';
import { Divider, Grid, Menu, Input, Icon, Segment, Header, Loader, Dropdown, Pagination, Button, Table, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import ServiceUtils from '../utils/ServiceUtils.js';
import { authHeader, authHeaderSet, anonHeader } from '../helpers/auth-header.js';
import { withTranslation } from 'react-i18next';

class Services extends Component {

    BXL_SOCIAL = "bxlSocial";
    SOCIALE_KAART = "socialeKaart";

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            services: [],
            codableConcepts: [],
            serviceName: '',
            codableConcept: '',
            activePage: 1,
            serviceRequestPageSize: 8,
            totalPages: 1,
            totalElements: 1,
            elzId: '',
            elzName: '',
            searchLocationId: '',
            searchLocationName: '',
            offerType: 'Eerstelijnszone',
            addService: false,
            serviceRetrievalOngoing: false,
            compactView: false,
            showHealthcareServiceModal: false
        }
    }

    retrieveELZForLocation = (locationId) => {
        return fetch(this.config.LOCATIONS_URL + "?childId=" + locationId + "&level=Eerstelijnszone", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.setState({
                    searchLocationId: location.uuid,
                    searchLocationName: location.name,
                    elzId: location.uuid,
                    elzName: location.name
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveLocationForPostalCode = (postalCode) => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.retrieveELZForLocation(location.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.retrieveLocationForPostalCode(address.postalCode);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.organizationId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.retrieveAddress(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }


    loadCodableConcepts() {
        let codableConcepts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    codableConcepts.push(concept);
                }
                this.setState({ codableConcepts: codableConcepts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsBxlSocial = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsSocialeKaart = (serviceId) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetails = (reference) => {
        let bxlSocialId = ServiceUtils.getBxlSocialReference(reference);
        if (bxlSocialId) {
            let serviceDetails = this.getServiceDetailsBxlSocial(bxlSocialId);
            return serviceDetails;
        } else {
            let serviceDetails = this.getServiceDetailsSocialeKaart(reference);
            return serviceDetails;
        }
    }

    getBxlSocialDetails = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                let healthcareService = this.state.healthcareService;
                healthcareService.serviceDetails = serviceDetails;
                this.setState({
                    healthcareService: healthcareService
                })
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getSocialeKaartLeaflet = (healthcareService) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + healthcareService.socialeKaartReference, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                healthcareService.serviceDetails = leaflet;
                this.setState({
                    healthcareService: healthcareService
                })
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getMailableResource = (healthcareService) => {
        return fetch(this.config.MAILABLE_RESOURCES_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(mailableResources => {
                let resources = mailableResources._embedded.mailableResources;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getHealthcareServiceAreas = (healthcareService) => {
        return fetch(this.config.HEALTHCARESERVICEAREAS_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(healthcareServiceAreas => {
                let resources = healthcareServiceAreas._embedded.healthcareServiceAreas;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServices = () => {
        let query = '';
        if (this.state.serviceName && (this.state.serviceName.length > 1)) {
            query = query + "&name=" + this.state.serviceName;
        }
        if (this.state.codableConcept) {
            query = query + "&codableConcept=" + this.state.codableConcept;
        }
        if (this.state.searchLocationId && this.state.searchLocationId !== this.config.REGION_VLAANDEREN_ID) {
            query = query + "&location=" + this.state.searchLocationId;
        }
        return fetch(this.config.HEALTHCARESERVICES_URL + "?pagesize=" + this.state.serviceRequestPageSize + "&page=" + this.state.activePage + query, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(services => {
                let pages = services._page.totalPages;
                let totalElements = services._page.totalElements;
                let embedded = services._embedded.serviceRequests;
                if (embedded && embedded.length === 0) {
                    this.setState({ noResults: true })
                }
                this.setState({
                    totalPages: pages,
                    totalElements: totalElements,
                    serviceRetrievalOngoing: true,
                    services: []
                });
                return services._embedded.healthcareServices;
            })
            .then(services => {
                let currentComponent = this;
                if (!this.state.compactView) {
                    for (var i = 0; i < services.length; i++) {
                        let service = services[i];
                        let resourcePromise = this.getMailableResource(service);
                        if (resourcePromise) {
                            resourcePromise.then(function (resources) {
                                service.mailableResources = resources;
                                if (service.socialeKaartReference) {
                                    currentComponent.getServiceDetails(service.socialeKaartReference).then(function (serviceDetails) {
                                        service.serviceDetails = serviceDetails;
                                        let currentServices = currentComponent.state.services;
                                        currentServices.push(service);
                                        currentComponent.setState({
                                            services: currentServices
                                        });
                                    })
                                } else {
                                    let currentServices = currentComponent.state.services;
                                    currentServices.push(service);
                                    currentComponent.setState({
                                        services: currentServices
                                    });
                                }
                            })
                        };
                    }
                } else {
                    this.setState({
                        services: services
                    });
                }
                return services;
            }).catch(error => {
                this.handleError(error);
            });
    }

    componentDidUpdate() {
        if (this.state.searchLocationId && this.state.services.length === 0 && !this.state.serviceRetrievalOngoing) {
            this.retrieveServices();
        }
    }

    componentDidMount() {
        if (this.state.codableConcepts.length === 0) {
            this.loadCodableConcepts();
        }
        this.setState({
            organizationId: this.state.activeUser.organization.uuid
        },
            () => {
                this.retrieveOrganization();
            });
    }

    handleChange = (e, { name, value }) => { this.setState({ [name]: value }); this.retrieveServices() }

    handleRegionChange = (e, { value }) => this.setState({ value })

    handleDropdownChange = (e, { value }) => { this.setState({ codableConcept: value }, () => this.retrieveServices()) }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    ////// SERVICE RENDERING ELEMENTS //////

    // ZIPSTER AS SOURCE //

    showZipsterServiceForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showZipsterService(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showZipsterService(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    // SOCIALE KAART OR BXL SOCIAL AS SOURCE //

    showExternalServiceInfoForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showExternalServiceInfo(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showExternalServiceInfo(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    getServiceBody = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            return this.showExternalServiceInfoForMode(healthcareService);
        } else {
            return this.showZipsterServiceForMode(healthcareService);
        }
    }

    // MODAL OPERATIONS //

    handleOpenHealthcareServiceModal = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            }, () => {
                let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
                if (bxlSocialId) {
                    this.getBxlSocialDetails(bxlSocialId);
                } else {
                    this.getSocialeKaartLeaflet(healthcareService);
                }
            })
        } else {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            });
        }
    }

    handleCloseHealthcareServiceModal = () => {
        this.setState({ showHealthcareServiceModal: false });
    }

    getHealthcareServiceModal = () => {
        const { t } = this.props;
        if (this.state.healthcareService) {
            return <Modal
                open={this.state.showHealthcareServiceModal}
                onClose={this.handleCloseHealthcareServiceModal}>
                <Modal.Header>
                    {ServiceUtils.getSocialeKaartLabel(this.state.healthcareService, this.state.showHealthcareServiceModal, this.props)}
                    {this.state.healthcareService.name}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.getServiceBody(this.state.healthcareService)}
                        {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={this.handleCloseHealthcareServiceModal}>
                        <Icon name='close' />{t("Close Window")}
                    </Button>
                </Modal.Actions>
            </Modal>
        }
    }

    getHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>{healthcareService.name}</Header>;
    }

    getCompactServiceOverview = () => {
        const { t } = this.props;
        return <Fragment>
            {this.getHealthcareServiceModal()}
            <Table basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("Service")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Support Needs")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Outreaching")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {this.state.services.slice(0, this.state.services.length).map((service, index) =>
                    <Table.Row>
                        <Table.Cell>
                            {this.getHealthcareServiceHeader(service)}
                        </Table.Cell>
                        <Table.Cell>
                            {ServiceUtils.getCodableConcepts(service, this.state.codableConcepts, this.state.compactView, this.props)}
                        </Table.Cell>
                        <Table.Cell>
                            {ServiceUtils.getReferralMethodIcon(service.referralMethod, this.props)}
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table>
        </Fragment>;
    }

    getExtendedServiceOverview = () => {
        return <Grid columns={1} padded stackable>
            {this.state.services.slice(0, this.state.services.length).map((service, index) =>
                <Grid.Column width={16} key={index}>
                    <Header size='large' attached='top' inverted style={{ backgroundColor: '#385773' }}>
                        {ServiceUtils.getSocialeKaartLabel(service, this.state.showHealthcareServiceModal, this.props)}<Header.Content>{service.name}</Header.Content></Header>
                    <Segment raised attached>
                        {this.getServiceBody(service)}
                    </Segment>
                </Grid.Column>
            )}
        </Grid>;
    }

    getServices = () => {
        if (this.state.services) {
            if (this.state.compactView) {
                return this.getCompactServiceOverview();
            } else {
                return this.getExtendedServiceOverview();
            }
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    }

    getOptions = () => {
        const { t } = this.props;
        let options = [];
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            let codableConcept = this.state.codableConcepts[i];
            let option = { key: i, text: t(codableConcept.text), value: codableConcept.uuid };
            options.push(option);
        }
        return options;
    }


    handlePaginationChange = (e, { activePage }) => {
        this.setState({
            services: [],
            activePage: activePage
        }, () => {
            this.retrieveServices();
        })
    }

    toggleOffer = () => {
        if (this.state.offerType === 'Eerstelijnszone') {
            this.setState({
                serviceRetrievalOngoing: false,
                services: [],
                offerType: 'Gewest',
                searchLocationId: this.config.REGION_VLAANDEREN_ID,
                searchLocationName: 'Vlaanderen'
            });
        } else if (this.state.offerType === 'Gewest') {
            this.setState({
                serviceRetrievalOngoing: false,
                services: [],
                offerType: 'Eerstelijnszone',
                searchLocationId: this.state.elzId,
                searchLocationName: this.state.elzName
            });
        }
    }

    getRegionButtonDescription = () => {
        const { t } = this.props;
        if (this.state.offerType === 'Eerstelijnszone') {
            return t("Show Full Offer");
        } else if (this.state.offerType === 'Gewest') {
            return t("Show ELZ Offer");
        }
    }

    enableAddService = () => {
        this.setState({ addService: true })
    }

    switchViewingMode = () => {
        if (this.state.compactView) {
            this.setState({
                compactView: false,
                serviceRequestPageSize: 8,
                services: [],
                activePage: 1
            }, () => {
                this.retrieveServices();
            })
        } else {
            this.setState({
                compactView: true,
                serviceRequestPageSize: 60,
                services: [],
                activePage: 1
            }, () => {
                this.retrieveServices();
            })
        }
    }

    getViewButton = () => {
        if (this.state.compactView) {
            return <Button icon floated='left' circular color='blue' onClick={this.switchViewingMode}><Icon name='list layout' /></Button>;
        } else {
            return <Button icon floated='left' circular color='blue' onClick={this.switchViewingMode}><Icon name='grid layout' /></Button>;
        }
    }

    showServices = () => {
        const { t } = this.props;
        const { serviceName, codableConcept, totalPages } = this.state;
        return (<Fragment>
            <Header attached='top' size='huge' textAlign='center'>{t("Services")}<Header.Subheader>{this.state.searchLocationName}</Header.Subheader></Header>
            <Segment attached>
                <Menu text >
                    <Menu.Item>
                    </Menu.Item>
                    <Menu.Item>
                        <Input name='serviceName' value={serviceName} onChange={this.handleChange} icon='search' placeholder={t("Search By Name")} />
                    </Menu.Item>
                    <Menu.Item>
                    </Menu.Item>
                    <Menu.Menu text position='right'>
                        <Menu.Item>
                            {this.state.totalElements} {t("Results")}
                        </Menu.Item>
                        <Menu.Item>
                            {t("Filter By Need")}
                        </Menu.Item>
                        <Dropdown
                            placeholder={t("Choose Support Need")}
                            selection
                            search
                            options={this.getOptions()}
                            fluid
                            clearable
                            value={codableConcept}
                            onChange={this.handleDropdownChange}
                        />
                    </Menu.Menu>
                </Menu>
                <Divider />
                <Segment textAlign='center' basic>
                    {this.getViewButton()}
                    <Pagination
                        activePage={this.state.activePage}
                        onPageChange={this.handlePaginationChange}
                        size='mini'
                        totalPages={totalPages} />
                    <Button icon color='blue' floated='right' labelPosition='right' onClick={this.toggleOffer} as={Link}>{this.getRegionButtonDescription()} <Icon name='marker' /></Button>
                </Segment>
                {this.getServices()}
            </Segment>
        </Fragment >
        );
    }

    render() {
        return this.showServices();
    }

}

export default withTranslation()(Services);
