import React, { Component, Fragment } from 'react';
import { Grid, Segment, Menu, Button, Divider, Icon, Input, Card, Image, Message, Loader, List, Responsive, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Configuration from '../config/Configuration.js';
import { authHeaderSet, authHeader } from '../helpers/auth-header.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { getAge } from '../helpers/date-utils.js';
import logogreen from '../images/state-green.png';
import logored from '../images/state-red.png';
import logoorange from '../images/state-orange.png';
import logoyellow from '../images/state-yellow.png';
import logogrey from '../images/state-grey.png';
import { withTranslation } from 'react-i18next';

class ProviderDashboard extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            patients: [],
            codableTexts: [],
            codableConceptMappings: [],
            pagesizePatients: 12,
            pagesize: 24,
            patientName: '',
            patientsCallDone: false,
            serviceRequestStatus: '',
            totalPatients: 0,
            activePage: 1,
            totalPages: 1,
            totalElements: 1,
            organizationId: ''
        }
    }

    handleChange = (e, { name, value }) => { this.setState({ [name]: value }); this.retrievePatients() }

    retrieveLastEncounterDate = (patientId) => {
        return fetch(this.config.ENCOUNTERS_URL + "?patient=" + patientId + "&pagesize=1", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(encounters => {
                let embedded = encounters._embedded.encounters;
                if (embedded && embedded.length > 0) {
                    let lastEncounter = embedded[0];
                    let patients = this.state.patients;
                    for (var i = 0; i < patients.length; i++) {
                        if (patients[i].uuid === patientId) {
                            patients[i].lastEncounterDate = lastEncounter.date;
                            break;
                        }
                    }
                    this.setState({
                        patients: patients
                    });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAssignedHealthcareService = (uuid) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "/" + uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            }).then(assignedHealthcareService => {
                if (assignedHealthcareService) {
                    return assignedHealthcareService.healthcareService.name;
                } else {
                    return '';
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    setOverallStatusAndDetailedSupport = (serviceRequests, patientId) => {
        let currentComponent = this;
        let status = "REQUESTED";
        let detailedSupport = [];
        let serviceRequest = serviceRequests[0];
        if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
            status = this.config.SERVICE_REQUEST_STATUS_SUPPORT;
        } if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
            status = this.config.SERVICE_REQUEST_STATUS_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
            status = this.config.SERVICE_REQUEST_STATUS_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_FORWARD) {
            status = "REQUESTED";
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SHOW) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
            status = "REQUESTED";
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
            status = this.config.SERVICE_REQUEST_STATUS_PLANNED;
        }
        for (var c = 0; c < serviceRequests.length; c++) {
            let request = serviceRequests[c];
            let promise = this.retrieveAssignedHealthcareService(request.performer.uuid);
            if (promise) {
                promise.then(function (healthcareServiceName) {
                    let details = {
                        healthcareService: healthcareServiceName,
                        status: request.status
                    };
                    detailedSupport.push(details);
                    let statusAndDetailedSupport = {
                        overallStatus: status,
                        detailedSupport: detailedSupport
                    }
                    let patients = currentComponent.state.patients;
                    for (var i = 0; i < patients.length; i++) {
                        if (patients[i].uuid === patientId) {
                            patients[i].status = statusAndDetailedSupport;
                            break;
                        }
                    }
                    currentComponent.setState({
                        patients: patients
                    });
                })
            }
        }
    }

    retrieveServiceRequestStatus = (patient) => {
        let patientId = patient.uuid;
        return fetch(this.config.SERVICEREQUESTS_URL + "?patient=" + patientId + "&pagesize=" + this.state.pagesize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequests => {
                let embedded = serviceRequests._embedded.serviceRequests;
                let patients = this.state.patients;
                if (embedded && embedded.length > 0) {
                    this.setOverallStatusAndDetailedSupport(embedded, patientId);
                } else {
                    for (var j = 0; j < patients.length; j++) {
                        if (patients[j].uuid === patientId) {
                            patients[j].status = { overallStatus: "NO REQUEST" };
                            break;
                        }
                    }
                    this.setState({
                        patients: patients
                    });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePatients = () => {
        let query = '';
        if (this.state.patientName && (this.state.patientName.length > 1)) {
            query = "&name=" + this.state.patientName;
        }
        if (this.state.organizationId && (this.state.organizationId.length > 1)) {
            query = query + "&managingOrganization=" + this.state.organizationId;
        }
        if (this.state.serviceRequestStatus && (this.state.serviceRequestStatus.length > 1)) {
            switch (this.state.serviceRequestStatus) {
                case "In afwachting":
                    query = query + "&serviceRequestStatus=PatientMessageSent";
                    break;
                case "Reeds ondersteund":
                    query = query + "&serviceRequestStatus=SUPPORT";
                    break;
                case "Niet ondersteund":
                    query = query + "&serviceRequestStatus=NO SUPPORT";
                    break;
                case "Doorverwezen":
                    query = query + "&serviceRequestStatus=FORWARD";
                    break;
                case "Traject afgerond":
                    query = query + "&serviceRequestStatus=SUPPORT ENDED";
                    break;
                default:
                    break;
            }
        }
        return fetch(this.config.PATIENTS_URL + "?pagesize=" + this.state.pagesizePatients + "&page=" + this.state.activePage + query, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(patients => {
                let pages = patients._page.totalPages;
                let totalElements = patients._page.totalElements;
                this.setState({
                    patients: patients._embedded.patients,
                    totalPatients: patients._page.totalElements,
                    patientsCallDone: true,
                    totalPages: pages,
                    totalElements: totalElements
                }, () => {
                    for (var i = 0; i < this.state.patients.length; i++) {
                        this.retrieveServiceRequestStatus(this.state.patients[i]);
                    }
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    componentDidMount() {
        this.setState({
            organizationId: this.state.activeUser.organization.uuid
        },
            () => {
                this.retrievePatients();
            })
    }

    handlePaginationChange = (e, { activePage }) => this.setState({
        activePage,
        patients: []
    }, () => {
        this.retrievePatients();
    })

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    getDate = (date) => {
        if (date) {
            return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(Date.parse(date));
        }
    }

    getStatusImage = (patient) => {
        if (patient.status) {
            let logo = logogreen;
            if (patient.status.overallStatus === "NO REQUEST") {
                logo = logogrey;
            } else if (patient.status.overallStatus === "REQUESTED") {
                logo = logoorange;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
                logo = logogreen;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
                logo = logogreen;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
                logo = logored;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
                logo = logoyellow;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_NO_SHOW) {
                logo = logored;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED) {
                logo = logored;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE) {
                logo = logored;
            } else if (patient.status.overallStatus === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
                logo = logogreen;
            }
            return <Image circular floated='left' src={logo} size='mini' />;
        } else {
            return <Image circular floated='left' src={logogrey} size='mini' disabled />;
        }

    }

    getSupportDescription = (support, givenName) => {
        const { t } = this.props;
        let description = "";
        if (support.healthcareService) {
            if (support.status === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
                description = <Fragment><List.Icon name='calendar' color='blue' />
                    {t("PD Planned", { serviceName: support.healthcareService })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
                description = <Fragment><List.Icon name='wait' color='blue' />
                    {t("PD WL", { serviceName: support.healthcareService, givenName: givenName })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
                description = <Fragment><List.Icon name='check' color='blue' />
                    {t("PD Support", { serviceName: support.healthcareService, givenName: givenName })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
                description = <Fragment><List.Icon name='check' color='blue' />
                    {t("PD Already Supported")}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
                description = <Fragment><List.Icon name='cancel' color='blue' />
                    {t("PD No Support", { serviceName: support.healthcareService })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_FORWARD) {
                description = <Fragment><List.Icon name='building' color='blue' />
                    {t("PD Referred", { serviceName: support.healthcareService })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_SIGNPOSTING) {
                description = <Fragment><List.Icon name='info circle' color='blue' />
                    {t("PD Informed", { serviceName: support.healthcareService })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_SIGNPOSTING_FAILED) {
                return;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE) {
                description = <Fragment><List.Icon name='dont' color='blue' />
                    {t("PD Not Reachable", { serviceName: support.healthcareService, givenName: givenName })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_NO_SHOW) {
                description = <Fragment><List.Icon name='dont' color='blue' />
                    {t("PD No Show", { givenName: givenName })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED) {
                description = <Fragment><List.Icon name='dont' color='blue' />
                    {t("PD No Support Wanted", { serviceName: support.healthcareService, givenName: givenName })}
                </Fragment>;
            } else if (support.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
                description = <Fragment><List.Icon name='thumbs up' color='blue' />
                    {t("PD No Support Wanted", { serviceName: support.healthcareService })}
                </Fragment>;
            } else {
                description = <Fragment><List.Icon name='mail forward' color='blue' />
                    {t("PD Referral", { serviceName: support.healthcareService })}
                </Fragment>;
            }
        }
        return description;
    }

    getDetailedSupport = (patient) => {
        const { t } = this.props;
        if (patient.status) {
            if (patient.status.detailedSupport && patient.status.detailedSupport.length > 0) {
                return <List>{patient.status.detailedSupport.slice(0, patient.status.detailedSupport.length).map((support, index) =>
                    <List.Item key={index}>{this.getSupportDescription(support, patient.givenName)}</List.Item>
                )}
                </List>;
            } else {
                if (patient.status.overallStatus === "NO REQUEST") {
                    return <p>{t("PD No Referrals", { givenName: patient.givenName })}</p>;
                }
            }
        }
    }

    getLastUpdateColumns = (patient) => {
        const { t } = this.props;
        if (patient.lastEncounterDate) {
            return <Fragment>
                <Grid.Column width={8}>
                    {t("PD Last Update")}
                </Grid.Column>
                <Grid.Column width={8} textAlign='right'>
                    {this.getDate(patient.lastEncounterDate)}
                </Grid.Column>
            </Fragment>
        }
    }

    getCard = (patient) => {
        const { t } = this.props;
        return <Card fluid raised as={Link} to={{
            pathname: '/patient',
            state: { patientId: patient.uuid, activeUser: this.state.activeUser }
        }}>
            <Card.Content>
                {this.getStatusImage(patient)}
                <Card.Header>{patient.name}</Card.Header>
                <Card.Meta>{getAge(patient.birthdate)} {t("Years")}</Card.Meta>
                <Card.Description>
                    {this.getDetailedSupport(patient)}
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            {t("PD Contact")}
                        </Grid.Column>
                        <Grid.Column width={10} textAlign='right'>
                            <Icon color='blue' name='phone'></Icon>{patient.phone}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>;
    }

    getPatientGrid = () => {
        const { t } = this.props;
        const {
            activePage,
            totalPages,
        } = this.state;
        if (this.state.patients && this.state.patients.length > 0) {
            return <Fragment>
                <Responsive maxWidth={1024}>
                    <Grid columns={2} padded='horizontally' stackable >
                        {this.state.patients.slice(0, this.state.patients.length).map((patient, index) =>
                            <Grid.Column key={index}>
                                {this.getCard(patient)}
                            </Grid.Column>
                        )}
                    </Grid>
                    <Segment basic textAlign='center'>
                        <Pagination
                            activePage={activePage}
                            onPageChange={this.handlePaginationChange}
                            size='mini'
                            totalPages={totalPages} />
                    </Segment>
                </Responsive>
                <Responsive minWidth={1025} maxWidth={1919}>
                    <Fragment>
                        <Grid columns={3} padded stackable>
                            {this.state.patients.slice(0, this.state.patients.length).map((patient, index) =>
                                <Grid.Column key={index}>
                                    {this.getCard(patient)}
                                </Grid.Column>
                            )}
                        </Grid>
                        <Segment basic textAlign='center'>
                            <Pagination
                                activePage={activePage}
                                onPageChange={this.handlePaginationChange}
                                size='mini'
                                totalPages={totalPages} />
                        </Segment>
                    </Fragment>
                </Responsive>
                <Responsive minWidth={1920}>
                    <Fragment>
                        <Grid columns={4} padded stackable>
                            {this.state.patients.slice(0, this.state.patients.length).map((patient, index) =>
                                <Grid.Column key={index}>
                                    {this.getCard(patient)}
                                </Grid.Column>
                            )}
                        </Grid>
                    </Fragment>
                    <Segment basic textAlign='center'>
                        <Pagination
                            activePage={activePage}
                            onPageChange={this.handlePaginationChange}
                            size='mini'
                            totalPages={totalPages} />
                    </Segment>
                </Responsive>
            </Fragment>
        } else {
            if (this.state.patientsCallDone) {
                if (this.state.patientName) {
                    return <Message info>
                        <Message.Header>{t("PD No Patients", { patientName: this.state.patientName })}</Message.Header>
                        <p>{t("PD No Patients Description")}</p>
                    </Message>
                } else {
                    return <Message info>
                        <Message.Header>{t("PD No Patients Registered")}</Message.Header>
                        <p>{t("PD No Patients Registered Description")}</p>
                    </Message>
                }
            } else {
                return <Loader active inline='centered' size='large' />;
            }
        }
    }

    getMenu = () => {
        const { t } = this.props;
        const { patientName } = this.state;
        return <Fragment>
            <Responsive minWidth={1025}>
                <Menu text >
                    <Menu.Item>
                        <Button as={Link} to='/newcontact' primary icon size='tiny' labelPosition='left'>
                            <Icon name='edit' />{t("PD New Contact")}</Button>
                    </Menu.Item>
                    <Menu.Menu text position='right'>
                        <Menu.Item>
                            {this.state.totalPatients} {t("Results")}
                        </Menu.Item>
                        <Menu.Item>
                            <Input name='patientName' value={patientName} onChange={this.handleChange} icon='search' placeholder={t("Search By Name")} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Divider />
            </Responsive>
            <Responsive maxWidth={1025}>
                <Menu text >
                    <Menu.Item>
                        <Button as={Link} to='/newcontact' primary icon size='tiny' labelPosition='left'>
                            <Icon name='edit' />{t("PD New Contact")}</Button>
                    </Menu.Item>
                    <Menu.Menu text position='right'>
                        <Menu.Item>
                            {this.state.totalPatients} {t("Results")}
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Divider />
            </Responsive>
        </Fragment >;
    }

    render() {
        return <Segment>
            {this.getMenu()}
            {this.getPatientGrid()}
        </Segment>;
    }
}

export default withTranslation()(withRouter(ProviderDashboard));
