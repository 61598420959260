import React, { Component, Fragment } from 'react';
import { Button, Form, Icon, Header, Grid, Segment, Divider } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class RegisterBackgroundInfoForm extends Component {

    state = {
        answeredQuestions: this.props.savedAnswers,
        sectionTitles: []
    }

    getSections = () => {
        let sectionTitles = [];
        for (var i = 0; i < this.props.questions.length; i++) {
            let question = this.props.questions[i];
            if (question.parent && !sectionTitles.includes(question.parent.name)) {
                sectionTitles.push(question.parent.name);
            }
        }
        return sectionTitles;
    }

    answerQuestion = (question) => {
        if (this.state.answeredQuestions.includes(question.uuid)) {
            const index = this.state.answeredQuestions.indexOf(question.uuid);
            if (index > -1) {
                let answers = this.state.answeredQuestions;
                answers.splice(index, 1);
                this.setState({ answeredQuestions: answers });
            }
        } else {
            this.state.answeredQuestions.push(question.uuid);
        }
    }

    isChecked = (item) => {
        if (this.state.answeredQuestions.includes(item.uuid)) {
            return true;
        }
    }

    getSectionQuestions = (sectionTitle) => {
        const { t } = this.props;
        let sectionQuestions = [];
        for (var i = 0; i < this.props.questions.length; i++) {
            let question = this.props.questions[i];
            if (question.parent && question.parent.name === sectionTitle) {
                sectionQuestions.push(question);
            }
        }
        return <Form>{sectionQuestions.slice(0, sectionQuestions.length).map((item, index) =>
            <Form.Checkbox checked={this.isChecked(item)} key={index} label={t(item.text)} onChange={this.answerQuestion.bind(this, item)} />)}</Form>
    }

    getPositiveAnswer = (question) => {
        let answerOptions = question.questionnaireAnswerOptionOutputListModel.questionnaireAnswerOptions;
        for (var i = 0; i < answerOptions.length; i++) {
            if (answerOptions[i].text === 'Ja') {
                return answerOptions[i];
            }
        }
        return;
    }

    getNegativeAnswer = (question) => {
        let answerOptions = question.questionnaireAnswerOptionOutputListModel.questionnaireAnswerOptions;
        for (var i = 0; i < answerOptions.length; i++) {
            if (answerOptions[i].text === 'Nee') {
                return answerOptions[i];
            }
        }
        return;
    }

    getAnswers = () => {
        let answers = [];
        for (var i = 0; i < this.props.questions.length; i++) {
            if (this.state.answeredQuestions.includes(this.props.questions[i].uuid)) {
                answers.push(this.getPositiveAnswer(this.props.questions[i]));
            }
        }
        return answers;
    }

    toNextStage = () => {
        let answers = this.getAnswers();
        this.props.answers(answers);
        this.props.nextStage();
    }

    render() {
        const { t } = this.props;
        let sectionTitles = this.getSections();
        return <Fragment>
            <Header as='h1'>{t("Profile Title")}</Header>
            <Grid columns={1}>
                {sectionTitles.slice(0, sectionTitles.length).map((section, index) =>
                    <Grid.Column key={index}>
                        <Segment>
                            <Header as='h3'>{t(section)}</Header>
                            <Divider />
                            {this.getSectionQuestions(section)}
                        </Segment>
                    </Grid.Column>)}
            </Grid>
            <Divider hidden />
            <Button onClick={this.toNextStage} icon labelPosition='right' color='blue' floated='right'>
                {t("Next")}
                <Icon name='right arrow' />
            </Button>
        </Fragment>;
    }

}

export default withTranslation()(RegisterBackgroundInfoForm);
