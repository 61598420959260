import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Form, Grid, Image, Message, Segment, Responsive } from 'semantic-ui-react';
import AuthenticationService from '../api/AuthenticationService.js';
import Configuration from '../config/Configuration.js';
import logo from '../images/logo-colour.png';
import { withTranslation } from 'react-i18next';

class LoginForm extends Component {

  state = {
    username: '',
    password: '',
    keepMeLoggedIn: false,
    authenticationResult: '',
    loginSuccessful: false
  }

  constructor() {
    super();
    this.authenticationService = new AuthenticationService();
    this.config = new Configuration();
  }

  handleResponseError(response) {
    throw Error(response.statusText);
  }

  handleError(error) {
    console.log(error.message);
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { username, password, keepMeLoggedIn } = this.state;
    let currentClass = this;
    this.authenticationService.login(username, password, keepMeLoggedIn, (function (authenticationResult) {
      if (authenticationResult) {
        currentClass.setState({ authenticationStatus: 'SUCCESS' });
      } else {
        currentClass.setState({ authenticationStatus: 'FAILED' });
      }
    }))
  }

  getMessage = () => {
    const { t } = this.props;
    if (this.state.authenticationStatus === 'FAILED') {
      return <Message color='blue'>
        <Message.Header>{t("Login Failed")}</Message.Header>
        <p>{t("Login Failed S1")}</p>
        <p>{t("Login Failed S2")}<a href="mailto:hallo@zipster.care">{t("Here")}</a>.</p></Message>
    } else return <Message>
      <p>{t("Invitation")}</p>
      <p><a href="mailto:hallo@zipster.care">{t("Contact Us")}</a></p>
    </Message>;
  }

  getLoginForm = () => {
    const { t } = this.props;
    const { username, password, keepMeLoggedIn } = this.state
    return <Fragment>
      <Responsive minWidth={1025}>
        <Fragment><style>{'body { background-color: #385773; }'}</style><Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image size='medium' src={logo} centered />
            <Form size='large' onSubmit={this.handleSubmit} style={{ marginTop: "2%" }}>
              <Segment stacked >
                <Form.Input
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder={t("Username")}
                  name='username'
                  value={username}
                  onChange={this.handleChange} />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder={t("Password")}
                  name='password'
                  type='password'
                  value={password}
                  onChange={this.handleChange}
                />
                <Form.Button color='orange' fluid size='large'>{t("Login")}</Form.Button>
                <Form.Checkbox
                  name='keepMeLoggedIn'
                  checked={keepMeLoggedIn}
                  onChange={this.handleChange}
                  label={t("Keep Me Logged In")} />
              </Segment>
            </Form>
            {this.getMessage()}
          </Grid.Column>
        </Grid>
        </Fragment>
      </Responsive>
      <Responsive maxWidth={1024}>
        <Fragment><style>{'body { background-color: #385773; }'}</style><Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
          <Grid.Column style={{ margin: "5%" }}>
            <Image size='medium' src={logo} centered />
            <Form size='large' onSubmit={this.handleSubmit}>
              <Segment stacked >
                <Form.Input
                  fluid
                  icon='user'
                  iconPosition='left'
                  placeholder={t("Username")}
                  name='username'
                  value={username}
                  onChange={this.handleChange} />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder={t("Password")}
                  name='password'
                  type='password'
                  value={password}
                  onChange={this.handleChange}
                />
                <Form.Button color='orange' fluid size='large'>{t("Login")}</Form.Button>
                <Form.Checkbox
                  name='keepMeLoggedIn'
                  checked={keepMeLoggedIn}
                  onChange={this.handleChange}
                  label={t("Keep Me Logged In")} />
              </Segment>
            </Form>
            {this.getMessage()}
          </Grid.Column>
        </Grid>
        </Fragment>
      </Responsive>
    </Fragment>
  }

  componentDidUpdate = () => {
    if (this.state.authenticationStatus === 'SUCCESS') {
      this.setState({ loginSuccessful: true });
    }
  }

  render() {
    if (this.state.loginSuccessful) {
      this.props.authenticated()
      return null;
    } else {
      return this.getLoginForm();
    }
  }

}

export default withTranslation()(withRouter(LoginForm));