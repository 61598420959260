import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, Header, Segment, Button, Divider, Grid, Responsive } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { withTranslation } from 'react-i18next';

class ChooseReferralType extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
        }
    }

    render() {
        const { t } = this.props;
        let patientId = this.props.location.state.patientId;
        let name = this.props.location.state.patientName;
        let givenName = this.props.location.state.patientGivenName;
        let postalCode = this.props.location.state.patientPostalCode;
        return <Segment>
            <Divider hidden />
            <Header as='h1' textAlign='center'>{name}<Header.Subheader>
                {t("Referral Options")}
            </Header.Subheader></Header>
            <Divider hidden />
            <Responsive maxWidth={1024}>
                <Grid columns='equal' stretched stackable>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='mail forward' color='blue' size='small' />
                                    {t("Direct Referral")}
                                    <Header.Subheader>
                                        {t("Direct Referral Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button color='blue' as={Link} to={{
                                pathname: '/directreferral',
                                state: {
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }
                            }}>{t("Start Referral")}</Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Responsive>
            <Responsive minWidth={1025}>
                <Grid columns='equal' stretched stackable>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='clipboard list' color='blue' size='small' />
                                    {t("Questionnaire")}
                                    <Header.Subheader>
                                        {t("Questionnaire Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button color='blue' as={Link} to={{
                                pathname: '/questionnaire',
                                state: {
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }
                            }}>{t("Start Questionnaire")}</Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='map signs' color='blue' size='small' />
                                    {t("Needs Known")}
                                    <Header.Subheader>
                                        {t("Needs Known Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button color='blue' as={Link} to={{
                                pathname: '/needsbasedreferral',
                                state: {
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }
                            }}>{t("Show Offer")}</Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='mail forward' color='blue' size='small' />
                                    {t("Direct Referral")}
                                    <Header.Subheader>
                                        {t("Direct Referral Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button color='blue' as={Link} to={{
                                pathname: '/directreferral',
                                state: {
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }
                            }}>{t("Start Referral")}</Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Responsive>
            <Divider hidden />
        </Segment>
    }
}

export default withTranslation()(withRouter(ChooseReferralType));
