import React, { Component, Fragment } from 'react';
import { Segment, Loader, Image, List, Message, Menu, Dropdown, Divider, Table, Pagination, Button, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeaderSet, authHeader } from '../helpers/auth-header.js';
import logogreen from '../images/state-green.png';
import logoorange from '../images/state-orange.png';
import logored from '../images/state-red.png';
import logogrey from '../images/state-grey.png';
import logoyellow from '../images/state-yellow.png';
import { withTranslation } from 'react-i18next';

class ServiceDashboard extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            serviceRequests: [],
            encounters: [],
            codableConcepts: [],
            pagesize: 24,
            servicesCallDone: false,
            requestCallDone: false,
            encounterCallsDone: false,
            noResults: false,
            serviceRequestStatus: '',
            serviceRequestPageSize: 10,
            activePage: 1,
            totalPages: 1,
            totalElements: 1,
            assignedHealthcareService: null,
            assignedHealthcareServices: []
        }
    }

    retrievePractitionerHealthcareService = () => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "?practitionerRole=" + this.state.activeUser.uuid + "&pagesize=" + this.state.pagesize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(assignedHealthcareServices => {
                let healthcareService = null;
                let embedded = assignedHealthcareServices._embedded.assignedHealthcareServices;
                if (embedded.length === 1) {
                    healthcareService = embedded[0].healthcareService;
                    this.setState({ assignedHealthcareService: healthcareService });
                } else if (embedded.length > 1) {
                    this.setState({ assignedHealthcareServices: embedded });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
                return codableTexts;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveEncounter = (encounterId) => {
        return fetch(this.config.ENCOUNTERS_URL + "/" + encounterId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            }).catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceRequestConcepts = (serviceRequestId) => {
        return fetch(this.config.SERVICEREQUESTS_CONCEPTS_URL + "?serviceRequest=" + serviceRequestId + "&pagesize=" + this.state.pagesize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequests => {
                let codableConceptIds = [];
                let embedded = serviceRequests._embedded.serviceRequestCodableConcepts;
                for (var i = 0; i < embedded.length; i++) {
                    codableConceptIds.push(embedded[i].codableConcept.uuid);
                }
                return codableConceptIds;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceRequests = () => {
        let query = '';
        if (this.state.serviceRequestStatus && (this.state.serviceRequestStatus.length > 1)) {
            switch (this.state.serviceRequestStatus) {
                case "In afwachting":
                    query += "&status=PatientMessageSent";
                    break;
                case "Reeds ondersteund":
                    query += "&status=SUPPORT";
                    break;
                case "Niet ondersteund":
                    query += "&status=NO SUPPORT";
                    break;
                case "Doorverwezen":
                    query += "&status=FORWARD";
                    break;
                default:
                    break;
            }
        }
        if (this.state.assignedHealthcareService) {
            query += "&performer=" + this.state.assignedHealthcareService.uuid;
        }
        return fetch(this.config.SERVICEREQUESTS_URL + "?pagesize=" + this.state.serviceRequestPageSize + "&page=" + this.state.activePage + query, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequests => {
                let pages = serviceRequests._page.totalPages;
                let totalElements = serviceRequests._page.totalElements;
                let embedded = serviceRequests._embedded.serviceRequests;
                if (embedded && embedded.length === 0) {
                    this.setState({ noResults: true })
                }
                this.setState({
                    serviceRequests: embedded,
                    requestCallDone: true,
                    totalPages: pages,
                    totalElements: totalElements
                }, () => {
                    for (var i = 0; i < embedded.length; i++) {
                        let serviceRequestId = embedded[i].uuid;
                        let currentComponent = this;
                        this.retrieveServiceRequestConcepts(serviceRequestId).then(function (codableConceptIds) {
                            let fullCodableConcepts = [];
                            for (var j = 0; j < codableConceptIds.length; j++) {
                                let codableConcept = currentComponent.getCodableConcept(codableConceptIds[j]);
                                if (codableConcept) {
                                    fullCodableConcepts.push(codableConcept);
                                }
                            }
                            return fullCodableConcepts;
                        }).then(function (fullCodableConcepts) {
                            for (var i = 0; i < embedded.length; i++) {
                                if (embedded[i].uuid === serviceRequestId) {
                                    embedded[i].codableConcepts = fullCodableConcepts;
                                    break;
                                }
                            }
                            currentComponent.setState({
                                serviceRequests: embedded
                            });
                        })
                    }
                })
                return embedded;
            })
            .then(serviceRequests => {
                let currentComponent = this;
                for (var i = 0; i < serviceRequests.length; i++) {
                    let serviceRequest = serviceRequests[i];
                    let promise = currentComponent.retrieveEncounter(serviceRequest.encounter.uuid)
                    if (promise) {
                        promise.then(function (encounter) {
                            if (encounter && !currentComponent.state.encounters.includes(encounter)) {
                                serviceRequest.encounter = encounter;
                                let encounters = currentComponent.state.encounters;
                                encounters.push(encounter);
                                currentComponent.setState({
                                    serviceRequests: serviceRequests,
                                    encounters: encounters
                                });
                            }
                        });
                    }
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    componentDidMount() {
        let currentComponent = this;
        let conceptPromise = this.loadCodableConcepts();
        if (conceptPromise) {
            conceptPromise.then(function () {
                let practitionerPromise = currentComponent.retrievePractitionerHealthcareService();
                if (practitionerPromise) {
                    practitionerPromise.then(function () {
                        currentComponent.retrieveServiceRequests()
                    })
                }
            })
        }
    }

    componentDidUpdate() {
        if (this.state.serviceRequests.length > 0 &&
            this.state.encounters.length === this.state.serviceRequests.length
            && !this.state.encounterCallsDone) {
            this.setState({
                encounterCallsDone: true
            })
        }
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    getStatus = (serviceRequest) => {
        const { t } = this.props;
        let text = '';
        switch (serviceRequest.status) {
            case "SendPatientMessageFailed":
                text = t("SD Awaiting");
                break;
            case "PatientMessageSent":
                text = t("SD Awaiting");
                break;
            case this.config.SERVICE_REQUEST_STATUS_PLANNED:
                text = t("SD Planned");
                break;
            case this.config.SERVICE_REQUEST_STATUS_WAITING_LIST:
                text = t("SD Waiting List");
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT:
                text = t("SD Support");
                break;
            case this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED:
                text = t("SD Already Supported");
                break;
            case this.config.SERVICE_REQUEST_STATUS_FORWARD:
                text = t("SD Referred");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT:
                text = t("SD Not Supported");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SHOW:
                text = t("SD No Show");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED:
                text = t("SD No Support Wanted");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE:
                text = t("SD Unreachable");
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED:
                text = t("SD Support Ended");
                break;
            default:
                text = t("SD Awaiting");
        }
        return text;
    }

    getDate = (date) => {
        return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(Date.parse(date));
    }

    getCodableConceptText = (serviceRequest) => {
        const { t } = this.props;
        let codableConceptTexts = [];
        let codableConcepts = serviceRequest.codableConcepts;
        if (codableConcepts) {
            for (var j = 0; j < codableConcepts.length; j++) {
                let codableConcept = codableConcepts[j];
                for (var i = 0; i < this.state.codableConcepts.length; i++) {
                    if (this.state.codableConcepts[i].uuid === codableConcept.uuid) {
                        codableConceptTexts.push(this.state.codableConcepts[i].text);
                    }
                }
            }
            if (codableConceptTexts.length > 0) {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) => {
                        return <List.Item key={index}><List.Icon name='angle double right' color='blue' />{t(codableConceptText)}</List.Item>
                    })}
                </List>
            }
        } else {
            return null;
        }
    }

    getCodableConcept = (uuid) => {
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            if (this.state.codableConcepts[i].uuid === uuid) {
                return this.state.codableConcepts[i];
            }
        }
    }

    getLogo = (serviceRequest) => {
        let src = logoorange;
        switch (serviceRequest.status) {
            case "ConsentCreated":
                src = logoorange;
                break;
            case "PatientMessageSent":
                src = logoorange;
                break;
            case "SendPatientMessageFailed":
                src = logoorange;
                break;
            case "SendMessageFailed":
                src = logoorange;
                break;
            case this.config.SERVICE_REQUEST_STATUS_PLANNED:
                src = logoyellow;
                break;
            case this.config.SERVICE_REQUEST_STATUS_WAITING_LIST:
                src = logoorange;
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SHOW:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_FORWARD:
                src = logogrey;
                break;
            default:
                src = logogreen;
                break;
        }
        return <Image
            circular
            floated='left'
            size='mini'
            src={src}
        />
    }

    handlePaginationChange = (e, { activePage }) => this.setState({
        activePage, encounterCallsDone: false,
        servicesCallDone: false,
        noResults: false,
        serviceRequests: [],
        encounters: []
    }, () => {
        this.retrieveServiceRequests();
    })

    getFullTable = () => {
        const { t } = this.props;
        const {
            activePage,
            totalPages,
        } = this.state;
        return <Fragment>
            <Table selectable striped unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>{t("Person")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Requester")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Support Needs")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.state.serviceRequests.slice(0, this.state.serviceRequests.length).map((serviceRequest, index) => {
                        if (serviceRequest.encounter.subject) {
                            return <Table.Row>
                                <Table.Cell collapsing textAlign='center'>{this.getLogo(serviceRequest)}</Table.Cell>
                                <Table.Cell>{serviceRequest.encounter.subject.name}</Table.Cell>
                                <Table.Cell>{serviceRequest.encounter.participant.name}</Table.Cell>
                                <Table.Cell>{this.getCodableConceptText(serviceRequest)}</Table.Cell>
                                <Table.Cell>{this.getStatus(serviceRequest)}</Table.Cell>
                                <Table.Cell>{this.getDate(serviceRequest.encounter.date)}</Table.Cell>
                                <Table.Cell collapsing><Button as={Link} to={{
                                    pathname: '/servicerequest',
                                    state: {
                                        serviceRequest: serviceRequest
                                    }
                                }} circular icon='edit outline' size='mini' color='orange' /></Table.Cell>
                            </Table.Row>
                        }
                    })
                    }
                </Table.Body>
            </Table>
            <Segment basic textAlign='center'>
                <Pagination
                    activePage={activePage}
                    onPageChange={this.handlePaginationChange}
                    size='mini'
                    totalPages={totalPages} />
            </Segment>
        </Fragment>;
    }

    getMobileTable = () => {
        const { t } = this.props;
        const {
            activePage,
            totalPages,
        } = this.state;
        return <Fragment>
            <Table selectable striped unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>{t("Person")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.state.serviceRequests.slice(0, this.state.serviceRequests.length).map((serviceRequest, index) => {
                        if (serviceRequest.encounter.subject) {
                            return <Table.Row>
                                <Table.Cell collapsing textAlign='center'>{this.getLogo(serviceRequest)}</Table.Cell>
                                <Table.Cell>{serviceRequest.encounter.subject.name}</Table.Cell>
                                <Table.Cell>{this.getStatus(serviceRequest)}</Table.Cell>
                                <Table.Cell collapsing><Button as={Link} to={{
                                    pathname: '/servicerequest',
                                    state: {
                                        serviceRequest: serviceRequest
                                    }
                                }} circular icon='edit outline' size='mini' color='orange' /></Table.Cell>
                            </Table.Row>
                        }
                    })
                    }
                </Table.Body>
            </Table>
            <Segment basic textAlign='center'>
                <Pagination
                    activePage={activePage}
                    onPageChange={this.handlePaginationChange}
                    size='mini'
                    ellipsisItem={null}
                    totalPages={totalPages} />
            </Segment>
        </Fragment>;
    }

    getTableOverview = () => {
        return <Fragment>
            <Responsive minWidth={1025}>{this.getFullTable()}</Responsive>
            <Responsive maxWidth={1024}>{this.getMobileTable()}</Responsive>
        </Fragment>
    }

    getServiceRequests = () => {
        const { t } = this.props;
        if (this.state.serviceRequests && this.state.serviceRequests.length > 0 && this.state.encounterCallsDone) {
            return this.getTableOverview();
        } else if (this.state.noResults) {
            return <Message info>
                <Message.Header>{t("SD No Requests")}</Message.Header>
                <p>{t("SD No Requests Description")}</p>
            </Message>;
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            encounterCallsDone: false,
            servicesCallDone: false,
            noResults: false,
            serviceRequests: [],
            encounters: []
        }, () => this.retrieveServiceRequests());
    }

    render() {
        const { t } = this.props;
        const sortOptions = [
            {
                key: t("SD Awaiting"),
                text: t("SD Awaiting"),
                value: t("SD Awaiting"),
                image: { avatar: true, src: logoorange }
            },
            {
                key: t("SD Already Supported"),
                text: t("SD Already Supported"),
                value: t("SD Already Supported"),
                image: { avatar: true, src: logogreen }
            },
            {
                key: t("SD Not Supported"),
                text: t("SD Not Supported"),
                value: t("SD Not Supported"),
                image: { avatar: true, src: logored }
            },
            {
                key: t("SD Referred"),
                text: t("SD Referred"),
                value: t("SD Referred"),
                image: { avatar: true, src: logogrey }
            }
        ]
        return <Segment>
            <Menu text >
                <Menu.Menu text='true' position='right'>
                    <Menu.Item>
                        {this.state.totalElements} {t("Results")}
                    </Menu.Item>
                    <Menu.Item>
                        {t("Filter")}
                    </Menu.Item>
                    <Dropdown
                        placeholder={t("SD Awaiting")}
                        selection
                        name='serviceRequestStatus'
                        options={sortOptions}
                        onChange={this.handleChange}
                        clearable
                    />
                </Menu.Menu>
            </Menu>
            <Divider />
            {this.getServiceRequests()}
        </Segment>;
    }

}

export default withTranslation()(withRouter(ServiceDashboard));
