import React, { Component, Fragment } from 'react';
import { Button, Form, Icon, Header, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class RegisterSocialContextForm extends Component {

    state = {
        socialContext: this.props.savedAnswers,
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    toNextStage = () => {
        this.props.answers(this.state.socialContext);
        this.props.nextStage();
    }

    toPreviousStage = () => {
        this.props.saveAnswers(this.state.socialContext);
        this.props.previousStage();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { t } = this.props;
        return <Fragment>
            <Segment basic>
                <Header as='h1'>{t("Social Context")}</Header>
                <Form>
                    <Form.TextArea
                        rows={10}
                        placeholder={t("Direct Referral S3 Placeholder")}
                        name='socialContext'
                        value={this.state.socialContext}
                        onChange={this.handleChange} />
                </Form>
            </Segment>
            <Button onClick={this.toPreviousStage} icon labelPosition='left' color='blue' floated='left'>
                {t("Back")}
                <Icon name='left arrow' />
            </Button>
            <Button onClick={this.toNextStage} icon labelPosition='right' color='blue' floated='right'>
                {t("Next")}
                <Icon name='right arrow' />
            </Button>
        </Fragment>;
    }
}

export default withTranslation()(RegisterSocialContextForm);
