import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { Container, Grid } from 'semantic-ui-react';
import QuestionnaireSteps from '../components/QuestionnaireSteps.jsx';
import RegisterBackgroundInfoForm from '../components/RegisterBackgroundInfoForm.jsx';
import RegisterSocialNeedsForm from '../components/RegisterSocialNeedsForm.jsx';
import RegisterSpecificQuestionsForm from '../components/RegisterSpecificQuestionsForm.jsx';
import RegisterSocialContextForm from '../components/RegisterSocialContextForm.jsx';
import SupportSuggestions from '../components/SupportSuggestions.jsx';
import RegisterConsentForm from '../components/RegisterConsentForm.jsx';
import ReferralCompleted from '../components/ReferralCompleted.jsx';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeader, authHeaderSet, authHeaderPatch } from '../helpers/auth-header.js';

class Questionnaire extends Component {

    cavasaId = 'c1157448-2945-41bb-bade-74510fca2243';

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            stage: 1,
            practitionerRoleId: '',
            patientId: '',
            familyName: '',
            givenName: '',
            postalCode: '',
            age: '',
            errorMsg: '',
            encounterId: '',
            questionnaireResponseId: '',
            questions: [],
            backgroundQuestions: [],
            socialNeedsQuestions: [],
            socialContextQuestions: [],
            specificQuestions: [],
            backgroundAnswers: [],
            socialNeedsAnswers: [],
            socialContextAnswers: '',
            specificAnswers: [],
            selectedServices: [],
            requestedReferral: false,
            requestedLeaflets: false
        }
    }

    retrieveSpecificQuestionnaire() {
        return fetch(this.config.QUESTIONNAIRES_URL + "/" + this.cavasaId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                const questions = [];
                const questionnaireItems = json.questionnaireItemOutputListModel.questionnaireItems;
                for (var i = 0; i < questionnaireItems.length; i++) {
                    questions.push(questionnaireItems[i]);
                }
                this.setState({ specificQuestions: questions });
                return questions;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveQuestionnaire(questionnaireId) {
        return fetch(this.config.QUESTIONNAIRES_URL + "/" + questionnaireId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(json => {
                const questions = [];
                const questionnaireItems = json.questionnaireItemOutputListModel.questionnaireItems;
                for (var i = 0; i < questionnaireItems.length; i++) {
                    questions.push(questionnaireItems[i]);
                }
                this.setState({ questions: questions });
                this.getBackgroundQuestions();
                this.getSocialNeedsQuestions();
                this.getSocialContextQuestions()
                return questions;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    createEncounter = (patientId, practionerRoleId) => {
        let pid = patientId;
        if (!patientId) {
            pid = this.state.patientId;
        }
        return fetch(this.config.ENCOUNTERS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                subject: pid,
                participant: practionerRoleId,
                date: new Date(),
                type: this.config.ENCOUNTER_CONSULT_QUESTIONNAIRE
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(encounter => {
            this.setState({ encounterId: encounter.id });
        }).catch(error => {
            this.handleError(error);
        });
    }

    createQuestionnaireResponse = (questionnaireId) => {
        return fetch(this.config.QUESTIONNAIRE_RESPONSES_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                subject: this.state.patientId,
                author: this.state.activeUser.uuid,
                questionnaire: questionnaireId,
                authored: new Date(),
                encounter: this.state.encounterId
            })
        }).then(response => {
            if (!response.ok) {
                this.setState({ questionnaireResponseCreationError: true })
            } else {
                this.setState({ questionnaireResponseCreated: true });
                return response.json();
            }
        }).then(questionnaireResponse => {
            this.setState({ questionnaireResponseId: questionnaireResponse.id })
            this.registerAnswers(questionnaireResponse.id);
            return questionnaireResponse;
        }).then(questionnaireResponse => {
            if (this.isSpecificQuestionnaire()) {
                this.setState({ stage: 2 })
            } else {
                this.setState({ stage: 4 })
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    completeQuestionnaireResponse = () => {
        return fetch(this.config.QUESTIONNAIRE_RESPONSES_URL + "/" + this.state.questionnaireResponseId, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: 'QuestionnaireComplete'
            })
        }).then(response => {
            if (!response.ok) {
                this.setState({ questionnaireResponseCompletionError: true })
            } else {
                this.setState({ questionnaireResponseCompleted: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    createQuestionnaireResponseItem = (responseId, itemId, answerId, notes) => {
        return fetch(this.config.QUESTIONNAIRE_RESPONSE_ITEMS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                questionnaireResponse: responseId,
                questionnaireItem: itemId,
                chosenAnswer: answerId,
                notes: notes
            })
        }).then(response => {
            if (!response.ok) {
                this.setState({ questionnaireResponseCreationError: true })
            } else {
                this.setState({ questionnaireResponseCreated: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    registerConsent(healthcareService) {
        let practitionerRoleId = '';
        if (this.state.activeUser) {
            this.setState({
                practitionerRoleId: this.state.activeUser.uuid,
            });
            practitionerRoleId = this.state.activeUser.uuid;
        }
        return fetch(this.config.CONSENTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                status: "Active",
                scope: "patient-privacy",
                patient: this.state.patientId,
                performer: practitionerRoleId,
                organization: healthcareService.provider.uuid,
                policy: "limited"
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    registerServiceRequestConcepts(serviceRequestId, codableConceptId) {
        return fetch(this.config.SERVICEREQUESTS_CONCEPTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                codableConcept: serviceRequestId,
                serviceRequest: codableConceptId
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    registerServiceRequest(suggestion) {
        let intent = '';
        if (suggestion.healthcareService.referralMethod === "NONE") {
            intent = this.config.SERVICE_REQUEST_INTENT_SIGNPOSTING;
        }
        return fetch(this.config.SERVICEREQUESTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                encounter: this.state.encounterId,
                performer: suggestion.healthcareService.defaultAssignedHealthcareService.uuid,
                suggestion: suggestion.uuid,
                notes: this.state.socialContextAnswers,
                intent: intent
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(serviceRequest => {
            let id = serviceRequest.id;
            let codableConcepts = suggestion.codableConcepts;
            if (codableConcepts) {
                for (var i = 0; i < codableConcepts.length; i++) {
                    this.registerServiceRequestConcepts(id, codableConcepts[i].uuid);
                }
            }
        }).catch(error => {
            this.handleError(error);
        });
    }


    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    getBackgroundQuestions = () => {
        if (this.state.backgroundQuestions.length === 0) {
            let sectionTitles = [];
            let backgroundQuestions = [];
            for (var i = 0; i < this.state.questions.length; i++) {
                let question = this.state.questions[i];
                if (question.parent && question.parent.name.includes("Profiel")) {
                    sectionTitles.push(question.name);
                }
            }
            for (var j = 0; j < this.state.questions.length; j++) {
                let question = this.state.questions[j];
                if (question.parent && sectionTitles.includes(question.parent.name)) {
                    backgroundQuestions.push(question);
                }
            }
            this.setState({ backgroundQuestions: backgroundQuestions });
        }
    }

    getSocialNeedsQuestions = () => {
        if (this.state.socialNeedsQuestions.length === 0) {
            let socialNeedsQuestions = [];
            for (var i = 0; i < this.state.questions.length; i++) {
                let question = this.state.questions[i];
                if (question.parent && question.parent.name.includes("Sociale Noden")) {
                    socialNeedsQuestions.push(question);
                }
            }
            this.setState({ socialNeedsQuestions: socialNeedsQuestions });
        }
    }

    getSocialContextQuestions = () => {
        if (this.state.socialContextQuestions.length === 0) {
            let socialContextQuestions = [];
            for (var i = 0; i < this.state.questions.length; i++) {
                let question = this.state.questions[i];
                if (question.name.includes("Sociale Context")) {
                    socialContextQuestions.push(question);
                }
            }
            this.setState({ socialContextQuestions: socialContextQuestions });
        }
    }

    setSelectedServices = (services) => {
        this.setState({ selectedServices: services });
    }

    saveBackgroundAnswers = (answers) => {
        this.setState({ backgroundAnswers: answers });
    }

    setSocialNeedsAnswers = (answers) => {
        this.setState({ socialNeedsAnswers: answers });
    }

    setSpecificAnswers = (answers) => {
        this.setState({ specificAnswers: answers }, () => {
            this.registerSpecificQuestionnaireResponse(this.cavasaId);
        });
    }

    saveSocialContextAnswers = (answers) => {
        this.setState({ socialContextAnswers: answers });
    }

    setSocialContextAnswers = (answers) => {
        this.setState({ socialContextAnswers: answers }, () => {
            this.registerQuestionnaireResponse(this.state.questionnaireId);
        });
    }

    retrieveActiveQuestionnaireForUrl = (url) => {
        return fetch(this.config.QUESTIONNAIRES_URL + "?url=" + url, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(questionnaires => {
                let questionnairez = questionnaires._embedded.questionnaires;
                for (var c = 0; c < questionnairez.length; c++) {
                    let questionnaire = questionnairez[c];
                    if (questionnaire.status === this.config.ACTIVE_QUESTIONNAIRE) {
                        const questions = [];
                        const questionnaireItems = questionnaire.questionnaireItemOutputListModel.questionnaireItems;
                        for (var i = 0; i < questionnaireItems.length; i++) {
                            questions.push(questionnaireItems[i]);
                        }
                        this.setState({ questions: questions });
                        this.getBackgroundQuestions();
                        this.getSocialNeedsQuestions();
                        this.getSocialContextQuestions()
                        return questions;
                    }
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveQuestionnaireForELZ = (elzId) => {
        return fetch(this.config.QUESTIONNAIRES_URL + "?region=" + elzId, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(questionnaires => {
                let questionnairez = questionnaires._embedded.questionnaires;
                let questionnaire = questionnairez[0];
                if (questionnaire.status === this.config.PROXY_QUESTIONNAIRE) {
                    this.retrieveActiveQuestionnaireForUrl(questionnaire.url);
                } else {
                    const questions = [];
                    const questionnaireItems = questionnaire.questionnaireItemOutputListModel.questionnaireItems;
                    for (var i = 0; i < questionnaireItems.length; i++) {
                        questions.push(questionnaireItems[i]);
                    }
                    this.setState({ questions: questions });
                    this.getBackgroundQuestions();
                    this.getSocialNeedsQuestions();
                    this.getSocialContextQuestions()
                    return questions;
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }


    retrieveELZForLocation = (locationId) => {
        return fetch(this.config.LOCATIONS_URL + "?childId=" + locationId + "&level=Eerstelijnszone", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                let elzId = location.uuid;
                if (this.isSpecificQuestionnaire()) {
                    this.retrieveSpecificQuestionnaire();
                } else {
                    this.retrieveQuestionnaireForELZ(elzId);
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveLocationForPostalCode = (postalCode) => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.retrieveELZForLocation(location.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.retrieveLocationForPostalCode(address.postalCode);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.organizationId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.retrieveAddress(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    componentDidMount() {
        let practitionerRoleId = this.state.activeUser.uuid;

        if (this.state.questions.length === 0) {
            this.setState({
                organizationId: this.state.activeUser.organization.uuid
            },
                () => {
                    this.retrieveOrganization();
                });
        }
        if (!this.state.patientId && this.props.location.state && this.props.location.state.patientId) {
            this.setState({
                patientId: this.props.location.state.patientId,
                postalCode: this.props.location.state.postalCode,
                givenName: this.props.location.state.givenName,
                familyName: this.props.location.state.familyName,
                patientName: this.props.location.state.patientName,
                age: this.props.location.state.age
            }, () => {
                this.createEncounter(this.props.location.state.patientId, practitionerRoleId);
            });
        }
    }

    registerAnswers = (questionnaireResponseId) => {
        if (this.isSpecificQuestionnaire()) {
            let currentComponent = this;
            let nrOfAnswers = this.state.specificAnswers.length;
            if (nrOfAnswers === 1) {
                this.createQuestionnaireResponseItem(questionnaireResponseId,
                    this.state.specificAnswers[0].questionnaireItem.uuid,
                    this.state.specificAnswers[0].uuid, this.state.specificAnswers[0].text).then(function () {
                        currentComponent.completeQuestionnaireResponse()
                    });
            } else if (nrOfAnswers > 1) {
                for (var i = 0; i < this.state.specificAnswers.length - 1; i++) {
                    this.createQuestionnaireResponseItem(questionnaireResponseId,
                        this.state.specificAnswers[i].questionnaireItem.uuid,
                        this.state.specificAnswers[i].uuid, this.state.specificAnswers[i].text);
                }
                this.createQuestionnaireResponseItem(questionnaireResponseId,
                    this.state.specificAnswers[nrOfAnswers - 1].questionnaireItem.uuid,
                    this.state.specificAnswers[nrOfAnswers - 1].uuid, this.state.specificAnswers[nrOfAnswers - 1].text).then(function () {
                        currentComponent.completeQuestionnaireResponse()
                    });
            }
        } else {
            for (var c = 0; c < this.state.backgroundAnswers.length; c++) {
                this.createQuestionnaireResponseItem(questionnaireResponseId,
                    this.state.backgroundAnswers[c].questionnaireItem.uuid,
                    this.state.backgroundAnswers[c].uuid, null);
            }
            for (var j = 0; j < this.state.socialNeedsAnswers.length; j++) {
                this.createQuestionnaireResponseItem(questionnaireResponseId,
                    this.state.socialNeedsAnswers[j].questionnaireItem.uuid,
                    this.state.socialNeedsAnswers[j].uuid, null);
            }

            let currentComponent = this;
            this.createQuestionnaireResponseItem(questionnaireResponseId,
                this.state.socialContextQuestions[0].uuid,
                null,
                this.state.socialContextAnswers).then(function () {
                    currentComponent.completeQuestionnaireResponse()
                });
        }

    }

    registerQuestionnaireResponse = (questionnaireId) => {
        this.createQuestionnaireResponse(questionnaireId);
    }

    registerSpecificQuestionnaireResponse = (questionnaireId) => {
        this.createQuestionnaireResponse(questionnaireId);
    }

    requestServices = (servicesToRequest) => {
        for (var i = 0; i < servicesToRequest.length; i++) {
            this.registerConsent(servicesToRequest[i].healthcareService);
            this.registerServiceRequest(servicesToRequest[i]);
            if (servicesToRequest[i].healthcareService.referralMethod === "email") {
                this.setState({ requestedReferral: true });
            } else if (servicesToRequest[i].healthcareService.referralMethod === "NONE") {
                this.setState({ requestedLeaflets: true });
            }
        }
    }

    previousStage = () => {
        this.setState({ stage: this.state.stage - 1 });
    }

    nextStage = () => {
        // Go to next stage except for suggestions
        // That needs to be triggered after all answers are registered
        if (this.isSpecificQuestionnaire()) {
            if (this.state.stage !== 1) {
                this.setState({ stage: this.state.stage + 1 });
            }
        } else {
            this.setState({ stage: this.state.stage + 1 });
        }
    }

    isSpecificQuestionnaire = () => {
        if (this.props.organizationType === "Apotheek CAVAsa") {
            return true;
        } else {
            return false;
        }
    }

    getBackgroundAnswers = () => {
        let savedAnswers = [];
        if (this.state.backgroundAnswers && this.state.backgroundAnswers.length > 0) {
            for (var i = 0; i < this.state.backgroundAnswers.length; i++) {
                let questionUuid = this.state.backgroundAnswers[i].questionnaireItem.uuid;
                savedAnswers.push(questionUuid);
            }
        }
        return savedAnswers;
    }

    getQuestions = () => {
        if (this.isSpecificQuestionnaire()) {
            if (this.state.stage === 1) {
                return <RegisterSpecificQuestionsForm nextStage={this.nextStage}
                    questions={this.state.specificQuestions}
                    answers={this.setSpecificAnswers} />;
            } else if (this.state.stage === 2) {
                return <SupportSuggestions nextStage={this.nextStage}
                    questionnaireResponseId={this.state.questionnaireResponseId}
                    selectedServices={this.setSelectedServices}
                    postalCode={this.state.postalCode}
                    age={this.state.age} />;
            } else if (this.state.stage === 3) {
                return <RegisterConsentForm nextStage={this.nextStage}
                    questionnaireResponseId={this.state.questionnaireResponseId}
                    selectedServices={this.state.selectedServices}
                    requestServices={this.requestServices} />;
            } else if (this.state.stage === 4) {
                return <ReferralCompleted requestedReferral={this.state.requestedReferral}
                    requestedLeaflets={this.state.requestedLeaflets} />;
            }
        } else {
            if (this.state.stage === 1) {
                return <RegisterBackgroundInfoForm
                    nextStage={this.nextStage}
                    questions={this.state.backgroundQuestions}
                    savedAnswers={this.getBackgroundAnswers()}
                    answers={this.saveBackgroundAnswers} />;
            } else if (this.state.stage === 2) {
                return <RegisterSocialNeedsForm
                    nextStage={this.nextStage}
                    previousStage={this.previousStage}
                    questions={this.state.socialNeedsQuestions}
                    answers={this.setSocialNeedsAnswers}
                    savedAnswers={this.state.socialNeedsAnswers}
                    backgroundAnswers={this.state.backgroundAnswers} />;
            } else if (this.state.stage === 3) {
                return <RegisterSocialContextForm questions={this.state.socialContextQuestions}
                    answers={this.setSocialContextAnswers}
                    savedAnswers={this.state.socialContextAnswers}
                    saveAnswers={this.saveSocialContextAnswers}
                    nextStage={this.nextStage}
                    previousStage={this.previousStage} />;
            } else if (this.state.stage === 4) {
                return <SupportSuggestions nextStage={this.nextStage}
                    questionnaireResponseId={this.state.questionnaireResponseId}
                    selectedServices={this.setSelectedServices}
                    postalCode={this.state.postalCode}
                    age={this.state.age} />;
            } else if (this.state.stage === 5) {
                return <RegisterConsentForm nextStage={this.nextStage}
                    questionnaireResponseId={this.state.questionnaireResponseId}
                    selectedServices={this.state.selectedServices}
                    requestServices={this.requestServices}
                    patientId={this.state.patientId}
                    email={this.state.email} />;
            } else if (this.state.stage === 6) {
                return <ReferralCompleted requestedReferral={this.state.requestedReferral}
                    requestedLeaflets={this.state.requestedLeaflets}
                    patientId={this.state.patientId} />;
            }
        }
    }

    render() {
        return <Grid columns={5}>
            <Grid.Column width={1}>
            </Grid.Column>
            <Grid.Column width={4}>
                <Container text style={{ marginTop: '5em' }}>
                    <QuestionnaireSteps stage={this.state.stage} organizationType={this.props.organizationType} patientName={this.state.patientName} />
                </Container>
            </Grid.Column>
            <Grid.Column width={10}>
                <Container fluid style={{ marginTop: '5em' }}>
                    {this.getQuestions()}
                </Container>
            </Grid.Column>
            <Grid.Column width={1}>
            </Grid.Column>
        </Grid >;
    }
}

export default withRouter(Questionnaire);
