import React, { Component, Fragment } from 'react';
import { Button, Icon, Header, Segment, Grid, Checkbox, Message, Divider, Modal, Form } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import { authHeaderPatch } from '../helpers/auth-header.js';
import { withTranslation } from 'react-i18next';

class RegisterConsentForm extends Component {

    constructor(props) {
        super(props);
        this.config = new Configuration();
        this.state = {
            servicesForRequest: [],
            email: '',
            printOutLeaflets: false,
            hasReferrals: false,
            showEmailModal: false
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    selectService = (suggestion) => {
        if (this.state.servicesForRequest.includes(suggestion)) {
            const index = this.state.servicesForRequest.indexOf(suggestion);
            if (index > -1) {
                this.state.servicesForRequest.splice(index, 1);
                this.setState({ servicesForRequest: this.state.servicesForRequest });
            }
        } else {
            this.state.servicesForRequest.push(suggestion);
            this.setState({ servicesForRequest: this.state.servicesForRequest });
        }
    }

    registerConsent = () => {
        this.props.requestServices(this.state.servicesForRequest);
        this.props.nextStage();
    }

    componentDidMount = () => {
        this.setState({ email: this.props.email });
        for (var i = 0; i < this.props.selectedServices.length; i++) {
            let service = this.props.selectedServices[i];
            if (service.healthcareService.referralMethod === 'email') {
                this.setState({ hasReferrals: true });
                break;
            }
        }
    }

    getReferralsConsent = () => {
        const { t } = this.props;
        if (this.state.hasReferrals) {
            return <Segment>
                <p>{t("Consent Organisations")}</p>
                {this.props.selectedServices.slice(0, this.props.selectedServices.length).map((suggestion, index) => {
                    if (suggestion.healthcareService.referralMethod !== 'NONE') {
                        return <Segment basic >
                            <Grid columns={16}>
                                <Grid.Column width={1}>
                                    <Checkbox name={suggestion.healthcareService.name} onChange={this.selectService.bind(this, suggestion)} />
                                </Grid.Column>
                                <Grid.Column width={15}>
                                    <Header>{suggestion.healthcareService.name}</Header>
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    }
                })}
                <Icon size='large' color='blue' name='talk' /> {t("Consent Organisation")}
            </Segment>
        }

    }

    setEmail = () => {
        this.setState({ showEmailModal: true });
    }

    closeEmailFollowUp = () => {
        this.setState({ showEmailModal: false });
    }

    closeEmailFollowUpAnddSave = () => {
        this.updateEmailAddress();
        this.setState({ showEmailModal: false });
    }

    printLeaflets = () => {
        this.setState({ printOutLeaflets: true });
    }

    getDownloadReference = (healthcareService) => {
        return healthcareService.mailableResources.slice(0, healthcareService.mailableResources.length).map((mailableResource, index) => {
            return <a href={mailableResource.resoureceUrl} target="_blank" rel="noopener noreferrer"> <Button circular size='tiny' color='blue'><Icon name='external' />{mailableResource.name}</Button></a>
        });
    }

    getWrapUpButton = () => {
        const { t } = this.props;
        if (this.state.hasReferrals) {
            return <Button onClick={this.registerConsent} icon labelPosition='right' color='blue' floated='right'>
                {t("Refer")}
                <Icon name='right arrow' />
            </Button>;
        } else {
            return <Button onClick={this.registerConsent} icon labelPosition='right' color='blue' floated='right'>
                {t("Close")}
                <Icon name='right arrow' />
            </Button>;
        }
    }

    handleError = (error) => {
        console.log(error.message);
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    updateEmailAddress = () => {
        return fetch(this.config.PATIENTS_URL + "/" + this.props.patientId, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                email: this.state.email,
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    getLeafletsConsent = () => {
        const { t } = this.props;
        let hasLeaflets = false;
        for (var i = 0; i < this.props.selectedServices.length; i++) {
            let service = this.props.selectedServices[i];
            if (service.healthcareService.referralMethod === 'NONE') {
                hasLeaflets = true;
                break;
            }
        }
        if (hasLeaflets) {
            if (this.state.printOutLeaflets) {
                return <Segment>
                    <Header>{t("Information Services")}</Header>
                    <p>{t("Information Links")}</p>
                    <Divider />
                    {this.props.selectedServices.slice(0, this.props.selectedServices.length).map((suggestion, index) => {
                        if (suggestion.healthcareService.referralMethod === 'NONE') {
                            return <Grid columns={16}>
                                <Grid.Column width={8}>
                                    <Header size='small'>{suggestion.healthcareService.name}</Header>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header>{this.getDownloadReference(suggestion.healthcareService)}</Header>
                                </Grid.Column>
                            </Grid>
                        }
                        return;
                    })}
                </Segment>
            }
            if (this.state.email && this.state.email.trim() !== "") {
                return <Segment>
                    <p>{t("Information Email")}</p>
                    {this.props.selectedServices.slice(0, this.props.selectedServices.length).map((suggestion, index) => {
                        if (suggestion.healthcareService.referralMethod === 'NONE') {
                            return <Segment basic >
                                <Grid columns={16}>
                                    <Grid.Column width={1}>
                                        <Checkbox name={suggestion.healthcareService.name} onChange={this.selectService.bind(this, suggestion)} />
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <Header>{suggestion.healthcareService.name}</Header>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        }
                        return;
                    })}
                    <Icon size='large' color='blue' name='talk' /> {t("Information Email Consent")}
                </Segment>;
            } else {
                return <Segment>
                    <Header>{t("Information Services")}</Header>
                    <Message warning>
                        <p>{t("Information Services S1")}</p>
                        <p>{t("Information Services S2")}</p>
                        <p>{t("Information Services S3")}</p>
                    </Message>
                    <Button color='blue' onClick={this.setEmail}>{t("Enter Email")}</Button>
                    <Button color='blue' onClick={this.printLeaflets}>{t("Print Information")}</Button>
                </Segment>
            }
        }

    }


    render() {
        const { t } = this.props;
        const { email } = this.state;
        return <Fragment>
            <Modal
                open={this.state.showEmailModal}
                onClose={this.closeEmailFollowUp}
                size='small'
                dimmer='blurring'>
                <Header icon='mail' content={t("Email Patient")} />
                <Modal.Content>
                    <Form>
                        <Form.Input
                            width={12}
                            fluid
                            label={t("Email")}
                            placeholder={t("Email")}
                            name='email'
                            value={email}
                            onChange={this.handleChange} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={this.closeEmailFollowUpAnddSave}>
                        <Icon name='checkmark' />{t("Save")}
                    </Button>
                </Modal.Actions>
            </Modal><Segment basic>
                <Header as='h1'>{t("Register Consent")}</Header>
                {this.getReferralsConsent()}
                {this.getLeafletsConsent()}
                {this.getWrapUpButton()}
            </Segment >
        </Fragment>;
    }

}

export default withTranslation() (RegisterConsentForm);
