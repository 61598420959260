
import React, { Component, Fragment } from 'react';
import { Header, Segment, Divider, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import sadface from '../images/sad-face.png';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends Component {

    constructor(props) {
        super();
        this.state = {
            hasError: false
        };
        this.resetState = this.resetState.bind(this);
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    resetState() {
        setTimeout(() => {
            this.setState({ hasError: false });
            this.props.history.push("/");
          }, 10000);
    }

    render() {
        const { t } = this.props;
        if (this.state.hasError) {
            {this.resetState()}
            return <Fragment>
                <Segment textAlign='center' raised>
                    <Image src={sadface} size='small' centered/>
                    <Header size='large' color='blue'>{t("Error S1")}</Header>
                    <Divider hidden />
                    <Header size='small' color='blue'>{t("Error S2")}</Header>
                    <Divider hidden />
                    <Segment color='blue' raised inverted>
                    <Header>{t("Error S3")}</Header>
                    <Header>{t("Error S4")}</Header>
                    </Segment>
                    <Divider hidden />
                </Segment>
            </Fragment>;
        }
        return this.props.children;
    }
}

export default withTranslation() (withRouter(ErrorBoundary));