import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class MultipleChoiceAnswer extends Component {

    state = {
        answers: [],
        answersInitialized: false
    }

    componentDidUpdate() {
        if (!this.state.answersInitialized && this.props.responses && this.props.responses.length > 0) {
            let initialAnswers = [];
            for (var i = 0; i < this.props.responses.length; i++) {
                let response = this.props.responses[i];
                if (response.questionnaireItem.uuid === this.props.question) {
                    let answerUuid = '';
                    if (response.chosenAnswer && response.chosenAnswer.uuid) {
                        answerUuid = response.chosenAnswer.uuid;
                    }
                    let answer = {
                        uuid: answerUuid,
                        text: response.answer,
                        questionnaireItem: response.questionnaireItem
                    }
                    initialAnswers.push(answer);
                }
            }
            if (initialAnswers.length > 0) {
                this.setState({
                    answers: initialAnswers,
                    answersInitialized: true
                }, () => {
                    this.props.answer(this.state.answers);
                });
            }
        }
    }

    alreadyAnswered = (answer) => {
        for (var i = 0; i < this.state.answers.length; i++) {
            let answered = this.state.answers[i]
            if (answered.uuid === answer.uuid) {
                return true;
            }
        }
        return false;
    }

    answerQuestion = (answer) => {
        if (this.alreadyAnswered(answer)) {
            for (var i = 0; i < this.state.answers.length; i++) {
                let answered = this.state.answers[i];
                if (answered.uuid === answer.uuid) {
                    const index = this.state.answers.indexOf(answered);
                    if (index > -1) {
                        this.state.answers.splice(index, 1);
                    }
                }
            }
        } else {
            let answers = this.state.answers;
            answers.push(answer);
            this.setState({ answers: answers });
        }
        this.props.answer(this.state.answers);
    }

    getChecked = (answer) => {
        if (this.state.answers.includes(answer.uuid)) {
            return true
        } else if (this.props.responses) {
            for (var i = 0; i < this.props.responses.length; i++) {
                let response = this.props.responses[i];
                if (response.chosenAnswer && response.chosenAnswer.uuid === answer.uuid) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const { t } = this.props;
        if (this.props.registrationMode) {
            return <Form.Group grouped>
                {this.props.answers.slice(0, this.props.answers.length).map((answer, index) =>
                    <Form.Field key={index}>
                        <Form.Checkbox onClick={this.answerQuestion.bind(this, answer)}
                            label={t(answer.text)}
                            value={answer.uuid}
                            name={this.props.question}
                        />
                    </Form.Field>
                )}
            </Form.Group>
        } else {
            return <Form.Group grouped>
                {this.props.answers.slice(0, this.props.answers.length).map((answer, index) =>
                    <Form.Field key={index}>
                        <Form.Checkbox
                            label={t(answer.text)}
                            value={answer.uuid}
                            name={this.props.question}
                            checked={this.getChecked(answer)}
                            readOnly={true}
                        />
                    </Form.Field>
                )}
            </Form.Group>
        }
    }

}

export default withTranslation() (MultipleChoiceAnswer);
